import axios from 'axios';
import history from '../../history';
import { AGGREGATION_API, BETA_SITE_URL, CHAT_API, NOTIFICATION_API, OAUTH_TOKEN, RIDGE_API, SARBJIT_API } from '../../Config';
import moment from 'moment';

import axiosInstance from './axiosInterceptor';

import { GetTimeZoneOffsetInHHMM } from '../../Functions';

import {
  CLEAR_GET_STARTED,
  HIDE_ERROR_MODAL,
  HANDLE_VISIBILITY_CHANGE,
  RESIZE_WINDOW,

  CLEAR_SAVE_DATA, SAVE_DATA_IN_PROPS,
  CLEAR_NEWLY_READ_NOTIFICATION_ID,
  TRYING_ADD_ADDITIONAL_ACTIVITIES, TRYING_ADD_ADDITIONAL_ACTIVITIES_SUCCESS, TRYING_ADD_ADDITIONAL_ACTIVITIES_ERROR,
  TRYING_ADD_CLIENT_JOURNAL_ENTRY, TRYING_ADD_CLIENT_JOURNAL_ENTRY_SUCCESS, TRYING_ADD_CLIENT_JOURNAL_ENTRY_ERROR,
  TRYING_ADD_COMPANY_EMPLOYEES, TRYING_ADD_COMPANY_EMPLOYEES_SUCCESS, TRYING_ADD_COMPANY_EMPLOYEES_ERROR,
  TRYING_ADD_EXISTING_DISH, TRYING_ADD_EXISTING_DISH_SUCCESS, TRYING_ADD_EXISTING_DISH_ERROR,
  TRYING_ADD_GROUP_WORKOUT_MEMBERS, TRYING_ADD_GROUP_WORKOUT_MEMBERS_SUCCESS, TRYING_ADD_GROUP_WORKOUT_MEMBERS_ERROR,
  TRYING_ADD_GET_STARTED_MEALS, TRYING_ADD_GET_STARTED_MEALS_SUCCESS, TRYING_ADD_GET_STARTED_MEALS_ERROR,
  TRYING_ADD_GET_STARTED_WORKOUTS, TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS, TRYING_ADD_GET_STARTED_WORKOUTS_ERROR,
  TRYING_ADD_EXISTING_MEAL_TEMPLATE, TRYING_ADD_EXISTING_MEAL_TEMPLATE_SUCCESS, TRYING_ADD_EXISTING_MEAL_TEMPLATE_ERROR,
  TRYING_ADD_EXISTING_WORKOUT_TEMPLATE, TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_SUCCESS, TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_ERROR,
  TRYING_ADD_LOG_FOOD, TRYING_ADD_LOG_FOOD_SUCCESS, TRYING_ADD_LOG_FOOD_ERROR,
  TRYING_ADD_MEASUREMENTS, TRYING_ADD_MEASUREMENTS_SUCCESS, TRYING_ADD_MEASUREMENTS_ERROR,
  TRYING_ADD_NEW_WORKOUT, TRYING_ADD_NEW_WORKOUT_SUCCESS, TRYING_ADD_NEW_WORKOUT_ERROR,
  TRYING_ADD_SINGLE_GENERATED_WORKOUT, TRYING_ADD_SINGLE_GENERATED_WORKOUT_SUCCESS, TRYING_ADD_SINGLE_GENERATED_WORKOUT_ERROR,
  CLEAR_ADD_TRAINER_CLIENT_ERROR, TRYING_ADD_TRAINER_CLIENT, TRYING_ADD_TRAINER_CLIENT_SUCCESS, TRYING_ADD_TRAINER_CLIENT_ERROR,
  TRYING_ADD_TRAINING_PROGRAM, TRYING_ADD_TRAINING_PROGRAM_SUCCESS, TRYING_ADD_TRAINING_PROGRAM_ERROR,
  TRYING_ADD_WORKOUT_EXERCISES, TRYING_ADD_WORKOUT_EXERCISES_SUCCESS, TRYING_ADD_WORKOUT_EXERCISES_ERROR,
  TRYING_ADD_WORKOUT_LAP, TRYING_ADD_WORKOUT_LAP_SUCCESS, TRYING_ADD_WORKOUT_LAP_ERROR,
  TRYING_ADD_WORKOUT_SET, TRYING_ADD_WORKOUT_SET_SUCCESS, TRYING_ADD_WORKOUT_SET_ERROR,
  TRYING_AUTOCOMPLETE_WORKOUT, TRYING_AUTOCOMPLETE_WORKOUT_SUCCESS, TRYING_AUTOCOMPLETE_WORKOUT_ERROR,
  TRYING_BUILD_WORKOUT_PLAN, TRYING_BUILD_WORKOUT_PLAN_SUCCESS, TRYING_BUILD_WORKOUT_PLAN_ERROR,
  TRYING_BULK_ADD_CLIENTS, TRYING_BULK_ADD_CLIENTS_SUCCESS, TRYING_BULK_ADD_CLIENTS_ERROR,
  TRYING_CALCULATE_BODY_FAT, TRYING_CALCULATE_BODY_FAT_SUCCESS, TRYING_CALCULATE_BODY_FAT_ERROR,
  TRYING_CHANGE_DISHES_CATEGORY, TRYING_CHANGE_DISHES_CATEGORY_SUCCESS, TRYING_CHANGE_DISHES_CATEGORY_ERROR,
  TRYING_CHANGE_PASSWORD, TRYING_CHANGE_PASSWORD_SUCCESS, TRYING_CHANGE_PASSWORD_ERROR,
  TRYING_CHANGE_SELECTED_DISHES_RATING, TRYING_CHANGE_SELECTED_DISHES_RATING_SUCCESS, TRYING_CHANGE_SELECTED_DISHES_RATING_ERROR,
  TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET, TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_SUCCESS, TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_ERROR,
  TRYING_CHANGE_WORKOUT_EXERCISE_NOTE, TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_SUCCESS, TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_ERROR,
  TRYING_CHARGE_SUBSCRIPTION, TRYING_CHARGE_SUBSCRIPTION_SUCCESS, TRYING_CHARGE_SUBSCRIPTION_ERROR,
  TRYING_CHOOSE_PRIMARY_ORGANIZATION, TRYING_CHOOSE_PRIMARY_ORGANIZATION_SUCCESS, TRYING_CHOOSE_PRIMARY_ORGANIZATION_ERROR,
  TRYING_COPY_GROUP_WORKOUTS, TRYING_COPY_GROUP_WORKOUTS_SUCCESS, TRYING_COPY_GROUP_WORKOUTS_ERROR,
  TRYING_COPY_MEALS, TRYING_COPY_MEALS_SUCCESS, TRYING_COPY_MEALS_ERROR,
  TRYING_COPY_WORKOUTS, TRYING_COPY_WORKOUTS_SUCCESS, TRYING_COPY_WORKOUTS_ERROR,
  TRYING_CREATE_CONTRACT, TRYING_CREATE_CONTRACT_SUCCESS, TRYING_CREATE_CONTRACT_ERROR,
  TRYING_CREATE_DISH_CATEGORY, TRYING_CREATE_DISH_CATEGORY_SUCCESS, TRYING_CREATE_DISH_CATEGORY_ERROR,
  TRYING_CREATE_VIDEO_SESSION, TRYING_CREATE_VIDEO_SESSION_SUCCESS, TRYING_CREATE_VIDEO_SESSION_ERROR,
  TRYING_DELETE_BODYGOAL_MEASUREMENT, TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR,
  TRYING_DELETE_COMPANY_EMPLOYEES, TRYING_DELETE_COMPANY_EMPLOYEES_SUCCESS, TRYING_DELETE_COMPANY_EMPLOYEES_ERROR,
  TRYING_DELETE_CONTRACT, TRYING_DELETE_CONTRACT_SUCCESS, TRYING_DELETE_CONTRACT_ERROR,
  TRYING_DELETE_DISH_DETAIL_FOOD, TRYING_DELETE_DISH_DETAIL_FOOD_SUCCESS, TRYING_DELETE_DISH_DETAIL_FOOD_ERROR,
  TRYING_DELETE_DISHES, TRYING_DELETE_DISHES_SUCCESS, TRYING_DELETE_DISHES_ERROR,
  TRYING_DELETE_EXERCISES, TRYING_DELETE_EXERCISES_SUCCESS, TRYING_DELETE_EXERCISES_ERROR,
  TRYING_DELETE_EXPENSE, TRYING_DELETE_EXPENSE_SUCCESS, TRYING_DELETE_EXPENSE_ERROR,
  TRYING_DELETE_FOODS, TRYING_DELETE_FOODS_SUCCESS, TRYING_DELETE_FOODS_ERROR,
  TRYING_DELETE_FOODS_CONFIRMATION, TRYING_DELETE_FOODS_CONFIRMATION_SUCCESS, TRYING_DELETE_FOODS_CONFIRMATION_ERROR,
  TRYING_DELETE_GROUP_WORKOUTS, TRYING_DELETE_GROUP_WORKOUTS_SUCCESS, TRYING_DELETE_GROUP_WORKOUTS_ERROR,
  TRYING_DELETE_MEAL_DISH, TRYING_DELETE_MEAL_DISH_SUCCESS, TRYING_DELETE_MEAL_DISH_ERROR,
  TRYING_DELETE_MEALS, TRYING_DELETE_MEALS_SUCCESS, TRYING_DELETE_MEALS_ERROR,
  TRYING_DELETE_MEAL_TEMPLATES, TRYING_DELETE_MEAL_TEMPLATES_SUCCESS, TRYING_DELETE_MEAL_TEMPLATES_ERROR,
  TRYING_DELETE_ORGANIZATION_BRANCH, TRYING_DELETE_ORGANIZATION_BRANCH_SUCCESS, TRYING_DELETE_ORGANIZATION_BRANCH_ERROR,
  TRYING_DELETE_ORGANIZATION_MEMBER, TRYING_DELETE_ORGANIZATION_MEMBER_SUCCESS, TRYING_DELETE_ORGANIZATION_MEMBER_ERROR,
  TRYING_DELETE_ORGANIZATION_REGION, TRYING_DELETE_ORGANIZATION_REGION_SUCCESS, TRYING_DELETE_ORGANIZATION_REGION_ERROR,
  TRYING_DELETE_PRODUCTS, TRYING_DELETE_PRODUCTS_SUCCESS, TRYING_DELETE_PRODUCTS_ERROR,
  TRYING_DELETE_TRAINER_CLIENT, TRYING_DELETE_TRAINER_CLIENT_SUCCESS, TRYING_DELETE_TRAINER_CLIENT_ERROR,
  TRYING_DELETE_TRAINER_GROUP, TRYING_DELETE_TRAINER_GROUP_SUCCESS, TRYING_DELETE_TRAINER_GROUP_ERROR,
  TRYING_DELETE_TRAINING_PROGRAMS, TRYING_DELETE_TRAINING_PROGRAMS_SUCCESS, TRYING_DELETE_TRAINING_PROGRAMS_ERROR,
  TRYING_DELETE_VIDEO_SESSION, TRYING_DELETE_VIDEO_SESSION_SUCCESS, TRYING_DELETE_VIDEO_SESSION_ERROR,
  TRYING_DELETE_WORKOUT, TRYING_DELETE_WORKOUT_SUCCESS, TRYING_DELETE_WORKOUT_ERROR,
  TRYING_DELETE_WORKOUT_EXERCISE, TRYING_DELETE_WORKOUT_EXERCISE_SUCCESS, TRYING_DELETE_WORKOUT_EXERCISE_ERROR,
  TRYING_DELETE_WORKOUT_LAP, TRYING_DELETE_WORKOUT_LAP_SUCCESS, TRYING_DELETE_WORKOUT_LAP_ERROR,
  TRYING_DELETE_WORKOUT_SET, TRYING_DELETE_WORKOUT_SET_SUCCESS, TRYING_DELETE_WORKOUT_SET_ERROR,
  TRYING_DELETE_WORKOUT_TEMPLATES, TRYING_DELETE_WORKOUT_TEMPLATES_SUCCESS, TRYING_DELETE_WORKOUT_TEMPLATES_ERROR,
  TRYING_DELETE_WORKOUTS, TRYING_DELETE_WORKOUTS_SUCCESS, TRYING_DELETE_WORKOUTS_ERROR,
  TRYING_DELIVERY_SITE_ACTION, TRYING_DELIVERY_SITE_ACTION_SUCCESS, TRYING_DELIVERY_SITE_ACTION_ERROR,
  TRYING_DOWNLOAD_CONTRACT_PDF, TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS, TRYING_DOWNLOAD_CONTRACT_PDF_ERROR,
  TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT, TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_SUCCESS,
  TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY, TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS, TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR,
  TRYING_EDIT_GROUP_WORKOUT_DETAIL, TRYING_EDIT_GROUP_WORKOUT_DETAIL_SUCCESS, TRYING_EDIT_GROUP_WORKOUT_DETAIL_ERROR,
  TRYING_EDIT_USER_PROFILE, TRYING_EDIT_USER_PROFILE_SUCCESS, TRYING_EDIT_USER_PROFILE_ERROR,
  TRYING_EDIT_VIDEO_SESSION, TRYING_EDIT_VIDEO_SESSION_ERROR, TRYING_EDIT_VIDEO_SESSION_SUCCESS,
  TRYING_FORGOT_PASSWORD_SEND_CODE, TRYING_FORGOT_PASSWORD_SEND_CODE_SUCCESS, TRYING_FORGOT_PASSWORD_SEND_CODE_ERROR,
  TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD, TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS, TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_ERROR,
  TRYING_FREEZE_WORKOUT_FOR_PRINT, TRYING_FREEZE_WORKOUT_FOR_PRINT_SUCCESS, TRYING_FREEZE_WORKOUT_FOR_PRINT_ERROR,
  TRYING_GENERATE_WORKOUT_TEMPLATE_PDF, TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_SUCCESS,
  CLEAR_ACTIVITY_LEVELS, TRYING_GET_ACTIVITY_LEVELS, TRYING_GET_ACTIVITY_LEVELS_SUCCESS, TRYING_GET_ACTIVITY_LEVELS_ERROR,
  CLEAR_ADDITIONAL_ACTIVITY_DETAILS, TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS, TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS, TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_ERROR,
  CLEAR_ADD_EDIT_GROUP_SETTINGS, TRYING_GET_ADD_EDIT_GROUP_SETTINGS, TRYING_GET_ADD_EDIT_GROUP_SETTINGS_SUCCESS, TRYING_GET_ADD_EDIT_GROUP_SETTINGS_ERROR,
  TRYING_GET_ADD_MEASUREMENT_DETAILS, TRYING_GET_ADD_MEASUREMENT_DETAILS_SUCCESS, TRYING_GET_ADD_MEASUREMENT_DETAILS_ERROR,
  TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT, TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_SUCCESS, TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_ERROR,
  TRYING_GET_BODYGOAL_TRACKING_HISTORY, TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR,
  CLEAR_BUILD_WORKOUT_PLAN_DETAILS, TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS, TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_SUCCESS, TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_ERROR,
  TRYING_GET_CHOOSE_CLIENTS, TRYING_GET_CHOOSE_CLIENTS_SUCCESS, TRYING_GET_CHOOSE_CLIENTS_ERROR,
  CLEAR_CLIENT_DASHBOARD, TRYING_GET_CLIENT_DASHBOARD, TRYING_GET_CLIENT_DASHBOARD_SUCCESS, TRYING_GET_CLIENT_DASHBOARD_ERROR,
  TRYING_GET_CLIENT_DASHBOARD_EVENT, TRYING_GET_CLIENT_DASHBOARD_EVENT_SUCCESS, TRYING_GET_CLIENT_DASHBOARD_EVENT_ERROR,
  CLEAR_CLIENT_DETAILS, TRYING_GET_CLIENT_DETAILS, TRYING_GET_CLIENT_DETAILS_SUCCESS, TRYING_GET_CLIENT_DETAILS_ERROR,
  TRYING_GET_CLIENT_JOURNAL_ENTRIES, TRYING_GET_CLIENT_JOURNAL_ENTRIES_SUCCESS, TRYING_GET_CLIENT_JOURNAL_ENTRIES_ERROR,
  TRYING_GET_CLIENT_MEALS, TRYING_GET_CLIENT_MEALS_SUCCESS, TRYING_GET_CLIENT_MEALS_ERROR,
  TRYING_GET_CLIENT_MEAL_SETTINGS, TRYING_GET_CLIENT_MEAL_SETTINGS_SUCCESS, TRYING_GET_CLIENT_MEAL_SETTINGS_ERROR,
  TRYING_GET_CLIENTS, TRYING_GET_CLIENTS_SUCCESS, TRYING_GET_CLIENTS_ERROR,
  TRYING_GET_CLIENT_WORKOUTS, TRYING_GET_CLIENT_WORKOUTS_SUCCESS, TRYING_GET_CLIENT_WORKOUTS_ERROR,
  TRYING_GET_COMPANIES, TRYING_GET_COMPANIES_SUCCESS, TRYING_GET_COMPANIES_ERROR,
  TRYING_GET_COMPANY, TRYING_GET_COMPANY_SUCCESS, TRYING_GET_COMPANY_ERROR,
  CLEAR_CREATE_CONTRACT_DETAILS, TRYING_GET_CREATE_CONTRACT_DETAILS, TRYING_GET_CREATE_CONTRACT_DETAILS_SUCCESS, TRYING_GET_CREATE_CONTRACT_DETAILS_ERROR,
  CLEAR_PRIVATE_DISH_SETTINGS, TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS, TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_SUCCESS, TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_ERROR,
  CLEAR_PRIVATE_EXERCISE_SETTINGS, TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS, TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_SUCCESS, TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_ERROR,
  TRYING_GET_DATACENTER_DASHBOARD, TRYING_GET_DATACENTER_DASHBOARD_SUCCESS, TRYING_GET_DATACENTER_DASHBOARD_ERROR,
  TRYING_GET_DATACENTER_DASHBOARD_FILTERS, TRYING_GET_DATACENTER_DASHBOARD_FILTERS_SUCCESS, TRYING_GET_DATACENTER_DASHBOARD_FILTERS_ERROR,
  CLEAR_DISH_DETAIL, TRYING_GET_DISH_DETAIL, TRYING_GET_DISH_DETAIL_SUCCESS, TRYING_GET_DISH_DETAIL_ERROR,
  TRYING_GET_DISH_FILTER_SETTINGS, TRYING_GET_DISH_FILTER_SETTINGS_SUCCESS, TRYING_GET_DISH_FILTER_SETTINGS_ERROR,
  CLEAR_ENDURANCE_DETAIL, TRYING_GET_ENDURANCE_DETAIL, TRYING_GET_ENDURANCE_DETAIL_SUCCESS, TRYING_GET_ENDURANCE_DETAIL_ERROR,
  TRYING_GET_ENDURANCE_TRACKING_HISTORY, TRYING_GET_ENDURANCE_TRACKING_HISTORY_SUCCESS, TRYING_GET_ENDURANCE_TRACKING_HISTORY_ERROR,
  CLEAR_EXERCISE_DETAIL, TRYING_GET_EXERCISE_DETAIL, TRYING_GET_EXERCISE_DETAIL_SUCCESS, TRYING_GET_EXERCISE_DETAIL_ERROR,
  TRYING_GET_EXERCISE_FILTER_SETTINGS, TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR,
  TRYING_GET_EXERCISE_TRACKING_HISTORY, TRYING_GET_EXERCISE_TRACKING_HISTORY_SUCCESS, TRYING_GET_EXERCISE_TRACKING_HISTORY_ERROR,
  TRYING_GET_EXPERIENCE_LEVELS, TRYING_GET_EXPERIENCE_LEVELS_SUCCESS, TRYING_GET_EXPERIENCE_LEVELS_ERROR,
  TRYING_GET_GOALS, TRYING_GET_GOALS_SUCCESS, TRYING_GET_GOALS_ERROR,
  CLEAR_GROUP_DETAILS, TRYING_GET_GROUP_DETAILS, TRYING_GET_GROUP_DETAILS_SUCCESS, TRYING_GET_GROUP_DETAILS_ERROR,
  CLEAR_GROUP_WORKOUT_DETAIL, TRYING_GET_GROUP_WORKOUT_DETAIL, TRYING_GET_GROUP_WORKOUT_DETAIL_SUCCESS, TRYING_GET_GROUP_WORKOUT_DETAIL_ERROR,
  TRYING_GET_WORKOUT_NOTES, TRYING_GET_WORKOUT_NOTES_SUCCESS, TRYING_GET_WORKOUT_NOTES_ERROR,
  TRYING_GET_GROUP_WORKOUTS, TRYING_GET_GROUP_WORKOUTS_SUCCESS, TRYING_GET_GROUP_WORKOUTS_ERROR,
  CLEAR_GYMS, TRYING_GET_GYMS, TRYING_GET_GYMS_SUCCESS, TRYING_GET_GYMS_ERROR,
  TRYING_GET_INJURIES, TRYING_GET_INJURIES_SUCCESS, TRYING_GET_INJURIES_ERROR,
  CLEAR_MEAL_TEMPLATE_DETAIL, TRYING_GET_MEAL_TEMPLATE_DETAIL, TRYING_GET_MEAL_TEMPLATE_DETAIL_SUCCESS, TRYING_GET_MEAL_TEMPLATE_DETAIL_ERROR,
  TRYING_GET_MEAL_DISH_PREVIEW, TRYING_GET_MEAL_DISH_PREVIEW_SUCCESS, TRYING_GET_MEAL_DISH_PREVIEW_ERROR,
  CLEAR_NUTRITION, TRYING_GET_NUTRITION, TRYING_GET_NUTRITION_SUCCESS, TRYING_GET_NUTRITION_ERROR,
  TRYING_GET_PRESIGNED_URL, TRYING_GET_PRESIGNED_URL_SUCCESS, TRYING_GET_PRESIGNED_URL_ERROR,
  CLEAR_PROGRESS_DASHBOARD, TRYING_GET_PROGRESS_DASHBOARD, TRYING_GET_PROGRESS_DASHBOARD_SUCCESS, TRYING_GET_PROGRESS_DASHBOARD_ERROR,
  TRYING_GET_ORGANIZATION_INCOME_REPORT, TRYING_GET_ORGANIZATION_INCOME_REPORT_SUCCESS, TRYING_GET_ORGANIZATION_INCOME_REPORT_ERROR,
  TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES, TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_SUCCESS, TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_ERROR,
  CLEAR_SINGLE_GENERATED_WORKOUT_DETAILS, TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS, TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_SUCCESS, TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_ERROR,
  CLEAR_SINGLE_VIDEO_SESSION, TRYING_GET_SINGLE_VIDEO_SESSION, TRYING_GET_SINGLE_VIDEO_SESSION_ERROR, TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS,
  CLEAR_TRAINER_CLIENTS, TRYING_GET_TRAINER_CLIENTS, TRYING_GET_TRAINER_CLIENTS_SUCCESS, TRYING_GET_TRAINER_CLIENTS_ERROR,
  TRYING_GET_TRAINER_CONTRACTS, TRYING_GET_TRAINER_CONTRACTS_SUCCESS, TRYING_GET_TRAINER_CONTRACTS_ERROR,
  CLEAR_TRAINING_PROGRAM_DETAIL, TRYING_GET_TRAINING_PROGRAM_DETAIL, TRYING_GET_TRAINING_PROGRAM_DETAIL_SUCCESS, TRYING_GET_TRAINING_PROGRAM_DETAIL_ERROR,
  TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS, TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR,
  TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE, TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_SUCCESS, TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_ERROR,
  TRYING_GET_USER_WORKOUT_MAXES_STRENGTH, TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_SUCCESS, TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_ERROR,
  CLEAR_VIEW_PRIVATE_FOOD_SETTINGS, TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS, TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_SUCCESS, TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_ERROR,
  CLEAR_WORKOUT_DETAIL, TRYING_GET_WORKOUT_DETAIL, TRYING_GET_WORKOUT_DETAIL_SUCCESS, TRYING_GET_WORKOUT_DETAIL_ERROR,
  TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE, TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_SUCCESS, TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_ERROR,
  TRYING_GET_WORKOUT_PREVIEW, TRYING_GET_WORKOUT_PREVIEW_SUCCESS, TRYING_GET_WORKOUT_PREVIEW_ERROR,
  CLEAR_WORKOUT_TEMPLATE_DETAIL, TRYING_GET_WORKOUT_TEMPLATE_DETAIL, TRYING_GET_WORKOUT_TEMPLATE_DETAIL_SUCCESS, TRYING_GET_WORKOUT_TEMPLATE_DETAIL_ERROR,
  TRYING_GET_WORKOUT_TEMPLATE_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS,
  TRYING_GET_WORKOUT_TEMPLATE_FILTERS, TRYING_GET_WORKOUT_TEMPLATE_FILTERS_SUCCESS, TRYING_GET_WORKOUT_TEMPLATE_FILTERS_ERROR,
  TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS, TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR,
  TRYING_GET_VIDEO_SESSIONS, TRYING_GET_VIDEO_SESSIONS_ERROR, TRYING_GET_VIDEO_SESSIONS_SUCCESS,
  TRYING_UPDATE_WORKOUT_ACCESS, TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS, TRYING_UPDATE_WORKOUT_ACCESS_ERROR,
  TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY, TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_SUCCESS, TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_ERROR,
  TRYING_INSERTEDIT_COMPANY, TRYING_INSERTEDIT_COMPANY_SUCCESS, TRYING_INSERTEDIT_COMPANY_ERROR,
  TRYING_INSERTEDIT_ENDURANCE_GOAL, TRYING_INSERTEDIT_ENDURANCE_GOAL_SUCCESS, TRYING_INSERTEDIT_ENDURANCE_GOAL_ERROR,
  TRYING_INSERTEDIT_EXERCISE_GOAL, TRYING_INSERTEDIT_EXERCISE_GOAL_SUCCESS, TRYING_INSERTEDIT_EXERCISE_GOAL_ERROR,
  TRYING_INSERTEDIT_EXPENSE, TRYING_INSERTEDIT_EXPENSE_SUCCESS, TRYING_INSERTEDIT_EXPENSE_ERROR,
  TRYING_INSERTEDIT_FAVORITE_ENDURANCE, TRYING_INSERTEDIT_FAVORITE_ENDURANCE_SUCCESS, TRYING_INSERTEDIT_FAVORITE_ENDURANCE_ERROR,
  TRYING_INSERTEDIT_FAVORITE_EXERCISE, TRYING_INSERTEDIT_FAVORITE_EXERCISE_SUCCESS, TRYING_INSERTEDIT_FAVORITE_EXERCISE_ERROR,
  TRYING_INSERTEDIT_USER_GROUP_MEMBERS, TRYING_INSERTEDIT_USER_GROUP_MEMBERS_SUCCESS, TRYING_INSERTEDIT_USER_GROUP_MEMBERS_ERROR,
  TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES, TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR,
  TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS, TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR,
  TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES, TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR,
  TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER, TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_SUCCESS, TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_ERROR,
  TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL, TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_SUCCESS, TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_ERROR,
  TRYING_INSERTEDIT_ORGANIZATION_BRANCH, TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS, TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR,
  TRYING_INSERT_EDIT_ORGANIZATION_REGION, TRYING_INSERT_EDIT_ORGANIZATION_REGION_SUCCESS, TRYING_INSERT_EDIT_ORGANIZATION_REGION_ERROR,
  TRYING_INSERTEDIT_PRIVATE_DISH, TRYING_INSERTEDIT_PRIVATE_DISH_SUCCESS, TRYING_INSERTEDIT_PRIVATE_DISH_ERROR,
  TRYING_INSERTEDIT_PRIVATE_EXERCISE, TRYING_INSERTEDIT_PRIVATE_EXERCISE_SUCCESS, TRYING_INSERTEDIT_PRIVATE_EXERCISE_ERROR,
  TRYING_INSERTEDIT_PRIVATE_FOOD, TRYING_INSERTEDIT_PRIVATE_FOOD_SUCCESS, TRYING_INSERTEDIT_PRIVATE_FOOD_ERROR,
  TRYING_INSERTEDIT_PRODUCT, TRYING_INSERTEDIT_PRODUCT_SUCCESS, TRYING_INSERTEDIT_PRODUCT_ERROR,
  TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA, TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_SUCCESS, TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_ERROR,
  TRYING_INSERTEDIT_TRAINER_GROUP, TRYING_INSERTEDIT_TRAINER_GROUP_SUCCESS, TRYING_INSERTEDIT_TRAINER_GROUP_ERROR,
  TRYING_INSERTEDIT_TRAINING_PROGRAM, TRYING_INSERTEDIT_TRAINING_PROGRAM_SUCCESS, TRYING_INSERTEDIT_TRAINING_PROGRAM_ERROR,
  TRYING_INSERTEDIT_USER_GROUP, TRYING_INSERTEDIT_USER_GROUP_SUCCESS, TRYING_INSERTEDIT_USER_GROUP_ERROR,
  TRYING_INSERTEDIT_WORKOUT_NOTE, TRYING_INSERTEDIT_WORKOUT_NOTE_SUCCESS, TRYING_INSERTEDIT_WORKOUT_NOTE_ERROR,
  TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL, TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_SUCCESS, TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_ERROR,
  TRYING_INVITE_ORGANIZATION_MEMBERS, TRYING_INVITE_ORGANIZATION_MEMBERS_SUCCESS, TRYING_INVITE_ORGANIZATION_MEMBERS_ERROR,
  TRYING_LOGIN_USER, TRYING_LOGIN_USER_SUCCESS, TRYING_LOGIN_USER_ERROR, LOGOUT_USER,
  TRYING_MOVE_GROUP_WORKOUTS, TRYING_MOVE_GROUP_WORKOUTS_SUCCESS, TRYING_MOVE_GROUP_WORKOUTS_ERROR,
  TRYING_MOVE_MEALS, TRYING_MOVE_MEALS_SUCCESS, TRYING_MOVE_MEALS_ERROR,
  TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION, TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_SUCCESS, TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_ERROR,
  TRYING_MOVE_WORKOUTS, TRYING_MOVE_WORKOUTS_SUCCESS, TRYING_MOVE_WORKOUTS_ERROR,
  TRYING_PROCESS_STAGE_SUBSCRIBER, TRYING_PROCESS_STAGE_SUBSCRIBER_SUCCESS, TRYING_PROCESS_STAGE_SUBSCRIBER_ERROR,
  CLEAR_READ_ALL_NOTIFICATIONS, TRYING_READ_ALL_NOTIFICATIONS, TRYING_READ_ALL_NOTIFICATIONS_SUCCESS, TRYING_READ_ALL_NOTIFICATIONS_ERROR,
  TRYING_READ_NOTIFICATION, TRYING_READ_NOTIFICATION_SUCCESS, TRYING_READ_NOTIFICATION_ERROR,
  TRYING_REGENERATE_MAXSPEED, TRYING_REGENERATE_MAXSPEED_SUCCESS, TRYING_REGENERATE_MAXSPEED_ERROR,
  TRYING_REGENERATE_TESTSET, TRYING_REGENERATE_TESTSET_SUCCESS, TRYING_REGENERATE_TESTSET_ERROR,
  TRYING_REMOVE_FAVORITE_ENDURANCE, TRYING_REMOVE_FAVORITE_ENDURANCE_SUCCESS, TRYING_REMOVE_FAVORITE_ENDURANCE_ERROR,
  TRYING_REMOVE_FAVORITE_EXERCISE, TRYING_REMOVE_FAVORITE_EXERCISE_SUCCESS, TRYING_REMOVE_FAVORITE_EXERCISE_ERROR,
  TRYING_REORDER_EXERCISES, TRYING_REORDER_EXERCISES_SUCCESS, TRYING_REORDER_EXERCISES_ERROR,
  TRYING_REORDER_TRAINER_GROUPS, TRYING_REORDER_TRAINER_GROUPS_SUCCESS, TRYING_REORDER_TRAINER_GROUPS_ERROR, UPDATE_TRAINER_GROUP_ORDER,
  TRYING_SAVE_ORGANIZATION_CUSTOMIZATION, TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_SUCCESS, TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_ERROR,
  CLEAR_SEARCH_CURRENCIES, TRYING_SEARCH_CURRENCIES, TRYING_SEARCH_CURRENCIES_SUCCESS, TRYING_SEARCH_CURRENCIES_ERROR,
  CLEAR_SEARCH_DISHES, TRYING_SEARCH_DISHES, TRYING_SEARCH_DISHES_SUCCESS, TRYING_SEARCH_DISHES_ERROR,
  CLEAR_SEARCH_EXERCISES, TRYING_SEARCH_EXERCISES, TRYING_SEARCH_EXERCISES_SUCCESS, TRYING_SEARCH_EXERCISES_ERROR,
  CLEAR_SEARCH_EXPENSES, TRYING_SEARCH_EXPENSES, TRYING_SEARCH_EXPENSES_SUCCESS, TRYING_SEARCH_EXPENSES_ERROR,
  CLEAR_SEARCH_FOOD, TRYING_SEARCH_FOOD, TRYING_SEARCH_FOOD_SUCCESS, TRYING_SEARCH_FOOD_ERROR,
  TRYING_SEARCH_GROUP_MANAGERS, TRYING_SEARCH_GROUP_MANAGERS_SUCCESS, TRYING_SEARCH_GROUP_MANAGERS_ERROR,
  TRYING_SEARCH_GROUP_MEMBERS, TRYING_SEARCH_GROUP_MEMBERS_SUCCESS, TRYING_SEARCH_GROUP_MEMBERS_ERROR,
  TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS, TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_SUCCESS, TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_ERROR,
  CLEAR_SEARCH_GROUPS, TRYING_SEARCH_GROUPS, TRYING_SEARCH_GROUPS_SUCCESS, TRYING_SEARCH_GROUPS_ERROR,
  CLEAR_SEARCH_MEAL_TEMPLATES, TRYING_SEARCH_MEAL_TEMPLATES, TRYING_SEARCH_MEAL_TEMPLATES_SUCCESS, TRYING_SEARCH_MEAL_TEMPLATES_ERROR,
  TRYING_SEARCH_PAYOUTS, TRYING_SEARCH_PAYOUTS_SUCCESS, TRYING_SEARCH_PAYOUTS_ERROR,
  TRYING_SEARCH_PRODUCTS, TRYING_SEARCH_PRODUCTS_SUCCESS, TRYING_SEARCH_PRODUCTS_ERROR,
  TRYING_SEARCH_TRAINER_CLIENTS, TRYING_SEARCH_TRAINER_CLIENTS_SUCCESS, TRYING_SEARCH_TRAINER_CLIENTS_ERROR,
  TRYING_SEARCH_TRAINER_CLIENTS_SAYT, TRYING_SEARCH_TRAINER_CLIENTS_SAYT_SUCCESS, TRYING_SEARCH_TRAINER_CLIENTS_SAYT_ERROR,
  TRYING_SEARCH_TRAINER_CONTRACTS, TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS, TRYING_SEARCH_TRAINER_CONTRACTS_ERROR,
  TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS, TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS, TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR,
  CLEAR_SEARCH_TRAINING_PROGRAMS, TRYING_SEARCH_TRAINING_PROGRAMS, TRYING_SEARCH_TRAINING_PROGRAMS_SUCCESS, TRYING_SEARCH_TRAINING_PROGRAMS_ERROR,
  CLEAR_SEARCH_WORKOUT_TEMPLATES, TRYING_SEARCH_WORKOUT_TEMPLATES, TRYING_SEARCH_WORKOUT_TEMPLATES_SUCCESS, TRYING_SEARCH_WORKOUT_TEMPLATES_ERROR,
  TRYING_SUBSCRIBE_PAYMENT_GATEWAY, TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS, TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR,
  TRYING_TASKS_ACTION, TRYING_TASKS_ACTION_SUCCESS, TRYING_TASKS_ACTION_ERROR, TRYING_TASKS_ACTION_COPY,
  TRYING_UPDATE_CLIENT_ASSIGNING, TRYING_UPDATE_CLIENT_ASSIGNING_SUCCESS, TRYING_UPDATE_CLIENT_ASSIGNING_ERROR,
  TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY, TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_SUCCESS, TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_ERROR,
  TRYING_UPDATE_DAILY_ACTIVITY_LEVEL, TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_SUCCESS, TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_ERROR,
  TRYING_UPDATE_DISH_COMPLETION_STATUS, TRYING_UPDATE_DISH_COMPLETION_STATUS_SUCCESS, TRYING_UPDATE_DISH_COMPLETION_STATUS_ERROR,
  TRYING_UPDATE_DISH_DETAIL_FOOD, TRYING_UPDATE_DISH_DETAIL_FOOD_SUCCESS, TRYING_UPDATE_DISH_DETAIL_FOOD_ERROR,
  TRYING_UPDATE_MEAL_ACCESS, TRYING_UPDATE_MEAL_ACCESS_SUCCESS, TRYING_UPDATE_MEAL_ACCESS_ERROR,
  TRYING_UPDATE_NOTIFICATION_SETTINGS, TRYING_UPDATE_NOTIFICATION_SETTINGS_SUCCESS, TRYING_UPDATE_NOTIFICATION_SETTINGS_ERROR,
  TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS, TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_SUCCESS, TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_ERROR,
  TRYING_UPDATE_ORGANIZATION_INCOME, TRYING_UPDATE_ORGANIZATION_INCOME_SUCCESS, TRYING_UPDATE_ORGANIZATION_INCOME_ERROR,
  TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS, TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS, TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR,
  TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS, TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_SUCCESS, TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_ERROR,
  TRYING_UPDATE_PAUSED_SHARING_EXERCISES, TRYING_UPDATE_PAUSED_SHARING_EXERCISES_SUCCESS, TRYING_UPDATE_PAUSED_SHARING_EXERCISES_ERROR,
  TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES, TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_SUCCESS, TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_ERROR,
  TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS, TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_SUCCESS, TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_ERROR,
  TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES, TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_SUCCESS, TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_ERROR,
  TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES, TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS, TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_ERROR,
  TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES, TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_SUCCESS, TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_ERROR,
  TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS, TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_SUCCESS, TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_ERROR,
  TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES, TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_SUCCESS, TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES, TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES, TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_ERROR,
  TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES, TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_SUCCESS, TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_ERROR,
  TRYING_UPDATE_SUBSCRIPTION_STATUS,
  TRYING_UPDATE_TRAINER_CLIENT_COLOR, TRYING_UPDATE_TRAINER_CLIENT_COLOR_SUCCESS, TRYING_UPDATE_TRAINER_CLIENT_COLOR_ERROR,
  TRYING_UPDATE_TRAINER_CLIENT_NOTE, TRYING_UPDATE_TRAINER_CLIENT_NOTE_SUCCESS, TRYING_UPDATE_TRAINER_CLIENT_NOTE_ERROR,
  TRYING_UPDATE_USER_SETTINGS, TRYING_UPDATE_USER_SETTINGS_SUCCESS, TRYING_UPDATE_USER_SETTINGS_ERROR,
  TRYING_UPDATE_WORKOUT_INFORMATION, TRYING_UPDATE_WORKOUT_INFORMATION_SUCCESS, TRYING_UPDATE_WORKOUT_INFORMATION_ERROR,
  TRYING_UPDATE_WORKOUT_LAP, TRYING_UPDATE_WORKOUT_LAP_SUCCESS, TRYING_UPDATE_WORKOUT_LAP_ERROR,
  TRYING_UPDATE_WORKOUT_SET, TRYING_UPDATE_WORKOUT_SET_SUCCESS, TRYING_UPDATE_WORKOUT_SET_ERROR,
  TRYING_UPLOAD_ORGANIZATION_PICTURE, TRYING_UPLOAD_ORGANIZATION_PICTURE_SUCCESS, TRYING_UPLOAD_ORGANIZATION_PICTURE_ERROR,
  TRYING_UPLOAD_PROFILE_PICTURE, TRYING_UPLOAD_PROFILE_PICTURE_SUCCESS, TRYING_UPLOAD_PROFILE_PICTURE_ERROR,
  TRYING_USER_ACCOUNTS_ACTION, TRYING_USER_ACCOUNTS_ACTION_SUCCESS, TRYING_USER_ACCOUNTS_ACTION_ERROR,
  TRYING_VALIDATE_USER, TRYING_VALIDATE_USER_SUCCESS, TRYING_VALIDATE_USER_ERROR,
  TRYING_VIEW_AVAILABLE_LANGUAGES, TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS, TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR,
  CLEAR_VIEW_BODYGOAL_PROGRESS, TRYING_VIEW_BODYGOAL_PROGRESS, TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, TRYING_VIEW_BODYGOAL_PROGRESS_ERROR,
  TRYING_VIEW_BODYGOAL_PROGRESS_CHART, TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR,
  TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER, TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_SUCCESS, TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_ERROR,
  CLEAR_VIEW_ENDURANCE_PROGRESS, TRYING_VIEW_ENDURANCE_PROGRESS, TRYING_VIEW_ENDURANCE_PROGRESS_SUCCESS, TRYING_VIEW_ENDURANCE_PROGRESS_ERROR,
  TRYING_VIEW_ENDURANCE_TEMPLATES, TRYING_VIEW_ENDURANCE_TEMPLATES_SUCCESS, TRYING_VIEW_ENDURANCE_TEMPLATES_ERROR,
  TRYING_VIEW_EXPENSE_CATEGORIES, TRYING_VIEW_EXPENSE_CATEGORIES_SUCCESS, TRYING_VIEW_EXPENSE_CATEGORIES_ERROR,
  TRYING_VIEW_FOOD_UNITS, TRYING_VIEW_FOOD_UNITS_SUCCESS, TRYING_VIEW_FOOD_UNITS_ERROR,
  TRYING_VIEW_NOTIFICATION_HISTORY, TRYING_VIEW_NOTIFICATION_HISTORY_SUCCESS, TRYING_VIEW_NOTIFICATION_HISTORY_ERROR,
  CLEAR_NOTIFICATION_SETTINGS, TRYING_VIEW_NOTIFICATION_SETTINGS, TRYING_VIEW_NOTIFICATION_SETTINGS_SUCCESS, TRYING_VIEW_NOTIFICATION_SETTINGS_ERROR,
  TRYING_VIEW_NUTRITION_PROGRESS, TRYING_VIEW_NUTRITION_PROGRESS_SUCCESS, TRYING_VIEW_NUTRITION_PROGRESS_ERROR,
  CLEAR_NUTRITION_PREVIEW_FILTERS, TRYING_VIEW_NUTRITION_PREVIEW_FILTERS, TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_SUCCESS, TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_ERROR,
  TRYING_VIEW_ORGANIZATION_BRANCH, TRYING_VIEW_ORGANIZATION_BRANCH_SUCCESS, TRYING_VIEW_ORGANIZATION_BRANCH_ERROR,
  CLEAR_ORGANIZATION_DASHBOARD, TRYING_VIEW_ORGANIZATION_DASHBOARD, TRYING_VIEW_ORGANIZATION_DASHBOARD_SUCCESS, TRYING_VIEW_ORGANIZATION_DASHBOARD_ERROR,
  TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_SUCCESS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_ERROR,
  TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_SUCCESS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_ERROR,
  TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_SUCCESS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_ERROR,
  TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_SUCCESS, TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_ERROR,
  TRYING_VIEW_ORGANIZATION_MEMBERS, TRYING_VIEW_ORGANIZATION_MEMBERS_SUCCESS, TRYING_VIEW_ORGANIZATION_MEMBERS_ERROR,
  CLEAR_VIEW_STRENGTH_PROGRESS, TRYING_VIEW_STRENGTH_PROGRESS, TRYING_VIEW_STRENGTH_PROGRESS_SUCCESS, TRYING_VIEW_STRENGTH_PROGRESS_ERROR,
  TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD, TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_SUCCESS, TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_ERROR,
  TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY, TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_SUCCESS, TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_ERROR,
  TRYING_VIEW_TRAINER_PAYOUT_DETAIL, TRYING_VIEW_TRAINER_PAYOUT_DETAIL_SUCCESS, TRYING_VIEW_TRAINER_PAYOUT_DETAIL_ERROR,
  CLEAR_TRAINER_PAYOUT_SUMMARY, TRYING_VIEW_TRAINER_PAYOUT_SUMMARY, TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_SUCCESS, TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_ERROR,
  TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT, TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_SUCCESS, TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_ERROR,
  UPDATE_ERROR_MODAL_WARNING,
  REMOVE_PENDING_CONTENT_REQUEST,
  UPDATE_SELECTED_TAB,
  UPDATE_UNREAD_NOTIFICATION_COUNT, INIT_WS,

  TRYING_GET_UPLOAD_CONTENT, TRYING_GET_UPLOAD_CONTENT_SUCCESS, TRYING_GET_UPLOAD_CONTENT_ERROR,
  TRYING_UPLOAD_CONTENT, TRYING_UPLOAD_CONTENT_SUCCESS, TRYING_UPLOAD_CONTENT_ERROR, TRYING_UPLOAD_CONTENT_PROGRESS, UPDATE_PDF_LOGO
} from '../Types';

export const GetUploadContent = ({ PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_UPLOAD_CONTENT });

  var data = { PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}upload-content/GetUploadContent`, { ...data });

  if (response.data.Response === 1) {
    var { UploadContent } = response.data;

    dispatch({ type: TRYING_GET_UPLOAD_CONTENT_SUCCESS });
    return { UploadContent };
  } else {
    dispatch({ type: TRYING_GET_UPLOAD_CONTENT_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });

    return {};
  }
}

export const UploadContent = ({ ContentId, ContentName, IsMuted, IsProcessingVideos, UploadedContent }) => async dispatch => {
  dispatch({ type: TRYING_UPLOAD_CONTENT });
  var ExtraHeaderConfig = {
    onUploadProgress: progress => {
      const { loaded, total } = progress;
      console.log('progress: ', progress);
      dispatch({ type: TRYING_UPLOAD_CONTENT_PROGRESS, payload: { type: 'UploadContent', loaded, total } });
    }
  };

  var data = new FormData();
  if (ContentId) data.append('ContentId', ContentId);
  data.append('ContentName', ContentName);
  data.append('IsMuted', +IsMuted);
  data.append('IsProcessingVideos', IsProcessingVideos);
  data.append('UploadedContent', UploadedContent);

  const response = await axiosInstance.post(`${RIDGE_API}upload-content/InsertEditUploadContent`, data, ExtraHeaderConfig);

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPLOAD_CONTENT_SUCCESS });
  } else {
    dispatch({ type: TRYING_UPLOAD_CONTENT_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
  }
}


export const AccountIntegration_Login = ({ DOB, Email, Password, Sex, UserName }) => async dispatch => {
  dispatch({ type: TRYING_LOGIN_USER });

  var data = { DOB, Email, IntegrationId: 1, Password, Sex, UserName };

  const response = await axios.post(`${RIDGE_API}ptadmin/AccountIntegration_Login`, { ...data });

  if (response.data.Response === 1 || response.data.ErrorMessage === 'Needs More Info') {
    // var { data: UserDetails, JwtToken } = response.data;
    var { MembershipRequests, NeedsMoreInfo, SubscriptionStatus, Token, UnreadNotifications, UserDetails } = response.data;

    if (!NeedsMoreInfo) {
      UserDetails.MembershipRequests = MembershipRequests;
      UserDetails.SubscriptionStatus = SubscriptionStatus;

      localStorage.setItem("accessToken", Token);
      dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });
      dispatch({ type: UPDATE_UNREAD_NOTIFICATION_COUNT, payload: { NewNotification: false, UnreadCount: UnreadNotifications } });

      if (!UserDetails.OrganizationId) history.push('/clients');
      else history.push('/income');
    } else dispatch({ type: TRYING_LOGIN_USER_SUCCESS });

    return { NeedsMoreInfo };
  } else {
    dispatch({ type: TRYING_LOGIN_USER_ERROR, payload: response.data.ErrorMessage });

    return {};
  }
}

export const AddAdditionalActivities = ({ ActivityId, AssignedDates, ClientIds, CustomName, Duration, Groups, HasDateTimes, HasLocation, IntensityId, Location, TrainerNote }) => async dispatch => {
  dispatch({ type: TRYING_ADD_ADDITIONAL_ACTIVITIES });

  var data = { ActivityId, AssignedDates, ClientIds, Duration, Groups, HasDateTimes, HasLocation, IntensityId, Location, TrainerNote, WorkoutImage: '', WorkoutName: CustomName, WorkoutType: 3 };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddWorkout`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_ADD_ADDITIONAL_ACTIVITIES_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_ADDITIONAL_ACTIVITIES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_BodyFat = ({ BodyGoalTypeId, ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { BodyGoalTypeId, ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_BodyFat`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_BodyWeight = ({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_BodyWeight`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_Caliper = ({ BodyGoalTypeId, Calipers, ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var Measurements = Calipers.map(({ BodyAreaId, Current }) => ({ BodyAreaId, Measurement: Current }));

  var data = { BodyGoalTypeId, ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, MeasurementDate, Measurements, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_Caliper`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_Circumference = ({ BodyAreaId, ChartEndDate, ChartStartDate, Circumferences, ClientId, GetDashboardData, Measurement, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  // GoalMeasurements = [{ BodyAreaId: 1, GoalMinMeasurement: 3.5, GoalMaxMeasurement: 0 }, { BodyAreaId: 2, GoalMinMeasurement: 5 GoalMaxMeasurement: 8 }]
  // Measurements = [{ BodyAreaId: 1, Measurement: 3.5 }, { BodyAreaId: 2, Measurement: 1 }]

  var GoalMeasurements = [];
  var Measurements = [];

  Circumferences.map(({ BodyAreaId: InnerBodyArea, Current, GoalMax, GoalMin, HasGoals, MeasurementDate }) => {
    if (!HasGoals) { GoalMax = 0; GoalMin = 0 };

    GoalMeasurements.push({ BodyAreaId: InnerBodyArea, GoalMinMeasurement: GoalMin, GoalMaxMeasurement: GoalMax });
    Measurements.push({ BodyAreaId: InnerBodyArea, Measurement: Current, MeasurementDate });

    return null;
  });

  var data = { BodyAreaId, ChartEndDate, ChartStartDate, GetDashboardData, GoalMeasurements, Measurements, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_Circumference`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_MuscleMass = ({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_MuscleMass`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_Pain = ({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_Pain`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_RPE = ({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_RPE`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddBodyMeasurements_Sleep = ({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingHistoryIndex = null, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_ADD_MEASUREMENTS });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, GetDashboardData, GoalMaxMeasurement, GoalMinMeasurement, Measurement, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/AddBodyMeasurement_Sleep`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_ADD_MEASUREMENTS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, GetDashboardData, Measurement, TrackingHistory, TrackingHistoryIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_MEASUREMENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddClientJournalEntry = ({ ClientId, JournalEntry, JournalMedia }) => async dispatch => {
  dispatch({ type: TRYING_ADD_CLIENT_JOURNAL_ENTRY });

  var data = new FormData();
  data.append('ClientId', ClientId);
  data.append('JournalEntry', JournalEntry);
  if (JournalMedia.length > 0) data.append('JournalMedia', JournalMedia);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddClientJournalEntry`, data);

  if (response.data.Response === 1) {
    var { PreSignedUrls } = response.data;

    dispatch({ type: TRYING_ADD_CLIENT_JOURNAL_ENTRY_SUCCESS });

    return { PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_CLIENT_JOURNAL_ENTRY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const AddCompanyEmployees = ({ CompanyId, Emails, TrainerEmail }) => async dispatch => {
  dispatch({ type: TRYING_ADD_COMPANY_EMPLOYEES });

  var data = { CompanyId, Emails, TrainerEmail };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/AddCompanyEmployees`, { ...data });

  if (response.data.Response === 1) {
    var { Company } = response.data;

    dispatch({ type: TRYING_ADD_COMPANY_EMPLOYEES_SUCCESS });

    return { Company };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_COMPANY_EMPLOYEES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const AddExistingDish = ({ ClientIds, DishId, MealDates }) => async dispatch => {
  dispatch({ type: TRYING_ADD_EXISTING_DISH });

  var data = {};
  if (ClientIds) data.ClientIds = ClientIds.join(',');
  if (DishId) data.DishIds = `${DishId}`;
  if (MealDates) data.MealDates = MealDates.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addExistingDish`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_ADD_EXISTING_DISH_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_EXISTING_DISH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddExistingMealTemplate = ({ ClientIds, StartDate, TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_ADD_EXISTING_MEAL_TEMPLATE });

  var data = { ClientIds, StartDate, TemplateId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addMealsFromTemplate`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_ADD_EXISTING_MEAL_TEMPLATE_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_EXISTING_MEAL_TEMPLATE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddExistingWorkoutTemplate = ({ ActiveType, ActivityId, AssignedDates, AttachSurveys = [], CardioLevel, ClientIds, CustomImage, CustomName, DataType, Duration, EditOriginal, Groups, Injuries, IntensityId, IsModified = 0, Location, NeedsBaseTrainerNote, RepRange, SaveAsNew, StartTime, TemplateId, TrainerNote, TrainingFocus, Units, WorkoutExercises, WorkoutImage, WorkoutLaps, WorkoutType }) => async dispatch => {
  dispatch({ type: TRYING_ADD_EXISTING_WORKOUT_TEMPLATE });

  var data = { ActiveType, ActivityId, AssignedDates, CardioLevel, ClientIds, CustomImage, CustomName, DataType, Duration, EditOriginal, IntensityId, IsModified, Location, NeedsBaseTrainerNote, SaveAsNew, StartTime, TemplateId, TrainerNote, Units, WorkoutType };
  data.Injuries = Injuries ? Injuries.join(',') : '';
  data.RepRange = RepRange ? RepRange.join(',') : '';
  data.TrainingFocus = TrainingFocus ? TrainingFocus.join(',') : '';
  
  if (AttachSurveys) data.AttachSurveys = JSON.stringify(AttachSurveys);
  else data.AttachSurveys = AttachSurveys;

  if (Groups) data.Groups = JSON.stringify(Groups);
  if (WorkoutType === 1) data.WorkoutExercises = JSON.stringify(WorkoutExercises);
  else if (WorkoutType === 2) data.WorkoutLaps = JSON.stringify(WorkoutLaps);

  data.WorkoutImage = WorkoutImage ? JSON.stringify(WorkoutImage) : null;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addworkoutfromtemplate`, { ...data });

  if (response.data.Response === 1) {
    var { PreSignedUrls } = response.data;

    dispatch({ type: TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_SUCCESS });

    return { PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return {};
  }
}

export const AddGetStartedMeals = ({ ActivityLevel, Allergies, ClientId, ClientType, CustomCalories, DietTypeId, FromDashboard, GoalId, HasAccess, Height, HeightUnit, NumberOfMeals, Weight, WeightUnit }) => async dispatch => {
  dispatch({ type: TRYING_ADD_GET_STARTED_MEALS });

  var data = { ActivityLevel, Allergies, ClientId, CustomCalories, DietTypeId, GoalId, HasAccess, Height, HeightUnit, NumberOfMeals, Weight, WeightUnit };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addGetStarted_Meals`, { GetStartedData: data, ...FromDashboard && { ReturnDataType: 'Dashboard', UserDate: moment().format() } });

  if (response.data.Response === 1) {
    var { Client, Clients } = response.data;

    dispatch({ type: TRYING_ADD_GET_STARTED_MEALS_SUCCESS, payload: { ...Client, ClientId } });
    if (!!FromDashboard) dispatch({ type: TRYING_UPDATE_MEAL_ACCESS_SUCCESS, payload: { Clients, ClientType, ReturnDataType: 'Dashboard', UserId: ClientId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_GET_STARTED_MEALS_ERROR, payload: response.data.ErrorMessage });
      }
    }
  }
}

export const AddGetStartedWorkouts = ({ ClientId, DefaultGymId: DefaultGym, ExperienceLevel_Endurance, ExperienceLevel_Strength, Joints, Muscles, Weight, WeightUnit }) => async dispatch => {
  dispatch({ type: TRYING_ADD_GET_STARTED_WORKOUTS });

  var data = { ClientId, DefaultGym, ExperienceLevel_Endurance, ExperienceLevel_Strength, Joints, Muscles, Weight, WeightUnit };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addGetStarted_Workouts`, { ...data });

  if (response.data.Response === 1) {
    var { Client } = response.data;

    dispatch({ type: TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS, payload: { ...Client, ClientId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_GET_STARTED_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const AddGroupWorkoutMembers = ({ GroupId, GroupWorkoutId, PageNo, PageSize, UserIds }) => async dispatch => {
  dispatch({ type: TRYING_ADD_GROUP_WORKOUT_MEMBERS });

  var data = { GroupId, GroupWorkoutId, PageNo, PageSize };
  if (UserIds) data.UserIds = UserIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddGroupWorkoutMembers`, { ...data });

  if (response.data.Response === 1) {
    var { GroupMembers, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_ADD_GROUP_WORKOUT_MEMBERS_SUCCESS, payload: { GroupMembers, IsMoreResults, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_GROUP_WORKOUT_MEMBERS_ERROR, payload: response.data.ErrorMessage });
      }
    }
  }
}

export const AddLogFood = ({ dataFoodDetail, IsDone, MealDate, UserId }) => async dispatch => {
  dispatch({ type: TRYING_ADD_LOG_FOOD });

  var data = { dataFoodDetail, IsDone, MealDate, UserId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddLogFood`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_ADD_LOG_FOOD_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_LOG_FOOD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
      }
    }
  }
}

export const AddNewWorkout = ({ ActiveType, ActivityId, AssignedDates, AttachSurveys = [], CardioLevel, ClientIds, CopyImage, CopiedTemplateId, CustomImage, CustomName, DataType, Duration, Groups, HasDateTimes, IntensityId, Location, SaveAsNew = 0, TrainerNote, Units, WorkoutExercises, WorkoutImage, WorkoutLaps, WorkoutType }) => async dispatch => {
  dispatch({ type: TRYING_ADD_NEW_WORKOUT });

  var data = { ActivityId, AssignedDates, ClientIds, CopyImage, CopiedTemplateId, CustomImage, CustomName, Duration, HasDateTimes, Location, SaveAsNew, TrainerNote, Units, WorkoutType };
  if (Groups) data.Groups = JSON.stringify(Groups);
  data.WorkoutImage = WorkoutImage ? JSON.stringify(WorkoutImage) : null;
  if (IntensityId) data.IntensityId = IntensityId;

  if (AttachSurveys) data.AttachSurveys = JSON.stringify(AttachSurveys);
  else data.AttachSurveys = AttachSurveys;

  if (WorkoutType === 1) data.WorkoutExercises = JSON.stringify(WorkoutExercises);
  else if (WorkoutType === 2) {
    data.ActiveType = ActiveType;
    data.CardioLevel = CardioLevel;
    data.DataType = DataType;
    data.WorkoutLaps = JSON.stringify(WorkoutLaps);
  }

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddWorkout`, { ...data });

  if (response.data.Response === 1) {
    var { PreSignedUrls } = response.data;

    dispatch({ type: TRYING_ADD_NEW_WORKOUT_SUCCESS });

    return { PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_NEW_WORKOUT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
      }
    }

    return {};
  }
}

export const AddSingleGeneratedAbelWorkout = ({ ActivityId, AssignedDates, ClientId, CustomImage, CustomName, Duration, EnduranceTemplateId, ExperienceLevel_Endurance, ExperienceLevel_Strength, GoalId, GymId, HasDateTimes, JointPains, Location, MusclePains, TargetMuscles, TrainerNote, WorkoutImage, WorkoutType }) => async dispatch => {
  dispatch({ type: TRYING_ADD_SINGLE_GENERATED_WORKOUT });

  WorkoutType = WorkoutType === 'Strength' ? 1 : 2;

  var data = new FormData();

  if (WorkoutType === 1) {
    data.append('GoalId', GoalId);
    data.append('GymId', GymId);
    data.append('JointPains', JointPains);
    data.append('MusclePains', MusclePains);
    data.append('TargetMuscles', TargetMuscles);
  }
  else if (WorkoutType === 2) {
    data.append('EnduranceTemplateId', EnduranceTemplateId);
    data.append('EnduranceTypeId', 1);
  }

  data.append('AssignedDates', AssignedDates);
  data.append('ActivityId', ActivityId);
  data.append('ClientId', ClientId);
  data.append('CustomImage', CustomImage);
  data.append('CustomName', CustomName);
  data.append('Duration', Duration);
  data.append('ExperienceLevel_Endurance', ExperienceLevel_Endurance);
  data.append('ExperienceLevel_Strength', ExperienceLevel_Strength);
  data.append('HasDateTimes', +HasDateTimes);
  data.append('Location', Location);
  data.append('TrainerNote', TrainerNote);
  data.append('WorkoutImage', WorkoutImage ? JSON.stringify(WorkoutImage) : null);
  data.append('WorkoutType', WorkoutType);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddSingleGeneratedWorkout`, data);

  if (response.data.Response === 1) {
    var { PreSignedUrls } = response.data;

    dispatch({ type: TRYING_ADD_SINGLE_GENERATED_WORKOUT_SUCCESS });

    return { PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_SINGLE_GENERATED_WORKOUT_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return {};
  }
}

export const AddSubscriber = ({ CancelUrl, PromotionTypeId, ReturnUrl }) => async dispatch => {
  dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY });

  var data = { CancelUrl, PromotionTypeId, ReturnUrl, TimeZoneOffset: GetTimeZoneOffsetInHHMM() };

  const response = await axiosInstance.post(`${RIDGE_API}payments/AddSubscriber`, { ...data });

  console.log('response: ', JSON.parse(JSON.stringify(response)));

  if (response.data.Response === 1) {
    var { hostedPaymentPageUrl } = response.data;

    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS });

    return { hostedPaymentPageUrl };
  } else {
    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });

    return {};
  }
}

export const AddTrainerClient = ({ ClientIdentifier, DefaultGroups = [], PreRegister, UserId }) => async dispatch => {
  dispatch({ type: TRYING_ADD_TRAINER_CLIENT });

  var data = { ClientIdentifier, DefaultGroups, PreRegister };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/newclient`, { ...data });

  if (response.data.Response === 1) {
    var { IsEmail } = response.data;

    dispatch({ type: TRYING_ADD_TRAINER_CLIENT_SUCCESS, payload: { UserId } });
    return { IsEmail };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_ADD_TRAINER_CLIENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        // dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
      }
    }
    return {};
  }
}

export const AddTrainingProgram = ({ ClientIds, Groups, StartDate, TrainingProgramId }) => async dispatch => {
  dispatch({ type: TRYING_ADD_TRAINING_PROGRAM });

  var data = { ClientIds, Groups, StartDate, TrainingProgramId };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/AddTrainingProgram`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_ADD_TRAINING_PROGRAM_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_TRAINING_PROGRAM_ERROR, payload: response.data.ErrorMessage });
      }
    }
  }
}

export const AddTrainingProgram_Modified = ({ ClientIds, Groups, StartDate, TrainingProgram }) => async dispatch => {
  dispatch({ type: TRYING_ADD_TRAINING_PROGRAM });

  var data = { ClientIds, Groups, StartDate, TrainingProgram };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/AddModifiedTrainingProgram`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_ADD_TRAINING_PROGRAM_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_TRAINING_PROGRAM_ERROR, payload: response.data.ErrorMessage });
      }
    }
  }
}

export const AddWorkoutExercises = ({ ClientId, SelectedExercises, WorkoutId, WorkoutRM }) => async dispatch => {
  dispatch({ type: TRYING_ADD_WORKOUT_EXERCISES });

  var data = { ClientId, SelectedExercises, WorkoutId, WorkoutRM };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addexercises`, { ...data });

  if (response.data.Response === 1) {
    var { Exercises, WorkoutCompletionStatus: { WorkoutCompleted } } = response.data;

    dispatch({ type: TRYING_ADD_WORKOUT_EXERCISES_SUCCESS });
    return { AddedExercises: Exercises, WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_WORKOUT_EXERCISES_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return {};
  }
}

export const AddWorkoutLap = ({ ClientId, Active, BreakTime, Completed, Intensity, LapOrder, Rest, Speed, Unit, WorkoutEnduranceId, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_ADD_WORKOUT_LAP });

  var data = { ClientId, Active, BreakTime, Completed, Intensity, LapOrder, Rest, Speed, Unit, WorkoutEnduranceId, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddWorkoutLap`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { WorkoutCompleted }, WorkoutLapId } = response.data;

    dispatch({ type: TRYING_ADD_WORKOUT_LAP_SUCCESS });
    return { WorkoutCompleted, WorkoutLapId };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_WORKOUT_LAP_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return {};
  }
}

export const AddWorkoutSet = ({ ClientId, Distance, Reps, Rest, Seconds, Weight, WorkoutExerciseId, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_ADD_WORKOUT_SET });

  var data = { ClientId, Distance, Reps, Rest, Seconds, Weight, WorkoutExerciseId, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddWorkoutSet`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { WorkoutCompleted }, WorkoutSetId } = response.data;

    dispatch({ type: TRYING_ADD_WORKOUT_SET_SUCCESS });
    return { WorkoutCompleted, WorkoutSetId };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_ADD_WORKOUT_SET_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return {};
  }
}

export const AutoCompleteWorkout = ({ ClientId, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_AUTOCOMPLETE_WORKOUT });

  var data = { ClientId, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AutoCompleteWorkout`, { ...data });

  if (response.data.Response === 1) {
    var { Response, WorkoutCompletionStatus: { WorkoutCompleted } } = response.data;

    dispatch({ type: TRYING_AUTOCOMPLETE_WORKOUT_SUCCESS });

    return { Response, WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_AUTOCOMPLETE_WORKOUT_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return { Response: response.data.Response };
  }
}

export const BuildWorkoutPlan = ({ ClientIds, EnduranceTypes, GoalId, JointPains, MusclePains, TargetMuscles, TrainerNote, WorkoutAvailability }) => async dispatch => {
  dispatch({ type: TRYING_BUILD_WORKOUT_PLAN });

  var UserDate = moment();
  var data = { ClientIds, EnduranceTypes, GoalId, GroupIds: "", JointPains, MusclePains, TargetMuscles, TrainerNote, UserDate, WorkoutAvailability };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GenerateWorkoutPlan`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_BUILD_WORKOUT_PLAN_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_BUILD_WORKOUT_PLAN_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const BulkAddClients = ({ CSVFile }) => async dispatch => {
  dispatch({ type: TRYING_BULK_ADD_CLIENTS });

    const formData = new FormData();
    formData.append("CSVFile", CSVFile);

    const response = await axiosInstance.post(`${RIDGE_API}ptadmin/BulkAddClients`, formData);

    if (response.data.Response === 1) {
      dispatch({ type: TRYING_BULK_ADD_CLIENTS_SUCCESS });
    } else {
      if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
      else {
        dispatch({ type: TRYING_BULK_ADD_CLIENTS_ERROR, payload: response.data.ErrorMessage });

        if (response.data.BadToken) LogoutUser();
        else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
      }
    }

    return;
}

export const CalculateBodyFat = ({ BodyGoalTypeId, Measurements }) => async dispatch => {
  dispatch({ type: TRYING_CALCULATE_BODY_FAT });

  var data = { BodyGoalTypeId, Measurements };

  const response = await axiosInstance.post(`${RIDGE_API}progress/CalculateBodyFat`, { ...data });

  if (response.data.Response === 1) {
    var { BodyFat } = response.data;

    dispatch({ type: TRYING_CALCULATE_BODY_FAT_SUCCESS });

    return { BodyFat };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CALCULATE_BODY_FAT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const CancelSubscription = () => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/CancelSubscription`, null);

  if (response.data.Response === 1) {
    var { MembershipRequests, SubscriptionStatus, UserDetails } = response.data;

    UserDetails.MembershipRequests = MembershipRequests;
    UserDetails.SubscriptionStatus = SubscriptionStatus;

    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS, payload: { UserDetails } });
    // dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });
  } else {
    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
  }
}

export const ChangeDishesCategory = ({ CategoryId, DishIds, UserId }) => async dispatch => {
  dispatch({ type: TRYING_CHANGE_DISHES_CATEGORY });

  var data = { CategoryId, UserId };
  data.DishIds = DishIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ChangeDishesCategory`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_CHANGE_DISHES_CATEGORY_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CHANGE_DISHES_CATEGORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ChangePassword = ({ CurrentPassword, NewPassword }) => async dispatch => {
  dispatch({ type: TRYING_CHANGE_PASSWORD });

  var data = { CurrentPassword, NewPassword };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ChangePassword`, { ...data });

  if (response.data.Response === 1) {
    var { WrongPassword } = response.data;

    dispatch({ type: TRYING_CHANGE_PASSWORD_SUCCESS });
    return { WrongPassword };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CHANGE_PASSWORD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ChangeSelectedDishesRating = ({ DishIds, Rating, UserId }) => async dispatch => {
  dispatch({ type: TRYING_CHANGE_SELECTED_DISHES_RATING });

  var data = { Rating, UserId };
  data.DishIds = DishIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ChangeSelectedDishesRating`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_CHANGE_SELECTED_DISHES_RATING_SUCCESS, payload: { DishIds, Rating } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CHANGE_SELECTED_DISHES_RATING_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ChangeToBusinessFromOrgMember = () => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ChangeToBusinessFromOrgMember`, null);

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS, payload: { UserDetails } });
  } else {
    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
  }
}

export const ChangeWorkoutExerciseMaxSet = ({ ClientId, IsCurrentMaxSet, WorkoutExerciseId, WorkoutId, WorkoutSetId }) => async dispatch => {
  dispatch({ type: TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET });

  var data = { ClientId, IsCurrentMaxSet, WorkoutExerciseId, WorkoutId, WorkoutSetId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ChangeWorkoutExerciseMaxSet`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { CanDelete } } = response.data;

    dispatch({ type: TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_SUCCESS });
    return { CanDelete };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
      }
    }

    return {};
  }
}

export const ChangeWorkoutExerciseNote = ({ ExerciseNote, WorkoutExerciseId, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_CHANGE_WORKOUT_EXERCISE_NOTE });

  var data = { ExerciseNote, WorkoutExerciseId, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ChangeWorkoutExerciseNote`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { CanDelete } } = response.data;

    dispatch({ type: TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_SUCCESS });
    return { CanDelete };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
      }
    }

    return {};
  }
}

export const ChargeSubscription = ({ PaymentId }) => async dispatch => {
  dispatch({ type: TRYING_CHARGE_SUBSCRIPTION });

  var data = { PaymentId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ChargeSubscription`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_CHARGE_SUBSCRIPTION_SUCCESS, payload: { UserDetails } });
  } else {
    dispatch({ type: TRYING_CHARGE_SUBSCRIPTION_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
  }
}

export const CheckLogin = () => async dispatch => {
  if (window.location.pathname.indexOf('/session-restore') !== -1) {}
  else {
    // var SarbjitToken = localStorage.getItem("SarbjitToken");
    var Token = localStorage.getItem("accessToken");

    if (Token) {
      dispatch({ type: TRYING_LOGIN_USER });

      const response = await axiosInstance.post(`${RIDGE_API}ptadmin/getUserProfile`, {});

      if (response.data.Response === 1) {
        var { MembershipRequests, SubscriptionStatus, UserDetails } = response.data;

        UserDetails.MembershipRequests = MembershipRequests;
        UserDetails.SubscriptionStatus = SubscriptionStatus;

        dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });
      } else {
        dispatch({ type: TRYING_LOGIN_USER_ERROR, payload: response.data.ErrorMessage });

        if (!response.data.IsTokenValid) LogoutUserFunc();
        // dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
      }
    }
    else {
      dispatch({ type: LOGOUT_USER });
      history.push('/login');
    }
  }
}

export const ChooseExerciseTags = ({ Categories }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });

  var data = { Categories };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/InsertEditCategories`, { ...data });

  if (response.data.Response === 1) {
    var { IsDuplicate, Response, ...rest } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    if (!IsDuplicate) dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...rest } });

    return { IsDuplicate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ChoosePrimaryOrganization = ({ OrganizationUserId }) => async dispatch => {
  dispatch({ type: TRYING_CHOOSE_PRIMARY_ORGANIZATION });

  var data = { OrganizationUserId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ChoosePrimaryOrganization`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_CHOOSE_PRIMARY_ORGANIZATION_SUCCESS, payload: { OrganizationUserId } });
  } else {
    dispatch({ type: TRYING_CHOOSE_PRIMARY_ORGANIZATION_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
  }
}

export const ChooseTrainingProgramTags = ({ Categories }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });

  var data = { Categories };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/InsertEditCategories`, { ...data });

  if (response.data.Response === 1) {
    var { IsDuplicate, Response, ...rest } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    if (!IsDuplicate) dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...rest } });

    return { IsDuplicate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ChooseWorkoutTemplateTags = ({ Categories }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });

  var data = { Categories };

  const response = await axiosInstance.post(`${RIDGE_API}templates/InsertEditCategories`, { ...data });

  if (response.data.Response === 1) {
    var { IsDuplicate, Response, ...rest } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    if (!IsDuplicate) dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...rest } });

    return { IsDuplicate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ClearActivityLevels = () => async dispatch => {
  dispatch({ type: CLEAR_ACTIVITY_LEVELS });
}

export const ClearAdditionalActivityDetails = () => async dispatch => {
  dispatch({ type: CLEAR_ADDITIONAL_ACTIVITY_DETAILS });
}

export const ClearAddEditGroupSettings = () => async dispatch => {
  dispatch({ type: CLEAR_ADD_EDIT_GROUP_SETTINGS });
}

export const ClearAddTrainerClientError = () => async dispatch => {
  dispatch({ type: CLEAR_ADD_TRAINER_CLIENT_ERROR });
}

export const ClearBodyGoal = () => async dispatch => {
  dispatch({ type: CLEAR_VIEW_BODYGOAL_PROGRESS });
}

export const ClearBuildWorkoutPlanDetails = () => async dispatch => {
  dispatch({ type: CLEAR_BUILD_WORKOUT_PLAN_DETAILS });
}

export const ClearClientDashboard = () => async dispatch => {
  dispatch({ type: CLEAR_CLIENT_DASHBOARD });
}

export const ClearClientDetails = () => async dispatch => {
  dispatch({ type: CLEAR_CLIENT_DETAILS });
}

export const ClearCreateContractDetails = () => async dispatch => {
  dispatch({ type: CLEAR_CREATE_CONTRACT_DETAILS });
}

export const ClearCurrencies = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_CURRENCIES });
}

export const ClearDishDetail = () => async dispatch => {
  dispatch({ type: CLEAR_DISH_DETAIL });
}

export const ClearDishes = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_DISHES });
}

export const ClearEnduranceDetail = () => async dispatch => {
  dispatch({ type: CLEAR_ENDURANCE_DETAIL });
}

export const ClearEnduranceProgress = () => async dispatch => {
  dispatch({ type: CLEAR_VIEW_ENDURANCE_PROGRESS });
}

export const ClearExerciseDetail = () => async dispatch => {
  dispatch({ type: CLEAR_EXERCISE_DETAIL });
}

export const ClearExercises = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_EXERCISES });
}

export const ClearExpenses = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_EXPENSES });
}

export const ClearGetStarted = () => async dispatch => {
  dispatch({ type: CLEAR_GET_STARTED });
}

export const ClearGroupDetails = () => async dispatch => {
  dispatch({ type: CLEAR_GROUP_DETAILS });
}

export const ClearGroupWorkoutDetail = () => async dispatch => {
  dispatch({ type: CLEAR_GROUP_WORKOUT_DETAIL });
}

export const ClearGroups = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_GROUPS });
}

export const ClearGyms = () => async dispatch => {
  dispatch({ type: CLEAR_GYMS });
}

export const ClearMarkAllAsRead = () => async dispatch => {
  dispatch({ type: CLEAR_READ_ALL_NOTIFICATIONS });
}

export const ClearMealTemplateDetail = () => async dispatch => {
  dispatch({ type: CLEAR_MEAL_TEMPLATE_DETAIL });
}

export const ClearMealTemplates = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_MEAL_TEMPLATES });
}

export const ClearNewlyReadNotificationId = () => async dispatch => {
  dispatch({ type: CLEAR_NEWLY_READ_NOTIFICATION_ID });
}

export const ClearNotificationSettings = () => async dispatch => {
  dispatch({ type: CLEAR_NOTIFICATION_SETTINGS });
}

export const ClearNutrition = () => async dispatch => {
  dispatch({ type: CLEAR_NUTRITION });
}

export const ClearNutritionPreviewFilters = () => async dispatch => {
  dispatch({ type: CLEAR_NUTRITION_PREVIEW_FILTERS });
}

export const ClearOrganizationDashboard = () => async dispatch => {
  dispatch({ type: CLEAR_ORGANIZATION_DASHBOARD });
}

export const ClearPrivateDishSettings = () => async dispatch => {
  dispatch({ type: CLEAR_PRIVATE_DISH_SETTINGS });
}

export const ClearPrivateExerciseSettings = () => async dispatch => {
  dispatch({ type: CLEAR_PRIVATE_EXERCISE_SETTINGS });
}

export const ClearPrivateFoodSettings = () => async dispatch => {
  dispatch({ type: CLEAR_VIEW_PRIVATE_FOOD_SETTINGS });
}

export const ClearProgressDashboard = () => async dispatch => {
  dispatch({ type: CLEAR_PROGRESS_DASHBOARD });
}

export const ClearSaveData = () => async dispatch => {
  dispatch({ type: CLEAR_SAVE_DATA });
}

export const ClearSearchFood = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_FOOD });
}

export const ClearSingleGeneratedWorkoutDetails = () => async dispatch => {
  dispatch({ type: CLEAR_SINGLE_GENERATED_WORKOUT_DETAILS });
}

export const ClearSingleVideoSession = () => async dispatch => {
  dispatch({ type: CLEAR_SINGLE_VIDEO_SESSION });
}

export const ClearStrengthProgress = () => async dispatch => {
  dispatch({ type: CLEAR_VIEW_STRENGTH_PROGRESS });
}

export const ClearTrainerClients = () => async dispatch => {
  dispatch({ type: CLEAR_TRAINER_CLIENTS });
}

export const ClearTrainerPayoutSummary = () => async dispatch => {
  dispatch({ type: CLEAR_TRAINER_PAYOUT_SUMMARY });
}

export const ClearTrainingProgram = () => async dispatch => {
  dispatch({ type: CLEAR_TRAINING_PROGRAM_DETAIL });
}

export const ClearTrainingPrograms = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_TRAINING_PROGRAMS });
}

export const ClearWorkoutDetail = () => async dispatch => {
  dispatch({ type: CLEAR_WORKOUT_DETAIL });
}

export const ClearWorkoutTemplateDetail = () => async dispatch => {
  dispatch({ type: CLEAR_WORKOUT_TEMPLATE_DETAIL });
}

export const ClearWorkoutTemplates = () => async dispatch => {
  dispatch({ type: CLEAR_SEARCH_WORKOUT_TEMPLATES });
}

export const ClientDashboard = ({ ClientType, GroupId, GroupIndex = null, GroupsPageNo, GroupsPageSize, GroupType, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_DASHBOARD });

  var data = { ClientType, GroupId, GroupsPageNo, GroupsPageSize, GroupType, PageNo, PageSize, UserDate: moment().format() };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ClientDashboard`, { ...data });

  if (response.data.Response === 1) {
    var { Clients, Groups } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_DASHBOARD_SUCCESS, payload: { Clients, GroupIndex, Groups } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_DASHBOARD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const CopyGroupWorkouts = ({ AssignedDates, Groups, GroupWorkoutIds }) => async dispatch => {
  dispatch({ type: TRYING_COPY_GROUP_WORKOUTS });

  var data = { Groups };
  data.AssignedDates = AssignedDates.join(',');
  data.GroupWorkoutIds = GroupWorkoutIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/copyworkouts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_COPY_GROUP_WORKOUTS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_COPY_GROUP_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
      }
    }
  }
}

export const CopyMeals = ({ AssignedDates, ClientIds, MealIds }) => async dispatch => {
  dispatch({ type: TRYING_COPY_MEALS });

  var data = {};
  data.AssignedDates = AssignedDates.join(',');
  data.ClientIds = ClientIds.join(',');
  data.MealIds = MealIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/CopyMeals`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_COPY_MEALS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_COPY_MEALS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
      }
    }
  }
}

export const CopyWorkouts = ({ AssignedDates, ClientIds, CopyNotes, WorkoutIds }) => async dispatch => {
  dispatch({ type: TRYING_COPY_WORKOUTS });

  var data = {};
  data.AssignedDates = AssignedDates.join(',');
  data.ClientIds = ClientIds.join(',');
  data.CopyNotes = +CopyNotes;
  data.WorkoutIds = WorkoutIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/CopyWorkouts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_COPY_WORKOUTS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_COPY_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
      }
    }
  }
}

export const CreateContract = ({ ClientId, CurrencyId, OrganizationBranchId, PaymentSchedule, Products }) => async dispatch => {
  dispatch({ type: TRYING_CREATE_CONTRACT });

  var data = { ClientId, CurrencyId, OrganizationBranchId, PaymentSchedule, Products };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/CreateContract`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_CREATE_CONTRACT_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CREATE_CONTRACT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const CreateDishCategory = CategoryName => async dispatch => {
  dispatch({ type: TRYING_CREATE_DISH_CATEGORY });

  var data = { CategoryName };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addDishCategory`, { ...data });

  if (response.data.Response === 1) {
    var { LastCategoryId } = response.data;

    dispatch({ type: TRYING_CREATE_DISH_CATEGORY_SUCCESS, payload: { CategoryId: LastCategoryId, CategoryName } });
    return LastCategoryId;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CREATE_DISH_CATEGORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const CreateVideoSession = SessionData => async dispatch => {
  dispatch({ type: TRYING_CREATE_VIDEO_SESSION });

  const response = await axiosInstance.post(`${RIDGE_API}sessions/create-session`, { ...SessionData });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_CREATE_VIDEO_SESSION_SUCCESS });
    return response.data;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_CREATE_VIDEO_SESSION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const CurusLogin = code => async dispatch => {
  var data = { code };

  const response = await axios.post(`${RIDGE_API}ptadmin/curus_login`, { ...data });

  if (response.data.Response === 1) {
    var { CurusData } = response.data;

    return ({ CurusData });
  }
}

export const DeleteBodyMeasurement_BodyFat = ({ BodyGoalTypeId, ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { BodyGoalTypeId, ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_BodyFat`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteBodyMeasurement_BodyWeight = ({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_BodyWeight`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteBodyMeasurement_Circumference = ({ BodyAreaId, ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { BodyAreaId, ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_Circumference`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteBodyMeasurement_MuscleMass = ({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_MuscleMass`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteBodyMeasurement_Pain = ({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_Pain`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteBodyMeasurement_RPE = ({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_RPE`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteBodyMeasurement_Sleep = ({ ChartEndDate, ChartStartDate, ClientId, TrackingHistoryId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT });

  var UserDate = moment().format();
  ChartEndDate = ChartEndDate || moment().format('YYYY-MM-DD');
  ChartStartDate = ChartStartDate || moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/DeleteBodyMeasurement_Sleep`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } = response.data;

    dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteCompanyEmployees = ({ CompanyId, UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_COMPANY_EMPLOYEES });

  var data = { CompanyId, UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/DeleteCompanyEmployees`, { ...data });

  if (response.data.Response === 1) {
    var { Company } = response.data;

    dispatch({ type: TRYING_DELETE_COMPANY_EMPLOYEES_SUCCESS });

    return { Company };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_COMPANY_EMPLOYEES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const DeleteContract = ({ ContractId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_CONTRACT });

  var data = { ContractId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/DeleteContract`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_CONTRACT_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_CONTRACT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteDishDetailFood = ({ ClientId, DishId, FoodId, MealPlanUId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_DISH_DETAIL_FOOD });

  var data = { DishId, FoodId, MealPlanUId, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/deleteDishDetailFood`, { DeleteDishDetailFoodData: data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_DISH_DETAIL_FOOD_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_DISH_DETAIL_FOOD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteDishes = ({ DishId, DishIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_DISHES });

  var data = { DishIds: [] };
  if (DishId) data.DishIds.push(DishId);
  if (DishIds) data.DishIds.push(DishIds);

  data.DishIds = data.DishIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteSelectedPrivateDishes`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_DISHES_SUCCESS, payload: { DishIds: data.DishIds.split(',').map(Number) } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_DISHES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteExercises = ({ ExerciseId, ExerciseIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_EXERCISES });

  var data = { ExerciseIds: [] };
  if (ExerciseId) data.ExerciseIds.push(ExerciseId);
  if (ExerciseIds) data.ExerciseIds.push(ExerciseIds);

  data.ExerciseIds = data.ExerciseIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}exercises/DeleteExercises`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_EXERCISES_SUCCESS, payload: { ExerciseIds: data.ExerciseIds.split(',').map(Number) } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteExerciseTags = ({ TagIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });

  var data = { TagIds: TagIds.join(',') };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/DeleteTags`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteExpense = ({ ExpenseId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_EXPENSE });

  var data = { ExpenseId };

  const response = await axiosInstance.post(`${RIDGE_API}expenses/DeleteExpense`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_EXPENSE_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_EXPENSE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteFoods = ({ DishesList, UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_FOODS });

  var data = { lstDishes: DishesList, UserId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteFoods`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_FOODS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_FOODS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteFoodsConfirmation = ({ FoodIds, UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_FOODS_CONFIRMATION });

  var data = { UserId };
  if (FoodIds && FoodIds.length) data.FoodIds = FoodIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteFoodsConfirmation`, { ...data });

  if (response.data.Response === 1) {
    var { DishesList, NeedsConfirm } = response.data;

    dispatch({ type: TRYING_DELETE_FOODS_CONFIRMATION_SUCCESS });
    return { DishesList, NeedsConfirm };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_FOODS_CONFIRMATION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const DeleteGroupWorkouts = GroupWorkoutIds => async dispatch => {
  dispatch({ type: TRYING_DELETE_GROUP_WORKOUTS });

  var data = {};
  data.GroupWorkoutIds = GroupWorkoutIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/deleteworkouts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_GROUP_WORKOUTS_SUCCESS, payload: GroupWorkoutIds });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_GROUP_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
    }
  }
}

export const DeleteMealDish = MealPlanUId => async dispatch => {
  dispatch({ type: TRYING_DELETE_MEAL_DISH });

  var data = { MealPlanUId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/deleteMealDish`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_MEAL_DISH_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_MEAL_DISH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteMeals = MealIds => async dispatch => {
  dispatch({ type: TRYING_DELETE_MEALS });

  var data = {};
  data.MealIds = MealIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteMeals`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_MEALS_SUCCESS, payload: MealIds });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_MEALS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
    }
  }
}

export const DeleteMealTemplates = ({ TemplateId, TemplateIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_MEAL_TEMPLATES });

  var data = { TemplateIds: [] };
  if (TemplateId) data.TemplateIds.push(TemplateId);
  if (TemplateIds) data.TemplateIds.push(TemplateIds);

  data.TemplateIds = data.TemplateIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/deletetemplates_meals`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_MEAL_TEMPLATES_SUCCESS, payload: { TemplateIds: data.TemplateIds.split(',').map(Number) } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_MEAL_TEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteOrganizationBranch = ({ NewOrganizationBranchId, OrganizationBranchId, BranchIndex, RegionIndex }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_ORGANIZATION_BRANCH });

  var data = { NewOrganizationBranchId, OrganizationBranchId, BranchIndex, RegionIndex };

  const response = await axiosInstance.post(`${RIDGE_API}payments/DeleteOrganizationBranch`, { ...data });

  if (response.data.Response === 1) {
    var { LastBranch, NeedReplacement } = response.data;

    dispatch({ type: TRYING_DELETE_ORGANIZATION_BRANCH_SUCCESS });
    return { LastBranch, NeedReplacement };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_ORGANIZATION_BRANCH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const DeleteOrganizationMember = ({ HistoryId, MemberIndex, MemberType, OrganizationMemberId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_ORGANIZATION_MEMBER });

  var data = { HistoryId, OrganizationMemberId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/DeleteOrganizationMember`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_ORGANIZATION_MEMBER_SUCCESS, payload: { MemberIndex, MemberType } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_ORGANIZATION_MEMBER_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteOrganizationRegion = ({ RegionId, RegionIndex }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_ORGANIZATION_REGION });

  var data = { RegionId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/DeleteOrganizationRegion`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_ORGANIZATION_REGION_SUCCESS, payload: { RegionIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_ORGANIZATION_REGION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteProducts = ({ ProductIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_PRODUCTS });

  var data = { ProductIds: ProductIds.length > 0 ? ProductIds.join(',') : '' };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteProducts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_PRODUCTS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_PRODUCTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteTrainerClient = ({ ClientId, ClientIndex, ClientType, FromDashboard }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_TRAINER_CLIENT, payload: ClientId });

  var data = { ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/deleteclient`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_TRAINER_CLIENT_SUCCESS, payload: { ClientId, ClientIndex, ...FromDashboard && { ClientType, ReturnDataType: 'Dashboard' } } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_TRAINER_CLIENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteTrainerGroup = ({ GroupId, GroupType }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_TRAINER_GROUP });

  var data = { GroupId, GroupType };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/deletetrainergroup`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_TRAINER_GROUP_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_TRAINER_GROUP_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteTrainingPrograms = ({ TrainingProgramId, TrainingProgramIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_TRAINING_PROGRAMS });

  var data = { TrainingProgramIds: [] };
  if (TrainingProgramId) data.TrainingProgramIds.push(TrainingProgramId);
  if (TrainingProgramIds) data.TrainingProgramIds.push(TrainingProgramIds);

  data.TrainingProgramIds = data.TrainingProgramIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/DeleteTrainingPrograms`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_TRAINING_PROGRAMS_SUCCESS, payload: { TrainingProgramIds: data.TrainingProgramIds.split(',').map(Number) } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_TRAINING_PROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteTrainingProgramTags = ({ TagIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });

  var data = { TagIds: TagIds.join(',') };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/DeleteTags`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteVideoSession = SessionData => async dispatch => {
  dispatch({ type: TRYING_DELETE_VIDEO_SESSION });

  const response = await axiosInstance.delete(`${RIDGE_API}sessions/delete-session`, { data: { ...SessionData } });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_VIDEO_SESSION_SUCCESS, payload: response.data.videoSessionId });
    return response.data;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_VIDEO_SESSION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteWorkout = ({ ClientId, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_WORKOUT });

  var data = { ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/workout/${WorkoutId}/delete`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_WORKOUT_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_WORKOUT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteWorkoutExercise = ({ ClientId, WorkoutExerciseId, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_WORKOUT_EXERCISE });

  var data = { ClientId, WorkoutExerciseId, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteWorkoutExercise`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { WorkoutCompleted } } = response.data;

    dispatch({ type: TRYING_DELETE_WORKOUT_EXERCISE_SUCCESS });
    return { WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_WORKOUT_EXERCISE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeleteWorkoutLap = ({ ClientId, WorkoutId, WorkoutLapId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_WORKOUT_LAP });

  var data = { ClientId, WorkoutId, WorkoutLapId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteWorkoutLap`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { WorkoutCompleted } } = response.data;

    dispatch({ type: TRYING_DELETE_WORKOUT_LAP_SUCCESS });
    return { WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_WORKOUT_LAP_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const DeleteWorkouts = WorkoutIds => async dispatch => {
  dispatch({ type: TRYING_DELETE_WORKOUTS });

  var data = {};
  data.WorkoutIds = WorkoutIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteWorkouts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_WORKOUTS_SUCCESS, payload: WorkoutIds });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
    }
  }
}

export const DeleteWorkoutSet = ({ ClientId, WorkoutId, WorkoutSetId }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_WORKOUT_SET });

  var data = { ClientId, WorkoutId, WorkoutSetId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DeleteWorkoutSet`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { WorkoutCompleted } } = response.data;

    dispatch({ type: TRYING_DELETE_WORKOUT_SET_SUCCESS });
    return { WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_WORKOUT_SET_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const DeleteWorkoutTemplates = ({ TemplateId, TemplateIds }) => async dispatch => {
  dispatch({ type: TRYING_DELETE_WORKOUT_TEMPLATES });

  var data = { TemplateIds: [] };
  if (TemplateId) data.TemplateIds.push(TemplateId);
  if (TemplateIds) data.TemplateIds.push(TemplateIds);

  data.TemplateIds = data.TemplateIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}templates/DeleteWorkoutTemplates`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELETE_WORKOUT_TEMPLATES_SUCCESS, payload: { TemplateIds: data.TemplateIds.split(',').map(Number) } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELETE_WORKOUT_TEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DeleteWorkoutTemplateTags = ({ TagIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });

  var data = { TagIds: TagIds.join(',') };

  const response = await axiosInstance.post(`${RIDGE_API}templates/DeleteTags`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_GetCompanies = ({ CompanyName, DHC_UserIds, IsIndividual, IsParent, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyName, DHC_UserIds, IsIndividual, IsParent, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetCompanies`, { ...data });

  if (response.data.Response === 1) {
    var { Companies, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { Companies, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetCompanies_Basic = ({ IsNotParent, IsParent }) => async dispatch => {

  var data = { IsNotParent, IsParent };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetCompanies_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Companies } = response.data;
    return { Companies };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetCompanyDetails = ({ CompanyId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetCompanyDetails`, { ...data });

  if (response.data.Response === 1) {
    var { CompanyDetails } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { CompanyDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetDeliveryTracking = ({ CheckingDate, OrderId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CheckingDate, OrderId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetDeliveryTracking`, { ...data });

  if (response.data.Response === 1) {
    var { DeliveryTracking } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { DeliveryTracking };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetDHCs = ({ CompanyIds, DHCName, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyIds, DHCName, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetDHCs`, { ...data });

  if (response.data.Response === 1) {
    var { DHCs, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { DHCs, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetDHCDetails = ({ DHC_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { DHC_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetDHCDetails`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { UserDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetDHCs_Basic = () => async dispatch => {

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetDHCs_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Users } = response.data;
    return { Users };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetEmployeeDetails = ({ Employee_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Employee_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetEmployeeDetails`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { UserDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetEmployees = ({ CompanyIds, DHC_UserIds, EmployeeName, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyIds, DHC_UserIds, EmployeeName, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetEmployees`, { ...data });

  if (response.data.Response === 1) {
    var { Employees, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { Employees, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetEmployees_Basic = ({ CompanyId, GetDefaultCoach }) => async dispatch => {

  var data = { CompanyId, GetDefaultCoach };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetEmployees_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Users } = response.data;
    return { Users };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetLeaderDetails = ({ Leader_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Leader_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetLeaderDetails`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { UserDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetLeaders = ({ CompanyIds, LeaderName, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyIds, LeaderName, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetLeaders`, { ...data });

  if (response.data.Response === 1) {
    var { Leaders, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { Leaders, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetLeaders_Basic = () => async dispatch => {

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetLeaders_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Users } = response.data;
    return { Users };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetMiddleLeaders = ({ CompanyIds, MiddleLeaderName, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyIds, MiddleLeaderName, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetMiddleLeaders`, { ...data });

  if (response.data.Response === 1) {
    var { MiddleLeaders, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { MiddleLeaders, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetMiddleLeaderDetails = ({ MiddleLeader_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { MiddleLeader_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetMiddleLeaderDetails`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { UserDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetMiddleLeaders_Basic = () => async dispatch => {

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetMiddleLeaders_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Users } = response.data;
    return { Users };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetInvoiceEmployees = ({ InvoiceId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { InvoiceId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetInvoiceEmployees`, { ...data });

  if (response.data.Response === 1) {
    var { Employees } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { Employees };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetOrderDetails = ({ OrderId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { OrderId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetOrderDetails`, { ...data });

  if (response.data.Response === 1) {
    var { Order } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { Order };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetOrders = ({ CompanyIds, DHC_UserIds, Employee_UserIds, HasOverdueInvoices, IsActive, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyIds, DHC_UserIds, Employee_UserIds, HasOverdueInvoices, IsActive, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetOrders`, { ...data });

  if (response.data.Response === 1) {
    var { Orders, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { Orders, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetOrders_Basic = () => async dispatch => {

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetOrders_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Orders } = response.data;
    return { Orders };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetProducts_Basic = () => async dispatch => {

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetProducts_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Products } = response.data;
    return { Products };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetSalesByProducts = ({ DHC_UserIds, EndDate, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { DHC_UserIds, EndDate, StartDate };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetSalesByProducts`, { ...data });

  if (response.data.Response === 1) {
    var { EndDate: ResposeEndDate, Months, Products, StartDate: ResponseStartDate } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { EndDate: ResposeEndDate, Months, Products, StartDate: ResponseStartDate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetTopLeaders = ({ CompanyIds, TopLeaderName, PageNo, PageSize, SortCol, SortDir }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyIds, TopLeaderName, PageNo, PageSize, SortCol, SortDir };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetTopLeaders`, { ...data });

  if (response.data.Response === 1) {
    var { TopLeaders, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { TopLeaders, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetTopLeaderDetails = ({ TopLeader_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { TopLeader_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetTopLeaderDetails`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
    return { UserDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_GetTopLeaders_Basic = () => async dispatch => {

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/GetTopLeaders_Basic`, { ...data });

  if (response.data.Response === 1) {
    var { Users } = response.data;
    return { Users };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const DeliverySite_InsertEditCompany = ({ CompanyName }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyName };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertEditCompany`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertEditDHC = ({ Email, Leader_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Email, Leader_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertEditDHC`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertEditEmployee = ({ CompanyId, DefaultDHC_UserId, Email }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyId, DefaultDHC_UserId, Email };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertEditEmployee`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertEditLeader = ({ Email, MiddleLeader_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Email, MiddleLeader_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertEditLeader`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertEditMiddleLeader = ({ Email, TopLeader_UserId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Email, TopLeader_UserId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertEditMiddleLeader`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertEditTopLeader = ({ Email }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Email };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertEditTopLeader`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertInvoice = ({ Employees, Invoices, OrderId }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { Invoice: { Employees, ...Invoices[0] }, OrderId };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertInvoice`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DeliverySite_InsertOrder = ({ CompanyId, Employees, Invoices, ProductId, UnitPrice }) => async dispatch => {
  dispatch({ type: TRYING_DELIVERY_SITE_ACTION });

  var data = { CompanyId, Employees, Invoices, ProductId, UnitPrice };

  const response = await axiosInstance.post(`${RIDGE_API}delivery-site/InsertOrder`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_DELIVERY_SITE_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DELIVERY_SITE_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const DownloadContractPDF = ({ ContractId }) => async dispatch => {
  dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF });

  var data = { ContractId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewContractPdf`, { ...data });

  if (response.data.Response === 1) {
    var { ContractPDFUrl } = response.data;

    window.open(ContractPDFUrl, "_blank");

    dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DownloadContractInvoicePDF = ({ InvoiceId }) => async dispatch => {
  dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF });

  var data = { InvoiceId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewContractInvoicePdf`, { ...data });

  if (response.data.Response === 1) {
    var { InvoiceUrl } = response.data;

    window.open(InvoiceUrl, "_blank");

    dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DownloadOrganizationIncomeReport = ({ OrgName = '', StartDate, EndDate }) => async dispatch => {
  dispatch({ type: TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT });

  var data = { StartDate, EndDate };

  const response = await axiosInstance.post(`${RIDGE_API}payments/DownloadIncomeReport`, { ...data });

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

    var blob = new Blob([s2ab(atob(response.data))], { type: 'application/vnd.openxmlformats' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${OrgName.replace(/\s/g, "")}_${StartDate}_${EndDate}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    dispatch({ type: TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_SUCCESS });
}

export const DownloadSubscriptionReceipt = ({ TransactionId }) => async dispatch => {
  dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF });

  var data = { TransactionId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetSubscriptionReceipt`, { ...data });

  if (response.data.Response === 1) {
    var { ReceiptS3Url } = response.data;

    dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS });

    window.open(ReceiptS3Url, "_blank");
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const DownloadTrainerPayoutSummary = ({ CSVFileName }) => async dispatch => {
  dispatch({ type: TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY });
  var ExtraHeaderConfig = { responseType: 'blob' };

  var data = { CSVFileName };

  // const response = await axiosInstance.post(`${RIDGE_API}ptadmin/DownloadTrainerPayoutPdf`, { ...data });
  const response = await axiosInstance.post(`http://localhost:3005/v1/payments/GetTrainerPayoutDetail`, { ...data }, ExtraHeaderConfig);

  if (!response) dispatch({ type: TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR, payload: 'Error Downloading PDF' });
  else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${CSVFileName}.pdf`);
    document.body.appendChild(link);
    link.click();

    dispatch({ type: TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS });
  }

  // if (response.data.Response === 1) {
  //     dispatch({ type: TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS });
  // } else {
  //     dispatch({ type: TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR, payload: response.data.ErrorMessage });

  //     if (response.data.BadToken) LogoutUser();
  //     else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
  // }
}

export const EditExerciseTag = ({ TagId, TagName }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });

  var data = { TagId, TagName };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/EditTag`, { ...data });

  if (response.data.Response === 1) {
    var { IsDuplicate, Response, ...rest } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    if (!IsDuplicate) dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...rest } });

    return { IsDuplicate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const EditGroupWorkoutDetail = ({ ActivityId, AssignedDate, CustomName, Duration, GroupWorkoutId, HasDateTimes, HasLocation, IntensityId, Location, TrainerNote, WorkoutType }) => async dispatch => {
  dispatch({ type: TRYING_EDIT_GROUP_WORKOUT_DETAIL });

  var data = { ActivityId, AssignedDate, CustomName, Duration, GroupWorkoutId, HasDateTimes, HasLocation, IntensityId, Location, TrainerNote, WorkoutType };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/workouts/${GroupWorkoutId}/edit`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_EDIT_GROUP_WORKOUT_DETAIL_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_EDIT_GROUP_WORKOUT_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const EditTrainingProgramTag = ({ TagId, TagName }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });

  var data = { TagId, TagName };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/EditTag`, { ...data });

  if (response.data.Response === 1) {
    var { IsDuplicate, Response, ...rest } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    if (!IsDuplicate) dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...rest } });

    return { IsDuplicate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const EditUserProfile = ({ BankAccountNo, ContentType, CurrencyId, DOB, FileName, FirstName, Gender, Height, HeightUnit, HerId, LanguageId, LastName, PersonalNumber, PreferredMeasuringSystem, ProfilePicture, UserName, Weight, WeightUnit }) => async dispatch => {
  dispatch({ type: TRYING_EDIT_USER_PROFILE });

  var data = new FormData();
  data.append('BankAccountNo', BankAccountNo);
  if (ContentType) data.append('ContentType', ContentType);
  data.append('CurrencyId', CurrencyId);
  data.append('DOB', DOB);
  if (FileName) data.append('FileName', FileName);
  data.append('FirstName', FirstName);
  data.append('Gender', Gender);
  data.append('Height', Height);
  data.append('HeightUnit', HeightUnit);
  data.append('HerId', HerId);
  data.append('LanguageId', LanguageId);
  data.append('LastName', LastName);
  data.append('PersonalNumber', PersonalNumber);
  data.append('Unit', PreferredMeasuringSystem);
  data.append('UserName', UserName);
  data.append('Weight', Weight);
  data.append('WeightUnit', WeightUnit);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/EditUserProfile`, data);

  if (response.data.Response === 1) {
    var { PresignedFormData, UserDetails, UserNameTaken } = response.data;

    if (!UserDetails.CurrencyId) UserDetails.CurrencyId = CurrencyId;
    UserDetails.LanguageId = +LanguageId;

    dispatch({ type: TRYING_EDIT_USER_PROFILE_SUCCESS, payload: UserDetails });
    return { PresignedFormData, UserDetails, UserNameTaken };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_EDIT_USER_PROFILE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const EditVideoSession = SessionData => async dispatch => {
  dispatch({ type: TRYING_EDIT_VIDEO_SESSION });

  const response = await axiosInstance.put(`${RIDGE_API}sessions/edit-session`, { ...SessionData });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_EDIT_VIDEO_SESSION_SUCCESS });
    return response.data;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_EDIT_VIDEO_SESSION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const EditWorkoutTemplateTag = ({ TagId, TagName }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });

  var data = { TagId, TagName };

  const response = await axiosInstance.post(`${RIDGE_API}templates/EditTag`, { ...data });

  if (response.data.Response === 1) {
    var { IsDuplicate, Response, ...rest } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    if (!IsDuplicate) dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...rest } });

    return { IsDuplicate };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ForgotPasswordSendCode = ({ Email }) => async dispatch => {
  dispatch({ type: TRYING_FORGOT_PASSWORD_SEND_CODE });

  var data = { Email };

  const response = await axios.post(`${RIDGE_API}ptadmin/ForgotPasswordSendCode`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_FORGOT_PASSWORD_SEND_CODE_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_FORGOT_PASSWORD_SEND_CODE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ForgotPasswordUpdatePassword = ({ Email, NewPassword, OTP }) => async dispatch => {
  dispatch({ type: TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD });

  var data = { Email, EmailOTP: OTP, NewPassword };

  const response = await axios.post(`${RIDGE_API}ptadmin/ForgotPasswordUpdatePassword`, { ...data });

  if (response.data.Response === 1) {
    var { WrongOTP } = response.data;

    dispatch({ type: TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS });
    return { WrongOTP };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const FreezeWorkoutForPrint = ({ FromTemplate, IsFrozen, IsTemporary, PDFLogo, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_FREEZE_WORKOUT_FOR_PRINT });


  const data = { FromTemplate, IsFrozen, IsTemporary, WorkoutId };
  if (PDFLogo) data.PDFLogo = JSON.stringify(PDFLogo);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/FreezeWorkoutForPrint`, { ...data });

  if (response.data.Response === 1) {
    var { PresignedFormData_Image } = response.data;

    dispatch({ type: TRYING_FREEZE_WORKOUT_FOR_PRINT_SUCCESS });

    return { PresignedFormData_Image };
  } else {
    dispatch({ type: TRYING_FREEZE_WORKOUT_FOR_PRINT_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });

    return {};
  }
}

export const GenerateWebInvoiceUrl = ({ InvoiceId }) => async dispatch => {
  dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF });

  var data = { InvoiceId };

  const response = await axiosInstance.post(`${RIDGE_API}invoices/GetWebInvoiceUrl`, { ...data });

  if (response.data.Response === 1) {
    var { InvoiceUrl } = response.data;

    dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS });

    return { InvoiceUrl };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_DOWNLOAD_CONTRACT_PDF_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const GenerateWorkoutTemplatePDF = ({ DownloadName, EmailBody, ExerciseNotes, HasExerciseInstructions, HasExercisePicture, HasMusclesIllustration, HowToExport, LogoImageUrl, MadeBy, MadeFor, MainTitle, NumEmptySpaces, SecondaryTitle, SendToEmails, TrainerNote, WorkoutTemplateId }) => async dispatch => {
  try {
    dispatch({ type: TRYING_GENERATE_WORKOUT_TEMPLATE_PDF });

    var ExtraHeaderConfig = { responseType: 'arraybuffer' };

    const data = {
        EmailBody,
        ExerciseNotes,
        HasExerciseInstructions,
        HasExercisePicture,
        HasMusclesIllustration,
        HowToExport,
        LogoImageUrl,
        MadeBy,
        MadeFor,
        MainTitle,
        NumEmptySpaces,
        SecondaryTitle,
        SendToEmails,
        TrainerNote,
        WorkoutTemplateId,
        "type": "buffer"
    };

    const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GenerateWorkoutTemplatePDF`, { ...data }, ExtraHeaderConfig);

    if (HowToExport === 'download' || HowToExport === 'both') {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${DownloadName}.pdf`);
      document.body.appendChild(link);
      link.click();
    }

    dispatch({ type: TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_SUCCESS });

  } catch (error) {
    console.log('error: ', error);
  }
}

export const GetAdditionalActivityDetails = ({ ForWorkoutTemplates }) => async dispatch => {
  dispatch({ type: TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addadditionalactivitydetails`, {});

  if (response.data.Response === 1) {
    var { AdditionalActivities, Intensities } = response.data;

    dispatch({ type: TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS, payload: { AdditionalActivities, Intensities } });
    if (ForWorkoutTemplates) dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS });

    return { AdditionalActivities, Intensities };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetAddEditGroupSettings = ({ EditingType, GroupId, GroupType }) => async dispatch => {
  dispatch({ type: TRYING_GET_ADD_EDIT_GROUP_SETTINGS });

  var data = { EditingType, GroupId, GroupType };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/addeditsettings`, { ...data });

  if (response.data.Response === 1) {
    var { ExistingMembers, NewMembers } = response.data;

    dispatch({ type: TRYING_GET_ADD_EDIT_GROUP_SETTINGS_SUCCESS, payload: { ExistingMembers, NewMembers } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ADD_EDIT_GROUP_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetAddMeasurementDetails = ({ ClientId, MeasurementType }) => async dispatch => {
  dispatch({ type: TRYING_GET_ADD_MEASUREMENT_DETAILS });

  var data = { UserId: ClientId, MeasurementType };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetAddMeasurementDetails`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails } = response.data;

    dispatch({ type: TRYING_GET_ADD_MEASUREMENT_DETAILS_SUCCESS });

    return { AddMeasurementDetails };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ADD_MEASUREMENT_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetAllTrainerGroupsForNewClient = () => async dispatch => {
  dispatch({ type: TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetAllTrainerGroupsForNewClient`, null);

  if (response.data.Response === 1) {
    var { AllTrainerGroups } = response.data;

    dispatch({ type: TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_SUCCESS });
    return { AllTrainerGroups };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetAllTagsForAddingRemoving_Exercises = ({ ActionType, ExerciseIds }) => async dispatch => {
  // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });
  
  var data = { ActionType, ExerciseIds: ExerciseIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}exercises/GetAllTagsForAddingRemoving`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return response.data.Tags;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetAllTagsForAddingRemoving_Templates = ({ ActionType, TemplateIds }) => async dispatch => {
  // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });
  
  var data = { ActionType, TemplateIds: TemplateIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}templates/GetAllTagsForAddingRemoving`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return response.data.Tags;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetAllTagsForAddingRemoving_TrainingPrograms = ({ ActionType, TrainingProgramIds }) => async dispatch => {
  // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });
  
  var data = { ActionType, TrainingProgramIds: TrainingProgramIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}training-programs/GetAllTagsForAddingRemoving`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return response.data.Tags;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetAllVideoSessions = SessionData => async dispatch => {
  dispatch({ type: TRYING_GET_VIDEO_SESSIONS });

  const response = await axiosInstance.post(`${RIDGE_API}sessions/get-host-sessions`, { ...SessionData, UserDate: moment().format() });
  
  if (response.data.Response === 1) {
    dispatch({ type: TRYING_GET_VIDEO_SESSIONS_SUCCESS, payload: response.data });
    return response.data;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_VIDEO_SESSIONS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetBodyFatChart = ({ BodyGoalTypeId, ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { BodyGoalTypeId, ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetBodyFatChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetBodyFatTrackingHistory = ({ AddToExisting, BodyGoalTypeId, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { BodyGoalTypeId, TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetBodyFatTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetBodyWeightChart = ({ ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetBodyWeightChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetBodyWeightTrackingHistory = ({ AddToExisting, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetBodyWeightTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetBuildWorkoutPlanDetails = () => async dispatch => {
  dispatch({ type: TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/buildplan_workoutdetails`, {});

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetChooseClients = ({ EligibleTypes, GetStartedEligible, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_CHOOSE_CLIENTS });

  var data = { EligibleTypes, GetStartedEligible, PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetChooseClients`, { ...data });

  if (response.data.Response === 1) {
    var { Groups, TrainerClients } = response.data;

    dispatch({ type: TRYING_GET_CHOOSE_CLIENTS_SUCCESS });
    return { Groups, TrainerClients };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CHOOSE_CLIENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetCircumferenceChart = ({ BodyAreaId, ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { BodyAreaId, ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetCircumferenceChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetCircumferenceTrackingHistory = ({ AddToExisting, BodyAreaId, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { BodyAreaId, TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetCircumferenceTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClientDashboardEvent = ({ EventId, EventType, UserId }) => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_DASHBOARD_EVENT });

  var data = { EventId, EventType, UserId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ClientDashboardEvent`, { ...data });

  if (response.data.Response === 1) {
    var { EventDetails } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_DASHBOARD_EVENT_SUCCESS, payload: { EventDetails, EventId, EventType, UserId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_DASHBOARD_EVENT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClientDetails = ClientId => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_DETAILS, payload: +ClientId });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/client/${ClientId}`, { ClientId });

  if (response.data.Response === 1) {
    var { Client } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: { ...Client, ClientId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClientJournalEntries = ({ ClientId, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_JOURNAL_ENTRIES });

  var data = { ClientId, PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetClientJournalEntries`, data);

  if (response.data.Response === 1) {
    var { JournalEntries } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_JOURNAL_ENTRIES_SUCCESS, payload: JournalEntries });
    return { ...JournalEntries };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_JOURNAL_ENTRIES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const GetClientMeals = ({ CalendarDifference, ClientId, EndDate, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_MEALS, payload: { CalendarDifference } });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/client/meals`, { 
    ClientId, 
    CurrentDate: moment().format('YYYY-MM-DD'), 
    EndDate, 
    StartDate, 
    UserDate: moment().format() });

  if (response.data.Response === 1) {
    var { Meals, MealAccess, NeedsHeight, NeedsWeight, ShowGetStarted } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_MEALS_SUCCESS, payload: { Meals, MealAccess, NeedsHeight, NeedsWeight, ShowGetStarted } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_MEALS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClientMealSettings = ({ ClientId }) => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_MEAL_SETTINGS });

  var data = {};
  if (ClientId) data.ClientId = Number(ClientId);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/getMealSettings`, { ...data });

  if (response.data.Response === 1) {
    var { lstAllergies, lstBlockIngredients, lstDietTypes } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_MEAL_SETTINGS_SUCCESS, payload: { Allergies: lstAllergies, BlockedIngredients: lstBlockIngredients, DietTypes: lstDietTypes } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_MEAL_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClientProgress = ({ ClientId }) => async dispatch => {
  dispatch({ type: TRYING_GET_PROGRESS_DASHBOARD });

  var UserDate = moment().format();
  var ChartEndDate = moment().format('YYYY-MM-DD');
  var ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewDashboard`, { ...data });

  if (response.data.Response === 1) {
    var { BodyGoals, Endurance, Strength, SurveyMeasurements } = response.data;

    dispatch({ type: TRYING_GET_PROGRESS_DASHBOARD_SUCCESS, payload: { BodyGoals, Endurance, Strength, SurveyMeasurements } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_PROGRESS_DASHBOARD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClients = () => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENTS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/clients`, { UserDate: moment().format('YYYY-MM-DD') });

  if (response.data.Response === 1) {
    var { Clients } = response.data;

    dispatch({ type: TRYING_GET_CLIENTS_SUCCESS, payload: { Clients } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser()
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetClientWorkouts = ({ CalendarDifference, ClientId, EndDate, GetWorkoutPreviewData, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_GET_CLIENT_WORKOUTS, payload: { CalendarDifference } });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/client/workouts`, { ClientId, StartDate, EndDate, GetWorkoutPreviewData: +GetWorkoutPreviewData, UserDate: moment().format()});

  if (response.data.Response === 1) {
    var { Workouts, NeedsWeight, ShowGetStarted, WorkoutAccess } = response.data;

    dispatch({ type: TRYING_GET_CLIENT_WORKOUTS_SUCCESS, payload: { Workouts, NeedsWeight, ShowGetStarted, WorkoutAccess } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CLIENT_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetCompanies = ({ CompanyName }) => async dispatch => {
  dispatch({ type: TRYING_GET_COMPANIES });

  var data = { CompanyName };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/GetCompanies`, { ...data });

  if (response.data.Response === 1) {
    var { Companies } = response.data;

    dispatch({ type: TRYING_GET_COMPANIES_SUCCESS });

    return { Companies };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_COMPANIES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const GetCompany = ({ CompanyId }) => async dispatch => {
  dispatch({ type: TRYING_GET_COMPANY });

  var data = { CompanyId };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/GetCompany`, { ...data });

  if (response.data.Response === 1) {
    var { Company } = response.data;

    dispatch({ type: TRYING_GET_COMPANY_SUCCESS });

    return { Company };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_COMPANY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const GetCreateContractDetails = () => async dispatch => {
  dispatch({ type: TRYING_GET_CREATE_CONTRACT_DETAILS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetCreateContractDetails`, {});

  if (response.data.Response === 1) {
    var { AccuracyStatement, HealthDeclarations, Organizations, Products, TermsConditions } = response.data;

    dispatch({ type: TRYING_GET_CREATE_CONTRACT_DETAILS_SUCCESS, payload: { AccuracyStatement, HealthDeclarations, Organizations, Products, TermsConditions } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CREATE_CONTRACT_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const GetDishDetail = ({ ClientId, DishId, uId }) => async dispatch => {
  dispatch({ type: TRYING_GET_DISH_DETAIL });

  var data = { DetailType: '' };
  if (ClientId) data.UserId = Number(ClientId);
  if (DishId) data.DishId = Number(DishId);
  if (uId) data.uId = Number(uId);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/getDishDetail`, { DishDetailData: data });

  if (response.data.Response === 1) {
    var { DishDetail } = response.data;

    if (DishDetail.clientDetails.length) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: DishDetail.clientDetails[0] });
    dispatch({ type: TRYING_GET_DISH_DETAIL_SUCCESS, payload: DishDetail });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_DISH_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetDishFilterSettings = () => async dispatch => {
  dispatch({ type: TRYING_GET_DISH_FILTER_SETTINGS });

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/getUserDishFilterSettings`, { ...data });

  if (response.data.Response === 1) {
    var { DishCategories, DishTypes, MealTypes, PrivateDishCategories } = response.data;

    dispatch({ type: TRYING_GET_DISH_FILTER_SETTINGS_SUCCESS, payload: { DishCategories, DishTypes, MealTypes, PrivateDishCategories } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_DISH_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetEnduranceDetail = ({ ClientId, EnduranceTemplateId }) => async dispatch => {
  dispatch({ type: TRYING_GET_ENDURANCE_DETAIL });

  var UserDate = moment().format();
  var ChartEndDate = moment().format('YYYY-MM-DD');
  var ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { AllTimeChartDates: 1, ChartEndDate, ChartStartDate, EnduranceTemplateId, UserDate };
  if (ClientId) data.UserId = +ClientId;

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetEnduranceDetail`, { ...data });

  if (response.data.Response === 1) {
    var { ClientDetails, Endurance } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_GET_ENDURANCE_DETAIL_SUCCESS, payload: Endurance });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ENDURANCE_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetEnduranceTrackingHistory = ({ AddToExisting, ClientId, EnduranceTemplateId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_ENDURANCE_TRACKING_HISTORY });

  var data = { ClientId, EnduranceTemplateId, TrackingPageNo, TrackingPageSize };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetEnduranceTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_ENDURANCE_TRACKING_HISTORY_SUCCESS, payload: { ...TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ENDURANCE_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetExerciseDetail = ({ ClientId, ExerciseId }) => async dispatch => {
  dispatch({ type: TRYING_GET_EXERCISE_DETAIL });

  var UserDate = moment().format();
  var ChartEndDate = moment().format('YYYY-MM-DD');
  var ChartStartDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD');

  var data = { AllTimeChartDates: 1, ChartEndDate, ChartStartDate, ExerciseId, UserDate };
  if (ClientId) data.ClientId = Number(ClientId);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/exerciseDetail`, { ...data });

  if (response.data.Response === 1) {
    var { ClientDetails, Exercise } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_GET_EXERCISE_DETAIL_SUCCESS, payload: Exercise });
    return Exercise
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_EXERCISE_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const GetExerciseFilterSettings = (NewUrl = false) => async dispatch => {
  dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });

  let response;

  if (!NewUrl) response = await axiosInstance.post(`${RIDGE_API}ptadmin/getUserExerciseFilterSettings`, null);
  else response = await axiosInstance.post(`${RIDGE_API}exercises/GetExerciseFilterSettings`, null);

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetExerciseTrackingHistory = ({ AddToExisting, ClientId, ExerciseId, ExerciseType, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_EXERCISE_TRACKING_HISTORY });

  var data = { ClientId, ExerciseId, ExerciseType, TrackingPageNo, TrackingPageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetExerciseTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_EXERCISE_TRACKING_HISTORY_SUCCESS, payload: { ...TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_EXERCISE_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetGroupDetails = GroupId => async dispatch => {
  dispatch({ type: TRYING_GET_GROUP_DETAILS, payload: +GroupId });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/${GroupId}`, {});

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_GROUP_DETAILS_SUCCESS, payload: { GroupDetails: response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_GROUP_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetGroupMemberWorkoutCompletionStatus = ({ AddToExisting, GroupWorkoutId, PageNo, PageSize, SortBy, SortByDirection, UserSearch }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS });

  var data = { GroupWorkoutId, PageNo, PageSize, SortBy, SortByDirection, UserSearch };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/workouts/${GroupWorkoutId}/memberworkoutstatus`, { ...data });

  if (response.data.Response === 1) {
    var { GroupMembers, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_SUCCESS, payload: { AddToExisting, GroupMembers, IsMoreResults, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const GetGroupWorkoutDetail = ({ GroupWorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_GET_GROUP_WORKOUT_DETAIL });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/workouts/${GroupWorkoutId}`, null);

  if (response.data.Response === 1) {
    var { AdditionalActivityDetails, ClientNotes, GroupDetails, GroupMembers, WorkoutDetails, WorkoutExercises, WorkoutLaps } = response.data;

    dispatch({ type: TRYING_GET_GROUP_WORKOUT_DETAIL_SUCCESS, payload: { AdditionalActivityDetails, ClientNotes, GroupDetails, GroupMembers, WorkoutDetails, WorkoutExercises, WorkoutLaps } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_GROUP_WORKOUT_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetGroupWorkouts = ({ EndDate, GroupId, StartDate, WeeklyDifference }) => async dispatch => {
  dispatch({ type: TRYING_GET_GROUP_WORKOUTS, payload: { EndDate, StartDate, WeeklyDifference } });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/workouts`, { EndDate, GroupId, StartDate });

  if (response.data.Response === 1) {
    var { Workouts } = response.data;

    dispatch({ type: TRYING_GET_GROUP_WORKOUTS_SUCCESS, payload: { Workouts } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_GROUP_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetMealDishPreview = ({ uId }) => async dispatch => {
  dispatch({ type: TRYING_GET_MEAL_DISH_PREVIEW });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetMealDishPreview`, { MealUId: uId });

  if (response.data.Response === 1) {
    var { DishPreview } = response.data;

    dispatch({ type: TRYING_GET_MEAL_DISH_PREVIEW_SUCCESS });
    return { DishPreview };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_MEAL_DISH_PREVIEW_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const GetMealTemplateDetail = ({ TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_GET_MEAL_TEMPLATE_DETAIL });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/template_meals/${TemplateId}`, {});

  if (response.data.Response === 1) {
    var { TemplateId: NewTemplateId, TemplateDetails } = response.data;

    dispatch({ type: TRYING_GET_MEAL_TEMPLATE_DETAIL_SUCCESS, payload: { TemplateId: NewTemplateId, TemplateDetails } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_MEAL_TEMPLATE_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetMuscleMassChart = ({ ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetMuscleMassChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetMuscleMassTrackingHistory = ({ AddToExisting, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetMuscleMassTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetNutrition = ({ ClientId, DayDate, DishId, FoodId, FromDate, ToDate, uId, UserId }) => async dispatch => {
  dispatch({ type: TRYING_GET_NUTRITION });

  var data = {};
  if (ClientId) data.UserId = +ClientId;
  if (DayDate) data.DayDate = DayDate;
  if (DishId) data.DishId = +DishId;
  if (FoodId) data.FoodId = +FoodId;
  if (FromDate) data.FromDate = FromDate;
  if (ToDate) data.ToDate = ToDate;
  if (uId) data.uId = +uId;
  if (UserId) data.UserId = +UserId;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetNutrition`, { NutritionData: data });

  if (response.data.Response === 1) {
    var { NutritionData } = response.data;

    dispatch({ type: TRYING_GET_NUTRITION_SUCCESS, payload: NutritionData });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_NUTRITION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetOrganizationIncomeReport = ({ StartDate, EndDate }) => async dispatch => {
  dispatch({ type: TRYING_GET_ORGANIZATION_INCOME_REPORT });

  var data = { StartDate, EndDate };

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetIncomeReport`, { ...data });

  if (response.data.Response === 1) {
    var { OrgName, Regions, Totals } = response.data;

    dispatch({ type: TRYING_GET_ORGANIZATION_INCOME_REPORT_SUCCESS, payload: { OrgName, Regions, Totals } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ORGANIZATION_INCOME_REPORT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const GetOrganizationRegionsAndBranches = () => async dispatch => {
  dispatch({ type: TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES });

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetOrganizationRegionsAndBranches`, { ...data });

  if (response.data.Response === 1) {
    var { Regions } = response.data;

    dispatch({ type: TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_SUCCESS, payload: Regions });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const GetPainChart = ({ ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetPainChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetPainTrackingHistory = ({ AddToExisting, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetPainTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetPreSignedS3Url = ({ ContentType, InternalContentId, InternalContentType, IsMuted, FileName }) => async dispatch => {
  dispatch({ type: TRYING_GET_PRESIGNED_URL });

  var data = { ContentType, InternalContentId, InternalContentType, IsMuted, FileName };

  const response = await axiosInstance.post(`${RIDGE_API}upload-content/GetPreSignedUrl`, data);

  if (response.data.Response === 1) {
    var { PresignedFormData } = response.data;

    dispatch({ type: TRYING_GET_PRESIGNED_URL_SUCCESS });

    return { PresignedFormData };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_PRESIGNED_URL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetRPEChart = ({ ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetRPEChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetRPETrackingHistory = ({ AddToExisting, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetRPETrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetSingleVideoSessionDetails = SessionData => async dispatch => {
  dispatch({ type: TRYING_GET_SINGLE_VIDEO_SESSION });

  const response = await axiosInstance.post(`${RIDGE_API}sessions/get-daily-session-details`, { ...SessionData, UserDate: moment().format()  });
  if (response.data.Response === 1) {
    dispatch({ type: TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS, payload: response.data.Session });
    return response.data;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_SINGLE_VIDEO_SESSION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetSingleGeneratedWorkoutDetails = ({ ClientId }) => async dispatch => {
  dispatch({ type: TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS });

  var data = { ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetSingleGeneratedWorkoutDetails`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_SUCCESS, payload: response.data });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetSleepChart = ({ ChartEndDate, ChartStartDate, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetSleepChart`, { ...data });

  if (response.data.Response === 1) {
    var { Chart } = response.data;

    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS, payload: { Chart } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetSleepTrackingHistory = ({ AddToExisting, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY });

  var data = { TrackingPageNo, TrackingPageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetSleepTrackingHistory`, { ...data });

  if (response.data.Response === 1) {
    var { TrackingHistory } = response.data;

    dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS, payload: { TrackingHistory, AddToExisting } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetTrainerClients = ({ GetStartedEligible, GroupId }) => async dispatch => {
  dispatch({ type: TRYING_GET_TRAINER_CLIENTS });

  var data = {};
  if (GetStartedEligible) data.GetStartedEligible = GetStartedEligible;
  if (GroupId) data.GroupId = GroupId;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/trainerclientsandgroups`, { ...data });

  if (response.data.Response === 1) {
    var { Clients, Groups } = response.data;

    dispatch({ type: TRYING_GET_TRAINER_CLIENTS_SUCCESS, payload: { Clients, Groups } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_TRAINER_CLIENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetTrainerContracts = ({ CoachId }) => async dispatch => {
  dispatch({ type: TRYING_GET_TRAINER_CONTRACTS });

  var data = { CoachId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/CheckTrainerContracts`, { ...data });

  if (response.data.Response === 1) {
    var { TrainerContracts } = response.data;

    dispatch({ type: TRYING_GET_TRAINER_CONTRACTS_SUCCESS });
    return { TrainerContracts };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_TRAINER_CONTRACTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetTrainingProgramDetail = ({ TrainingProgramId }) => async dispatch => {
  dispatch({ type: TRYING_GET_TRAINING_PROGRAM_DETAIL });

  var data = {};
  if (TrainingProgramId) data.TrainingProgramId = +TrainingProgramId;
  const response = await axiosInstance.post(`${RIDGE_API}training-programs/GetTrainingProgramDetail`, { ...data });

  if (response.data.Response === 1) {
    var { Injuries, Tags, TrainingFocus, TrainingProgram } = response.data;

    dispatch({ type: TRYING_GET_TRAINING_PROGRAM_DETAIL_SUCCESS, payload: { Injuries, Tags, TrainingFocus, TrainingProgram } });

    return { TrainingProgram };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_TRAINING_PROGRAM_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const GetTrainingProgramFilterSettings = () => async dispatch => {
  dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS });

  let response = await axiosInstance.post(`${RIDGE_API}training-programs/GetTrainingProgramFilterSettings`, null);

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetWorkoutDetail = ({ Rebuild, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUT_DETAIL });

  Rebuild = 0;
  var data = { Rebuild };
  // data.AllEnduranceTypes = 1;
  // data.AllEnduranceTypesWorkoutType = "Real_Time";
  // data.AllExerciseTypes = 1;
  // data.AllExerciseTypesWorkoutType = "Test";

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/workout/${WorkoutId}`, { ...data });

  if (response.data.Response === 1) {
    var { AdditionalActivityDetails, ClientDetails, WorkoutDetails, WorkoutExercises, WorkoutLaps, VideoSessionInfo } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    if (VideoSessionInfo) dispatch({ type: TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS, payload: VideoSessionInfo });
    dispatch({ type: TRYING_GET_WORKOUT_DETAIL_SUCCESS, payload: { AdditionalActivityDetails, WorkoutDetails, WorkoutExercises, WorkoutLaps, VideoSessionInfo } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUT_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetWorkoutNotes = ({ WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUT_NOTES });

  var data = { WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetWorkoutNotes`, data);

  if (response.data.Response === 1) {
    var { WorkoutNotes: { ClientDocuments, TrainerDocuments } } = response.data;

    dispatch({ type: TRYING_GET_WORKOUT_NOTES_SUCCESS });

    return { ClientDocuments, TrainerDocuments };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUT_NOTES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const GetWorkoutNotesByDateRange = ({ ClientId, EndDate, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE });

  var data = { ClientId, EndDate, StartDate };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetWorkoutNotesByDateRange`, { ...data });

  if (response.data.Response === 1) {
    var { ClientDetails, WorkoutNotes } = response.data;

    dispatch({ type: TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_SUCCESS, payload: null });

    return { ClientDetails, WorkoutNotes };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const GetWorkoutPreview = ({ WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUT_PREVIEW });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/GetWorkoutPreview`, { WorkoutId });

  if (response.data.Response === 1) {
    var { WorkoutPreview } = response.data;

    dispatch({ type: TRYING_GET_WORKOUT_PREVIEW_SUCCESS });
    return { WorkoutPreview };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUT_PREVIEW_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const GetWorkoutTemplateDetail = ({ TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_DETAIL });

  var data = {};
  if (TemplateId) data.TemplateId = +TemplateId;
  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/template/GetWorkoutTemplateDetail`, { ...data });

  if (response.data.Response === 1) {
    var { AdditionalActivityDetails, Injuries, RepRange, Tags, TemplateDetails, TrainingFocus, WorkoutExercises, WorkoutLaps } = response.data;

    dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_DETAIL_SUCCESS, payload: { AdditionalActivityDetails, Injuries, RepRange, Tags, TemplateId, TemplateDetails, TrainingFocus, WorkoutExercises, WorkoutLaps } });

    var { AdditionalActivities, Intensities } = AdditionalActivityDetails;
    dispatch({ type: TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS, payload: { AdditionalActivities, Intensities } });

    return { AdditionalActivityDetails, TemplateId, TemplateDetails, WorkoutExercises, WorkoutLaps };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const GetWorkoutTemplateFilters = () => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_FILTERS });

  const response = await axiosInstance.post(`${RIDGE_API}templates/GetWorkoutTemplateFilters`, null);

  if (response.data.Response === 1) {
    var { WorkoutTemplateFilters } = response.data;

    dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_FILTERS_SUCCESS, payload: { WorkoutTemplateFilters } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUT_TEMPLATE_FILTERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const GetWorkoutTemplateFilterSettings = (NewUrl = false) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS });

  let response;

  if (!NewUrl) response = await axiosInstance.post(`${RIDGE_API}templates/GetWorkoutTemplateFilters`, null);
  else response = await axiosInstance.post(`${RIDGE_API}templates/GetWorkoutTemplateFilterSettings`, null);

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const HandleVisibilityChange = () => async dispatch => {
  // console.log(`%cHandleVisibilityChange: ${document.visibilityState} at ${moment().format('YYYY-MM-DD HH:mm:ss')}`, 'color: #1A97FF; font-size: 20px;');
  dispatch({ type: HANDLE_VISIBILITY_CHANGE });
}

export const InitWS = () => async dispatch => {
  dispatch({ type: INIT_WS });
}

export const InsertEditAdditionalActivity = ({ ActivityId, ActivityName, AssignedDate, ClientId, Completed, Duration, HasDateTimes, HasLocation, IntensityId, Location, WorkoutId }) => async dispatch => {
  // Used on Workout Detail for Additional Activity Workout
  dispatch({ type: TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY });

  var data = { ActivityId, AssignedDate, ClientId, Completed, Duration, HasDateTimes, HasLocation, IntensityId, Location, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/AddAdditionalActivity`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_SUCCESS, payload: { ActivityName } });

    return { Response: response.data.Response };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return { Response: response.data.Response };
  }
}

export const InsertEditCompany = ({ CompanyId, CompanyName, HasWhitelabeling }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_COMPANY });

  var data = { CompanyId, CompanyName, HasWhitelabeling };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/InsertEditCompany`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERTEDIT_COMPANY_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_COMPANY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditEnduranceGoal = ({ ClientId, EnduranceTemplateId, Seconds, Speed }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_ENDURANCE_GOAL });

  var UserDate = moment().format();

  var data = { EnduranceTemplateId, Seconds, Speed, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/InsertEditEnduranceGoal`, { ...data });

  if (response.data.Response === 1) {
    var { Endurance } = response.data;

    dispatch({ type: TRYING_GET_ENDURANCE_DETAIL_SUCCESS, payload: Endurance });
    dispatch({ type: TRYING_INSERTEDIT_ENDURANCE_GOAL_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_ENDURANCE_GOAL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const InsertEditExerciseFilter = ({ FilterId, FilterName, IsHidden, NewTagIds, NewTags, RemoveTagIds, StartOpen, TagIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });

  var data = { FilterName, IsHidden, NewTagIds, NewTags, RemoveTagIds, StartOpen, TagIds };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/filters${FilterId && `/${FilterId}`}`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertEditExerciseGoal = ({ ClientId, Distance, ExerciseId, ExerciseType, Reps, Seconds, Weight }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_EXERCISE_GOAL });

  var UserDate = moment().format();

  var data = { Distance, ExerciseId, ExerciseType, Reps, Seconds, UserDate, UserId: ClientId, Weight };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/InsertEditExerciseGoal`, { ...data });

  if (response.data.Response === 1) {
    var { Exercise } = response.data;

    dispatch({ type: TRYING_GET_EXERCISE_DETAIL_SUCCESS, payload: Exercise });
    dispatch({ type: TRYING_INSERTEDIT_EXERCISE_GOAL_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_EXERCISE_GOAL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const InsertEditExpense = ({ Amount, CurrencyId, CurrencyName, DeleteMedia, Description, ExpenseCategoryId, ExpenseDate, ExpenseId, ExpenseIndex, ExpenseMedia, Year }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_EXPENSE });

  var data = new FormData();
  data.append('Amount', Amount);
  data.append('CurrencyId', CurrencyId);
  data.append('CurrencyName', CurrencyName);
  if (DeleteMedia) data.append('DeleteMedia', DeleteMedia.join(','));
  data.append('Description', Description);
  data.append('ExpenseCategoryId', ExpenseCategoryId);
  data.append('ExpenseDate', ExpenseDate);
  if (ExpenseId) data.append('ExpenseId', ExpenseId);
  if (ExpenseMedia.length > 0) data.append('ExpenseMedia', ExpenseMedia);
  data.append('Year', Year);

  const response = await axiosInstance.post(`${RIDGE_API}expenses/InsertEditExpense`, data);
  // const response = await axiosInstance.post(`http://ridges-MacBook-Pro.local:3005/v1/expenses/InsertEditExpense`, data);

  if (response.data.Response === 1) {
    var { Expense, PreSignedUrls, Stats } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_EXPENSE_SUCCESS, payload: { Expense, ExpenseIndex, Stats } });

    return { Expense, PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_EXPENSE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditFavoriteEndurance = ({ ClientId, EnduranceTemplateId, FavoriteOrder }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_FAVORITE_ENDURANCE });

  var data = { EnduranceTemplateId, FavoriteOrder, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/InsertEditFavoriteEndurance`, { ...data });

  if (response.data.Response === 1) {
    var { Endurance } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_FAVORITE_ENDURANCE_SUCCESS, payload: { Endurance } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_FAVORITE_ENDURANCE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const InsertEditFavoriteExercise = ({ ClientId, ExerciseId, FavoriteOrder }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_FAVORITE_EXERCISE });

  var data = { ExerciseId, FavoriteOrder, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/InsertEditFavoriteExercise`, { ...data });

  if (response.data.Response === 1) {
    var { Strength } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_FAVORITE_EXERCISE_SUCCESS, payload: { Strength } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_FAVORITE_EXERCISE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const InsertEditMealTemplateDetail = ({ CopiedTemplateId, TemplateDays, TemplateDishes, TemplateId, TemplateName }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL });

  var data = { CopiedTemplateId, DataType: 'dynamic', TemplateDays, TemplateDishes, TemplateName };
  data.TemplateId = Number(TemplateId) ? Number(TemplateId) : null;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/insertedittemplate_meals`, { ...data });

  if (response.data.Response === 1) {
    var { TemplateId: NewTemplateId } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_SUCCESS, payload: { TemplateId: NewTemplateId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const InsertEditOrganizationBranch = ({ BankAccountNo, BranchName, Month, OrganizationBranchId, OrganizationBranchIndex = null, Year }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_ORGANIZATION_BRANCH });

  var data = { BankAccountNo, BranchName, OrganizationBranchId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/InsertEditOrganizationBranch`, { ...data });

  if (response.data.Response === 1) {
    var { NameTaken } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS, payload: { BankAccountNo, BranchName, Month, NameTaken, OrganizationBranchIndex, Year } });
    return { NameTaken };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditOrganizationBranch2 = ({ BankAccountNo, BranchId, BranchName, BranchIndex, RegionId, RegionIndex }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_ORGANIZATION_BRANCH });

  var data = { BankAccountNo, BranchId, BranchName, RegionId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/InsertEditOrganizationBranch`, { ...data });

  if (response.data.Response === 1) {
    var { BranchId: NewBranchId } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS, payload: { BankAccountNo, BranchId, BranchName, BranchIndex, NewBranchId, RegionId, RegionIndex } });

    return { NewBranchId };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const InsertEditOrganizationRegion = ({ RegionId, RegionIndex, RegionName }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_ORGANIZATION_REGION });

  var data = { RegionId, RegionName };

  const response = await axiosInstance.post(`${RIDGE_API}payments/InsertEditOrganizationRegion`, { ...data });

  if (response.data.Response === 1) {
    var { RegionId: NewRegionId } = response.data;

    dispatch({ type: TRYING_INSERT_EDIT_ORGANIZATION_REGION_SUCCESS, payload: { NewRegionId, RegionId, RegionIndex, RegionName } });

    return { NewRegionId };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_ORGANIZATION_REGION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const InsertEditPrivateDish = ({ ActiveTime, ContentType, DishId, DishName, ExistingDishId, FileName, Ingredients, Instructions, IsImageNew, MealTypes, PrepareTime, PrivateDishCategoryId, Rating }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_PRIVATE_DISH });

  var dataMealTypes = JSON.parse(JSON.stringify(MealTypes.map(MealType => ({ MealTypeId: MealType }))));
  var dataIngredients = JSON.parse(JSON.stringify(Ingredients.map(({ FoodId, Quantity, UnitId }) => ({ FoodId, Quantity, UnitId }))));
  var dataInstructions = JSON.parse(JSON.stringify(Instructions.map(InstructionName => ({ InstructionName }))));

  var data = { ActiveTime: ActiveTime || 0, dataIngredients, dataInstructions, dataMealTypes, DishName, ExistingDishId, IsImageNew, IsPortionSized: 1, IsPublic: 0, MealDates: '', PrepareTime: PrepareTime || 0, PrivateDishCategoryId: PrivateDishCategoryId || 0, Rating, Time_Passive: 0, DishId };
  if (ContentType) data.ContentType = ContentType;
  if (FileName) data.FileName = FileName;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/createPrivateDish`, { ...data });

  if (response.data.Response === 1) {
    var { PresignedFormData } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_PRIVATE_DISH_SUCCESS });
    return { PresignedFormData };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_PRIVATE_DISH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditPrivateExercise = ({ CopiedExerciseId, CopyImage, CopyVideo, Equipment, ExerciseId, ExerciseImage, ExerciseType, ExerciseVideo, Joints, Movement, Plane, PrimaryMuscles, SecondaryMuscles, StartPosition, Tags, TertiaryMuscles, TrainingFocus, TrainingForm, Translations }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_PRIVATE_EXERCISE });

  var data = new FormData();
  if (CopiedExerciseId) {
    data.append('CopiedExerciseId', CopiedExerciseId);
    data.append('CopyImage', CopyImage);
    data.append('CopyVideo', CopyVideo);
  }
  data.append('Equipment', Equipment);
  if (ExerciseId) data.append('ExerciseId', ExerciseId);
  data.append('ExerciseType', ExerciseType);
  data.append('ExerciseImage', ExerciseImage ? JSON.stringify(ExerciseImage) : null);
  data.append('ExerciseVideo', ExerciseVideo ? JSON.stringify(ExerciseVideo) : null);
  data.append('Joints', Joints);
  data.append('Movement', Movement);
  data.append('Plane', Plane);
  data.append('PrimaryMuscles', PrimaryMuscles);
  data.append('SecondaryMuscles', SecondaryMuscles);
  data.append('StartPosition', StartPosition);
  data.append('TagIds', Tags);
  data.append('TertiaryMuscles', TertiaryMuscles);
  data.append('TrainingFocus', TrainingFocus);
  data.append('TrainingForm', TrainingForm);
  data.append('Translations', JSON.stringify(Translations));

  // const response = await axiosInstance.post(`${RIDGE_API}ptadmin/inserteditcustomexercise`, data);
  const response = await axiosInstance.post(`${RIDGE_API}exercises/inserteditcustomexercise`, data);

  if (response.data.Response === 1) {
    var { PresignedFormData_Image, PresignedFormData_Video } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_PRIVATE_EXERCISE_SUCCESS });
    return { PresignedFormData_Image, PresignedFormData_Video };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_PRIVATE_EXERCISE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const InsertEditPrivateFood = ({ FoodId, FoodMeasurements, FoodName, Nutrients }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_PRIVATE_FOOD });

  var data = { dataFoodUnits: FoodMeasurements, dataNutrients: Nutrients, FoodName, IsPublic: 0 };
  if (!!+FoodId) data.FoodId = FoodId;
  else data.FoodId = 0;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/InsertEditPrivateFood`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERTEDIT_PRIVATE_FOOD_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_PRIVATE_FOOD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertEditProduct = ({ CurrencyId, Price, ProductDescription, ProductId, ProductName }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_PRODUCT });

  var data = { CurrencyId, Price, ProductDescription, ProductId, ProductName };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/InsertEditProduct`, { ...data });

  if (response.data.Response === 1) {
    var NewProductId = response.data.ProductId;

    dispatch({ type: TRYING_INSERTEDIT_PRODUCT_SUCCESS });

    return { ProductId: NewProductId };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_PRODUCT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditTrainerClientMedia = ({ ClientId, DeleteMedia, NoteMedia }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA });

  var data = new FormData();
  data.append('ClientId', ClientId);
  data.append('DeleteMedia', DeleteMedia ? DeleteMedia.join(',') : '');
  if (NoteMedia.length > 0) data.append('NoteMedia', NoteMedia);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/InsertEditTrainerClientMedia`, data);

  if (response.data.Response === 1) {
    if (NoteMedia.length > 0 || DeleteMedia.length > 0) var { PreSignedUrls, TrainerClientMedia: { Documents } } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_SUCCESS });

    return { Documents, PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditTrainerGroup = ({ ClientIds, GroupId, GroupName }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_TRAINER_GROUP });

  var data = { ClientIds, GroupName };
  if (GroupId) data.GroupId = GroupId;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/InsertEditTrainerGroup`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERTEDIT_TRAINER_GROUP_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_TRAINER_GROUP_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const InsertEditTrainingProgram = ({ CopiedTrainingProgramId, Days, Injuries, NumDays, ProgramDescription, ProgramName, Tags, TrainingFocus, TrainingProgramId }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_TRAINING_PROGRAM });

  var data = { CopiedTrainingProgramId, Days, Injuries, NumDays, ProgramDescription, ProgramName, TagIds: Tags, TrainingFocus };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs${!!+TrainingProgramId ? `/${TrainingProgramId}` : ''}`, { ...data });

  if (response.data.Response === 1) {
    var { TrainingProgramId: NewTrainingProgramId } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_TRAINING_PROGRAM_SUCCESS, payload: { TrainingProgramId: NewTrainingProgramId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
        dispatch({ type: TRYING_INSERTEDIT_TRAINING_PROGRAM_ERROR, payload: response.data.ErrorMessage });
      }
    }
  }
}

export const InsertEditTrainingProgramFilter = ({ FilterId, FilterName, IsHidden, NewTagIds, NewTags, RemoveTagIds, StartOpen, TagIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });

  var data = { FilterName, IsHidden, NewTagIds, NewTags, RemoveTagIds, StartOpen, TagIds };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/filters${FilterId && `/${FilterId}`}`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertEditUserGroup = ({ GroupId, GroupImage, GroupImageObj, GroupIndex, GroupName, GroupType, OriginalImage, UserIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_USER_GROUP, payload: { GroupImage, GroupIndex, GroupName } });

  var data = new FormData();
  data.append('GroupId', GroupId || '');
  data.append('GroupImage', GroupImage ? JSON.stringify(GroupImageObj) : null);
  data.append('GroupName', GroupName || '');
  data.append('GroupType', GroupType);
  data.append('UserDate', moment().format());
  data.append('UserIds', UserIds ? UserIds.join(',') : '');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/insertedit`, data);

  if (response.data.Response === 1) {
    var { GroupMembers, Groups, PreSignedUrls } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_USER_GROUP_SUCCESS, payload: { GroupId, Groups } });
    return { GroupMembers, PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_USER_GROUP_ERROR, payload: { ErrorMessage: response.data.ErrorMessage, GroupImage, GroupIndex, OriginalImage } });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditUserGroupMembers = ({ AddToFutureWorkouts, AddUserIds, EditingType, FromDashboard, GetAllMembers, GroupId, GroupIndex, GroupName, GroupType, RemoveUserIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_USER_GROUP_MEMBERS });

  var data = { AddToFutureWorkouts, EditingType, GetAllMembers, GroupId, GroupName, GroupType, ...FromDashboard && { ReturnDataType: 'Dashboard', UserDate: moment().format() } };
  data.AddUserIds = (AddUserIds && AddUserIds.length) ? AddUserIds.join(',') : '';
  data.RemoveUserIds = (RemoveUserIds && RemoveUserIds.length) ? RemoveUserIds.join(',') : '';

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/${GroupId}/InsertEditMembers`, data);

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERTEDIT_USER_GROUP_MEMBERS_SUCCESS });

    if (GetAllMembers) {
      var { Groups, GroupMembers } = response.data;

      delete response.data.Response;
      dispatch({ type: TRYING_GET_GROUP_DETAILS_SUCCESS, payload: { FromDashboard, Groups, GroupDetails: response.data, GroupIndex } });

      return { GroupMembers };
    }

    return {};
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_USER_GROUP_MEMBERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditWorkoutNote = ({ ClientId, DeleteMedia, NoteMedia, NoteText, NoteType, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_WORKOUT_NOTE });

  var data = { ClientId, NoteText, NoteType, WorkoutId };
  data.DeleteMedia = DeleteMedia ? DeleteMedia.join(',') : '';
  if (NoteMedia) data.NoteMedia = NoteMedia;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/InsertEditWorkoutNote`, data);

  if (response.data.Response === 1) {
    if (NoteMedia.length > 0 || DeleteMedia.length > 0) var { PreSignedUrls, WorkoutNotes: { ClientDocuments, TrainerDocuments } } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_WORKOUT_NOTE_SUCCESS });

    return { ClientDocuments, PreSignedUrls, TrainerDocuments };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_WORKOUT_NOTE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditWorkoutTemplateDetail = ({ ActiveType, ActivityId, CardioLevel, CopiedTemplateId, CustomImage, CustomName, DataType, Duration, Injuries, IntensityId, Location, RepRange, StartTime, Tags, TemplateId, TemplateImage, TemplateName, TrainerNote, TrainingFocus, Units, WorkoutExercises, WorkoutLaps, WorkoutType }) => async dispatch => {
  dispatch({ type: TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL });

  var data = new FormData();
  data.append('ActiveType', ActiveType);
  data.append('ActivityId', ActivityId);
  data.append('CardioLevel', CardioLevel);
  if (CopiedTemplateId) data.append('CopiedTemplateId', CopiedTemplateId);
  data.append('CustomImage', CustomImage);
  data.append('CustomName', CustomName);
  data.append('DataType', DataType);
  data.append('Duration', Duration);
  data.append('Injuries', Injuries);
  if (IntensityId) data.append('IntensityId', IntensityId);
  data.append('Location', Location);
  data.append('RepRange', RepRange);
  data.append('StartTime', StartTime);
  data.append('TagIds', Tags);
  if (TemplateId) data.append('TemplateId', TemplateId);
  data.append('TemplateImage', TemplateImage ? JSON.stringify(TemplateImage) : null);
  data.append('TemplateName', TemplateName);
  data.append('TrainerNote', TrainerNote);
  data.append('TrainingFocus', TrainingFocus);
  data.append('Units', Units);
  if (WorkoutType === 1) data.append('WorkoutExercises', JSON.stringify(WorkoutExercises));
  if (WorkoutType === 2) data.append('WorkoutLaps', JSON.stringify(WorkoutLaps));
  data.append('WorkoutType', WorkoutType);

  const response = await axiosInstance.post(`${RIDGE_API}templates/InsertEditWorkoutTemplate`, data);

  if (response.data.Response === 1) {
    var { PreSignedUrls } = response.data;

    dispatch({ type: TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_SUCCESS });

    return { PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const InsertEditWorkoutTemplateFilter = ({ FilterId, FilterName, IsHidden, NewTagIds, NewTags, RemoveTagIds, StartOpen, TagIds }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });

  var data = { FilterName, IsHidden, NewTagIds, NewTags, RemoveTagIds, StartOpen, TagIds };

  const response = await axiosInstance.post(`${RIDGE_API}templates/filters${FilterId && `/${FilterId}`}`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertExerciseTags = ({ NewTags }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });

  var data = { NewTags };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/InsertTags`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertMealPlan_MealPlanBuilder = ({ ClientId, MealPlan, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER });

  var data = { ClientId, MealPlan, StartDate };

  const response = await axiosInstance.post(`${RIDGE_API}templates/InsertMealPlan_MealPlanBuilder`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertTrainingProgramTags = ({ NewTags }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });

  var data = { NewTags };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/InsertTags`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InsertWorkoutTemplateTags = ({ NewTags }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });

  var data = { NewTags };

  const response = await axiosInstance.post(`${RIDGE_API}templates/InsertTags`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const InviteOrganizationMembers = ({ Emails }) => async dispatch => {
  dispatch({ type: TRYING_INVITE_ORGANIZATION_MEMBERS });

  var data = { Emails };

  const response = await axiosInstance.post(`${RIDGE_API}payments/InviteOrganizationMembers`, { ...data });

  if (response.data.Response === 1) {
    var { MembersAdded } = response.data;

    dispatch({ type: TRYING_INVITE_ORGANIZATION_MEMBERS_SUCCESS });
    return { MembersAdded };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INVITE_ORGANIZATION_MEMBERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const HideErrorModal = () => async dispatch => {
  dispatch({ type: HIDE_ERROR_MODAL });
}

export const LeaveOrganization = ({ OrganizationId, OrganizationUserId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS });

  var data = { OrganizationId, OrganizationUserId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/LeaveOrganization`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS, payload: { UserDetails } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const LoginUser = ({ AppleId = '', Email, FacebookId, FirstName = '', GmailId, LastName = '', Password = '', UserPhoto = '' }) => async dispatch => {
  dispatch({ type: TRYING_LOGIN_USER });

  var data = { AppleId, Email, FacebookId, FirstName, GmailId, LastName, Password, UserPhoto };

  const response = await axios.post(`${RIDGE_API}ptadmin/loginUser`, { ...data });

  if (response.data.Response === 1) {
    // var { data: UserDetails, JwtToken } = response.data;
    var { JwtToken, MembershipRequests, SubscriptionStatus, Token, UserDetails } = response.data;

    UserDetails.MembershipRequests = MembershipRequests;
    UserDetails.SubscriptionStatus = SubscriptionStatus;

    localStorage.setItem("SarbjitToken", JwtToken);
    localStorage.setItem("accessToken", Token);
    dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });

    if (!UserDetails.OrganizationId) history.push('/clients');
    else history.push('/income');
  } else dispatch({ type: TRYING_LOGIN_USER_ERROR, payload: response.data.ErrorMessage });
}

export const LogoutUser = () => async dispatch => {
  // console.log('LogoutUser');
  LogoutUserFunc();
  dispatch({ type: LOGOUT_USER });
}

function LogoutUserFunc() {
  localStorage.clear();

  window.location = `${BETA_SITE_URL}/logout`;
}

export const MoveExercisesToTrash = ({ ExerciseIds }) => async dispatch => {
  dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });

  var data = { ExerciseIds: ExerciseIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}exercises/MoveToTrash`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const MoveGroupWorkouts = ({ AssignedDate, IsBetween, GroupWorkoutIds }) => async dispatch => {
  dispatch({ type: TRYING_MOVE_GROUP_WORKOUTS });

  var data = { AssignedDate };
  data.GroupWorkoutIds = GroupWorkoutIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/moveworkouts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_MOVE_GROUP_WORKOUTS_SUCCESS, payload: { IsBetween, GroupWorkoutIds } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_MOVE_GROUP_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
    }
  }
}

export const MoveMeals = ({ AssignedDate, IsBetween, MealIds }) => async dispatch => {
  dispatch({ type: TRYING_MOVE_MEALS });

  var data = { AssignedDate };
  data.MealIds = MealIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/MoveMeals`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_MOVE_MEALS_SUCCESS, payload: { IsBetween, MealIds } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_MOVE_MEALS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
    }
  }
}

export const MoveOrganizationBranchToNewRegion = ({ BranchId, RegionId }) => async dispatch => {
  dispatch({ type: TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION });

  var data = { BranchId, RegionId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/MoveOrganizationBranchToNewRegion`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const MoveTemplatesToTrash = ({ TemplateIds }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS });

  var data = { TemplateIds: TemplateIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}templates/MoveToTrash`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const MoveTrainingProgramsToTrash = ({ TrainingProgramIds }) => async dispatch => {
  dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS });

  var data = { TrainingProgramIds: TrainingProgramIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}training-programs/MoveToTrash`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const MoveWorkouts = ({ AssignedDate, IsBetween, WorkoutIds }) => async dispatch => {
  dispatch({ type: TRYING_MOVE_WORKOUTS });

  var data = { AssignedDate };
  data.WorkoutIds = WorkoutIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/MoveWorkouts`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_MOVE_WORKOUTS_SUCCESS, payload: { IsBetween, WorkoutIds } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_MOVE_WORKOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage }, ModalErrorNoLink: true });
    }
  }
}

export const ProcessStageSubscriber = () => async dispatch => {
  dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ProcessStageSubscriber`, null);

  if (response.data.Response === 1) {
    var { MembershipRequests, SubscriptionStatus, UserDetails } = response.data;

    UserDetails.MembershipRequests = MembershipRequests;
    UserDetails.SubscriptionStatus = SubscriptionStatus;

    dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER_SUCCESS });
    dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReactivateMembership = () => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ReactivateSubscription`, null);

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS, payload: { UserDetails } });
  } else {
    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
  }
}

export const ReactivateSubscription = () => async dispatch => {
  dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ReactivateSubscription2`, null);

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS });
    dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReactToNotification = ({ IsRead_Already, notificationId, reaction, UserId }) => async dispatch => {
  dispatch({ type: TRYING_READ_NOTIFICATION });
  var ExtraHeaderConfig = { headers: { 'x-user-id': UserId } };

  const response = await axiosInstance.post(`${CHAT_API}notification/react`, { notificationId, reaction }, ExtraHeaderConfig);

  if (response.status === 200) {
    if (!IsRead_Already) dispatch({ type: TRYING_READ_NOTIFICATION_SUCCESS });
  } else {
    dispatch({ type: TRYING_READ_NOTIFICATION_ERROR, payload: response.data.message });
    dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.message, ModalErrorNoLink: true } });
  }
}

export const ReadAllNotifications = ({ UserId }) => async dispatch => {
  dispatch({ type: TRYING_READ_ALL_NOTIFICATIONS });
  var ExtraHeaderConfig = { headers: { 'x-user-id': UserId } };

  const response = await axiosInstance.patch(`${NOTIFICATION_API}notification/mark-all-as-read`, null, ExtraHeaderConfig);

  if (response.status === 200) {
    dispatch({ type: TRYING_READ_ALL_NOTIFICATIONS_SUCCESS, payload: { ReadAll: true } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_READ_ALL_NOTIFICATIONS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReadNotification = ({ NotificationId, UserId }) => async dispatch => {
  dispatch({ type: TRYING_READ_NOTIFICATION });
  var ExtraHeaderConfig = { headers: { 'x-user-id': UserId } };

  const response = await axiosInstance.patch(`${NOTIFICATION_API}notification/${NotificationId}/mark-as-read`, null, ExtraHeaderConfig);

  if (response.status === 200) {
    dispatch({ type: TRYING_READ_NOTIFICATION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_READ_NOTIFICATION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReadNotificationFromClick = ({ NotificationId }) => {

  axiosInstance.post(`${RIDGE_API}ptadmin/ReadNotification`, { NotificationId });
}

export const RecoverExercisesFromTrash = ({ ExerciseIds }) => async dispatch => {
  dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });

  var data = { ExerciseIds: ExerciseIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}exercises/RecoverFromTrash`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const RecoverTemplatesFromTrash = ({ TemplateIds }) => async dispatch => {
  dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS });

  var data = { TemplateIds: TemplateIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}templates/RecoverFromTrash`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const RecoverTrainingProgramsFromTrash = ({ TrainingProgramIds }) => async dispatch => {
  dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS });

  var data = { TrainingProgramIds: TrainingProgramIds.join(',') };

  let response = await axiosInstance.post(`${RIDGE_API}training-programs/RecoverFromTrash`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const RegenerateMaxSpeed = ({ ClientId, EnduranceTypeId, MaxSpeed, WorkoutEnduranceId }) => async dispatch => {
  dispatch({ type: TRYING_REGENERATE_MAXSPEED });

  var data = { ClientId, EnduranceTypeId, Speed: MaxSpeed, WorkoutEnduranceId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addmax/endurance`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutLaps } = response.data;

    dispatch({ type: TRYING_REGENERATE_MAXSPEED_SUCCESS });
    return { WorkoutLaps };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_REGENERATE_MAXSPEED_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const RegenerateTestSet = ({ ClientId, Distance, ExerciseId, ExerciseType, Reps, Seconds, Units, Weight, WorkoutExerciseId, WorkoutId, WorkoutRM }) => async dispatch => {
  dispatch({ type: TRYING_REGENERATE_TESTSET });

  var data = { ClientId, Distance, ExerciseId, ExerciseType, Reps, Seconds, Units, Weight, WorkoutExerciseId, WorkoutId, WorkoutRM };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/addmax/strength`, { ...data });

  if (response.data.Response === 1) {
    var { Exercises } = response.data;

    dispatch({ type: TRYING_REGENERATE_TESTSET_SUCCESS });
    return { Exercise: Exercises[0].WorkoutExercises[0] };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_REGENERATE_TESTSET_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const RegisterUserInPaymentGateway = ({ CancelUrl, ReturnUrl }) => async dispatch => {
  dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY });

  var data = { CancelUrl, ReturnUrl };

  const response = await axiosInstance.post(`${RIDGE_API}payments/RegisterUserInPaymentGateway`, { ...data });

  if (response.data.Response === 1) {
    var { hostedPaymentPageUrl } = response.data;

    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS });

    return { hostedPaymentPageUrl };
  } else {
    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });

    return {};
  }
}

export const RemoveCategory_Exercise = ({ CategoryId }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });
  
  var data = { CategoryId };

  let response = await axiosInstance.post(`${RIDGE_API}exercises/RemoveCategory`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return;
  }
}

export const RemoveCategory_TrainingProgram = ({ CategoryId }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });
  
  var data = { CategoryId };

  let response = await axiosInstance.post(`${RIDGE_API}training-programs/RemoveCategory`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return;
  }
}

export const RemoveCategory_WorkoutTemplate = ({ CategoryId }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });
  
  var data = { CategoryId };

  let response = await axiosInstance.post(`${RIDGE_API}templates/RemoveCategory`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return;
  }
}

export const RemoveFavoriteEndurance = ({ ClientId, EnduranceTemplateId }) => async dispatch => {
  dispatch({ type: TRYING_REMOVE_FAVORITE_ENDURANCE });

  var data = { EnduranceTemplateId, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/RemoveFavoriteEndurance`, { ...data });

  if (response.data.Response === 1) {
    var { Endurance } = response.data;

    dispatch({ type: TRYING_REMOVE_FAVORITE_ENDURANCE_SUCCESS, payload: { Endurance } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_REMOVE_FAVORITE_ENDURANCE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const RemoveFavoriteExercise = ({ ClientId, ExerciseId }) => async dispatch => {
  dispatch({ type: TRYING_REMOVE_FAVORITE_EXERCISE });

  var data = { ExerciseId, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/RemoveFavoriteExercise`, { ...data });

  if (response.data.Response === 1) {
    var { Strength } = response.data;

    dispatch({ type: TRYING_REMOVE_FAVORITE_EXERCISE_SUCCESS, payload: { Strength } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_REMOVE_FAVORITE_EXERCISE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ResizeWindow = () => async dispatch => {
  dispatch({ type: RESIZE_WINDOW });
}

export const ReorderExerciseFilters = ({ Filters }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES });

  var data = { FilterIds: Filters.map(F => +F.TagFilterId).join(',') };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/ReorderFilters`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReorderExercises = ({ ClientId, ExerciseOrder, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_REORDER_EXERCISES });

  var data = { ClientId, ExerciseOrder, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ReorderExercises`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_REORDER_EXERCISES_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_REORDER_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReorderTemplateFilters = ({ Filters }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES });

  var data = { FilterIds: Filters.map(F => +F.TagFilterId).join(',') };

  const response = await axiosInstance.post(`${RIDGE_API}templates/ReorderFilters`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ReorderTrainerGroups = ({ Groups }) => async dispatch => {
  dispatch({ type: TRYING_REORDER_TRAINER_GROUPS });

  var data = { Groups };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ReorderTrainerGroups`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_REORDER_TRAINER_GROUPS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_REORDER_TRAINER_GROUPS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ReorderTrainingProgramFilters = ({ Filters }) => async dispatch => {
  dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS });

  var data = { FilterIds: Filters.map(F => +F.TagFilterId).join(',') };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/ReorderFilters`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS, payload: null });
    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const SaveDataInProps = SaveData => async dispatch => {
  dispatch({ type: SAVE_DATA_IN_PROPS, payload: SaveData });
}

export const SaveOrganizationCustomization = ({ LightMode, SignatureColor, TextColor }) => async dispatch => {
  dispatch({ type: TRYING_SAVE_ORGANIZATION_CUSTOMIZATION });

  var data = { LightMode, SignatureColor, TextColor };

  const response = await axiosInstance.post(`${RIDGE_API}payments/SaveOrganizationCustomization`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_SUCCESS, payload: { LightMode, SignatureColor, TextColor } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const SearchDishes_SAYT = ({ PageNo, PageSize, Search: DishName }) => async dispatch => {

  var data = { PageNo, PageSize };
  if (DishName) data.DishName = DishName;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/searchAddDish`, { SearchData: data });

  if (response.data.Response === 1) {
    var { Dishes } = response.data;

    return { SearchResults: Dishes };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchDishes = ({ AddToExisting, ClientId, DishCategoryIds, DishTypeIds, DishName, IsBlockedAllergiesDietTypes, IsBlockedIngredients, MaxCookingTime, MealTypeIds, MultiRatings, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_DISHES });

  var data = { PageNo, PageSize };
  if (ClientId) data.ClientId = ClientId;
  if (DishName) data.DishName = DishName;
  if (DishTypeIds && DishTypeIds.length) data.DishTypeIds = DishTypeIds.join(',');
  if (DishCategoryIds && DishCategoryIds.length) data.DishesShown = DishCategoryIds.join(',');
  if (MaxCookingTime) data.MaxCookingTime = MaxCookingTime;
  if (MealTypeIds && MealTypeIds.length) data.MealTypeIds = MealTypeIds.join(',');
  if (MultiRatings && MultiRatings.length) data.MultiRatings = MultiRatings.join(',');
  data.IsBlockedAllergiesDietTypes = IsBlockedAllergiesDietTypes ? 1 : 0;
  data.IsBlockedIngredients = IsBlockedIngredients ? 1 : 0;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/searchAddDish`, { SearchData: data });

  if (response.data.Response === 1) {
    var { AddDishes_IsMoreResults, Dishes, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_DISHES_SUCCESS, payload: { AddDishes_IsMoreResults, AddToExisting, Dishes, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_DISHES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const SearchExercises_SAYT = ({ LanguageId, Search: ExerciseName }) => async dispatch => {

  var data = { LanguageId };
  if (ExerciseName) data.ExerciseName = ExerciseName;

  const response = await axiosInstance.post(`${RIDGE_API}exercises/SearchExercises_SAYT`, { ...data });

  if (response.data.Response === 1) {
    var { Exercises } = response.data;

    return { SearchResults: Exercises };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const SearchExercises = ({ AddToExisting, AvoidJoints, AvoidMuscles, ClientId, Equipment, ExcludeExercises, ExerciseName, ExercisesShown, ExperienceLevels, HasDeleted, HideDeleted, IsBlockedInjuries, LanguageId, Movement, MuscleGroupId, Owners, PageNo, PageSize, Plane, SharedType, StartPosition, TagIds, TargetJoints, TargetMuscles, TemporaryDeletedExercises, TrainingFocus, TrainingForm, WorkoutId }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_EXERCISES });

  var data = { HasDeleted, HideDeleted, LanguageId, PageNo, PageSize, SharedType };
  if (AvoidJoints && AvoidJoints.length) data.AvoidJoints = AvoidJoints.join(',');
  if (AvoidMuscles && AvoidMuscles.length) data.AvoidMuscles = AvoidMuscles.join(',');
  if (ClientId) data.ClientId = ClientId;
  if (Equipment && Equipment.length) data.Equipment = Equipment.join(',');
  if (ExcludeExercises && ExcludeExercises.length) data.ExcludeExercises = ExcludeExercises.join(',');
  if (ExerciseName) data.ExerciseName = ExerciseName;
  if (ExercisesShown && ExercisesShown.length) data.ExercisesShown = ExercisesShown.join(',');
  if (ExperienceLevels && ExperienceLevels.length) data.ExperienceLevels = ExperienceLevels.join(',');
  if (IsBlockedInjuries !== null) data.IsBlockedInjuries = IsBlockedInjuries ? 1 : 0;
  if (Movement && Movement.length) data.Movement = Movement.join(',');
  if (MuscleGroupId) data.MuscleGroupId = MuscleGroupId;
  if (Owners && Owners.length) data.Owners = Owners.join(',');
  if (Plane && Plane.length) data.Plane = Plane.join(',');
  if (StartPosition && StartPosition.length) data.StartPosition = StartPosition.join(',');
  if (TagIds && TagIds.length) data.TagIds = TagIds.join(',');
  if (TargetJoints && TargetJoints.length) data.TargetJoints = TargetJoints.join(',');
  if (TargetMuscles && TargetMuscles.length) data.TargetMuscles = TargetMuscles.join(',');
  if (TemporaryDeletedExercises && TemporaryDeletedExercises.length) data.TemporaryDeletedExercises = TemporaryDeletedExercises.join(',');
  if (TrainingFocus && TrainingFocus.length) data.TrainingFocus = TrainingFocus.join(',');
  if (TrainingForm && TrainingForm.length) data.TrainingForm = TrainingForm.join(',');
  if (WorkoutId) data.WorkoutId = WorkoutId;

  const response = await axiosInstance.post(`${RIDGE_API}exercises/SearchExercises`, { ...data });
  // const response = await axiosInstance.post('http://localhost:3005/v1/elasticsearch/exercises/search', { ...data });

  if (response.data.Response === 1) {
    var { Exercises, IsMoreResults, NumPendingShared, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_EXERCISES_SUCCESS, payload: { AddToExisting, Exercises, IsMoreResults, NumPendingShared, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const SearchFood_SAYT = ({ ExcludeFoodIds, IngredientsShown, PageNo, PageSize, Search: FoodName }) => async dispatch => {

  var data = { PageNo, PageSize };
  if (ExcludeFoodIds && ExcludeFoodIds.length) data.ExcludeFoodIds = ExcludeFoodIds.join(',');
  else data.ExcludeFoodIds = "";

  if (FoodName) data.FoodName = FoodName;
  if (IngredientsShown && IngredientsShown.length) data.IngredientsShown = IngredientsShown.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/searchAddIngredients`, { SearchData: data });

  if (response.data.Response === 1) {
    var { Ingredients } = response.data;

    return { SearchResults: Ingredients };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchFood = ({ AddToExisting, ExcludeFoodIds, FoodName, IngredientsShown, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_FOOD });

  var data = { FoodName, PageNo, PageSize };
  if (ExcludeFoodIds && ExcludeFoodIds.length) data.ExcludeFoodIds = ExcludeFoodIds.join(',');
  else data.ExcludeFoodIds = "";
  if (IngredientsShown && IngredientsShown.length) data.IngredientsShown = IngredientsShown.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/searchAddIngredients`, { SearchData: data });

  if (response.data.Response === 1) {
    var { AddIngredients_IsMoreResults, Ingredients, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_FOOD_SUCCESS, payload: { AddIngredients_IsMoreResults, AddToExisting, Ingredients, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_FOOD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const SearchGroupManagers = ({ AddToExisting, GroupId, PageNo, PageSize, ReturnData, SortBy, SortByDirection, UserSearch }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_GROUP_MANAGERS });

  var data = { GroupId, PageNo, PageSize, SortBy, SortByDirection, UserSearch };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/SearchManagers`, { ...data });

  if (response.data.Response === 1) {
    var { GroupManagers, IsMoreResults, TotalRecords } = response.data.GroupManagers;

    dispatch({ type: TRYING_SEARCH_GROUP_MANAGERS_SUCCESS, payload: { AddToExisting, GroupManagers, IsMoreResults, ReturnData, TotalRecords } });

    return { GroupManagers, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_GROUP_MANAGERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchGroupMembers = ({ AddToExisting, GetOtherGroups, GroupId, GroupType, GroupWorkoutId, PageNo, PageSize, ReturnData, SortBy, SortByDirection, UserSearch }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_GROUP_MEMBERS });

  var data = { GetOtherGroups, GroupId, GroupType, GroupWorkoutId, PageNo, PageSize, SortBy, SortByDirection, UserSearch };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/group/SearchMembers`, { ...data });

  if (response.data.Response === 1) {
    var { GroupMembers, IsMoreResults, TotalRecords } = response.data.GroupMembers;

    dispatch({ type: TRYING_SEARCH_GROUP_MEMBERS_SUCCESS, payload: { AddToExisting, GroupMembers, IsMoreResults, ReturnData, TotalRecords } });

    return { GroupMembers, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_GROUP_MEMBERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchGroups = ({ AddToExisting, EligibleTypes, GetOtherGroups, GroupName, NoUsers, PageNo, PageSize, ReturnData }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_GROUPS });

  var data = { EligibleTypes, GetOtherGroups, GroupName, NoUsers, PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchGroups`, { ...data });

  if (response.data.Response === 1) {
    var { Groups, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_GROUPS_SUCCESS, payload: { AddToExisting, Groups, IsMoreResults, ReturnData, TotalRecords } });

    return { Groups, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUserFunc({ type: TRYING_SEARCH_GROUPS_ERROR, payload: response.data.ErrorMessage });
      else {
        dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
        dispatch({ type: TRYING_SEARCH_GROUPS_ERROR, payload: response.data.ErrorMessage });
      }
    }

    return {};
  }
}

export const SearchGyms = ({ AddToExisting, GymName, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_GYMS });

  var data = { PageNo, PageSize };
  if (GymName) data.GymName = GymName;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/viewGyms`, { ...data });

  if (response.data.Response === 1) {
    var { Gyms_IsMoreResults, Gyms, TotalRecords } = response.data;

    dispatch({ type: TRYING_GET_GYMS_SUCCESS, payload: { AddToExisting, Gyms_IsMoreResults, Gyms, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_GYMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const SearchMealTemplates_SAYT = ({ PageNo, PageSize, Search: TemplateName }) => async dispatch => {

  var data = { PageNo, PageSize };
  if (TemplateName) data.TemplateName = TemplateName;

  const response = await axiosInstance.post(`${RIDGE_API}meal-templates/SearchMealTemplates`, { ...data });

  if (response.data.Response === 1) {
    var { Templates } = response.data;

    return { SearchResults: Templates };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchMealTemplates = ({ AddToExisting, PageNo, PageSize, TemplateDays, TemplateName }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_MEAL_TEMPLATES });

  var data = { PageNo, PageSize };
  if (TemplateDays && TemplateDays.length) data.TemplateDays = TemplateDays.join(',');
  if (TemplateName) data.TemplateName = TemplateName;

  const response = await axiosInstance.post(`${RIDGE_API}meal-templates/SearchMealTemplates`, { ...data });

  if (response.data.Response === 1) {
    // var { TemplateDays: NewTemplateDays, Templates, Templates_IsMoreResults, TotalRecords } = response.data;
    var { MealTemplateFilters, NumPendingShared, Templates, IsMoreResults: Templates_IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_MEAL_TEMPLATES_SUCCESS, payload: { AddToExisting, MealTemplateFilters, NumPendingShared, Templates, Templates_IsMoreResults, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_MEAL_TEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const SearchCurrencies = () => async dispatch => {
  dispatch({ type: TRYING_SEARCH_CURRENCIES });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchCurrencies`, null);
  // const response = await axiosInstance.post(`http://ridges-MacBook-Pro.local:3005/v1/expenses/ViewCurrencies`, null);

  if (response.data.Response === 1) {
    var { Currencies } = response.data;

    dispatch({ type: TRYING_SEARCH_CURRENCIES_SUCCESS, payload: Currencies });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_CURRENCIES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const SearchExpenses = ({ Description, ExpenseCategoryId, NeedStats, OrderBy, OrderType, PageNo, PageSize, Year }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_EXPENSES });

  var data = { Description, ExpenseCategoryId, NeedStats, OrderBy, OrderType, PageNo, PageSize, Year };

  const response = await axiosInstance.post(`${RIDGE_API}expenses/ViewExpenses`, { ...data });
  // const response = await axiosInstance.post(`http://ridges-MacBook-Pro.local:3005/v1/expenses/ViewExpenses`, { ...data });

  if (response.data.Response === 1) {
    var { Expenses, Stats, TotalPages, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_EXPENSES_SUCCESS, payload: { Expenses, Stats, TotalPages, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_EXPENSES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const SearchPayouts = ({ EndDate, PageNo, PageSize, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_PAYOUTS });

  var UserDate = moment().format();

  var data = { EndDate, PageNo, PageSize, StartDate, UserDate };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchPayouts`, { ...data });

  if (response.data.Response === 1) {
    var { Payouts } = response.data;

    dispatch({ type: TRYING_SEARCH_PAYOUTS_SUCCESS, payload: { Payouts } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_PAYOUTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const SearchProducts = ({ PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_PRODUCTS });

  var data = { PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchProducts`, { ...data });

  if (response.data.Response === 1) {
    var { IsMoreResults, Products, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_PRODUCTS_SUCCESS });
    return { IsMoreResults, Products, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_PRODUCTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const SearchTrainerClients = ({ AddToExisting, EditingType, GetOtherGroups, GetStartedEligible, GroupId, GroupType, PageNo, PageSize, ReturnData, SortBy, SortByDirection, UserSearch }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINER_CLIENTS });

  var data = { EditingType, GetOtherGroups, GetStartedEligible, GroupId, GroupType, PageNo, PageSize, SortBy, SortByDirection, UserSearch };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchTrainerClients`, { ...data });

  if (response.data.Response === 1) {
    var { TrainerClients, IsMoreResults, TotalRecords } = response.data.TrainerClients;

    dispatch({ type: TRYING_SEARCH_TRAINER_CLIENTS_SUCCESS, payload: { AddToExisting, TrainerClients, IsMoreResults, ReturnData, TotalRecords } });

    return { TrainerClients, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINER_CLIENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchTrainerClientsSAYT = ({ Search, TrainerId }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINER_CLIENTS_SAYT });

  var data = { Search, TrainerId };

  const response = await axiosInstance.post(`${SARBJIT_API}SearchTrainerClients`, { ...data });

  if (response.data.Response === 1) {
    var { Clients } = response.data;

    dispatch({ type: TRYING_SEARCH_TRAINER_CLIENTS_SAYT_SUCCESS });

    return { Clients };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINER_CLIENTS_SAYT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchTrainerContracts = ({ ClientIds, PageNo, PageSize, OrderBy, OrderType, UserName }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS });

  var data = { ClientIds: ClientIds ? ClientIds.join(',') : '', InvoicesPageNo: 1, InvoicesPageSize: 10, PageNo, PageSize, ProductsPageNo: 1, ProductsPageSize: 10, OrderBy, OrderType, UserName };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchTrainerContracts`, { ...data });

  if (response.data.Response === 1) {
    var { Contracts, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS });
    return { Contracts, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const SearchTrainerContractsClients = ({ Type }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS });

  var data = { Type };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchTrainerContractClients`, { ...data });

  if (response.data.Response === 1) {
    var { Clients } = response.data;

    dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS });
    return { Clients };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const SearchTrainerContractsHistory = ({ ClientIds, PageNo, PageSize, OrderBy, OrderType, UserName }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS });

  var data = { ClientIds: ClientIds ? ClientIds.join(',') : '', InvoicesPageNo: 1, InvoicesPageSize: 10, PageNo, PageSize, ProductsPageNo: 1, ProductsPageSize: 10, OrderBy, OrderType, UserName };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchTrainerContractsHistory`, { ...data });

  if (response.data.Response === 1) {
    var { Contracts, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS });
    return { Contracts, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const SearchTrainerContractsHistoryClients = () => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/SearchTrainerHistoryContractClients`, {});

  if (response.data.Response === 1) {
    var { Clients } = response.data;

    dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS });
    return { Clients };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const SearchTrainingPrograms_SAYT = ({ PageNo, PageSize, Search: ProgramName }) => async dispatch => {

  var data = { PageNo, PageSize };
  if (ProgramName) data.ProgramName = ProgramName;

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/SearchTrainingPrograms`, { ...data });

  if (response.data.Response === 1) {
    var { TrainingPrograms } = response.data;

    return { SearchResults: TrainingPrograms };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchTrainingPrograms = ({ AddToExisting, HasDeleted, HideDeleted, Injuries, LanguageId, Owners, PageNo, PageSize, ProgramName, TagIds, TrainingFocus }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_TRAINING_PROGRAMS });

  var data = { HasDeleted, HideDeleted, LanguageId, PageNo, PageSize };
  if (Injuries && Injuries.length) data.Injuries = Injuries.join(',');
  if (Owners && Owners.length) data.Owners = Owners.join(',');
  if (ProgramName) data.ProgramName = ProgramName;
  if (TagIds && TagIds.length) data.TagIds = TagIds.join(',');
  if (TrainingFocus && TrainingFocus.length) data.TrainingFocus = TrainingFocus.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/SearchTrainingPrograms`, { ...data });

  if (response.data.Response === 1) {
    var { IsMoreResults, NumPendingShared, TrainingPrograms, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_TRAINING_PROGRAMS_SUCCESS, payload: { AddToExisting, IsMoreResults, NumPendingShared, TrainingPrograms, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_TRAINING_PROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const SearchWorkoutTemplates_SAYT = ({ LanguageId, Search: TemplateName }) => async dispatch => {

  var data = { LanguageId };
  if (TemplateName) data.TemplateName = TemplateName;

  const response = await axiosInstance.post(`${RIDGE_API}templates/SearchWorkoutTemplates_SAYT`, { ...data });

  if (response.data.Response === 1) {
    var { Templates } = response.data;

    return { SearchResults: Templates };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const SearchWorkoutTemplates = ({ AddToExisting, HasDeleted, HideDeleted, Injuries, LanguageId, Owners, PageNo, PageSize, RepRange, TemplateName, TagIds, TrainingFocus, WorkoutType }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_WORKOUT_TEMPLATES });

  var data = { HasDeleted, HideDeleted, LanguageId, PageNo, PageSize };
  if (Injuries && Injuries.length) data.Injuries = Injuries.join(',');
  if (Owners && typeof Owners !== 'string' && Owners.length) data.Owners = typeof Owners !== 'string' ? Owners.join(',') : Owners;
  if (RepRange && RepRange.length) data.RepRange = RepRange.join(',');
  if (TagIds && typeof TagIds !== 'string' && TagIds.length) data.TagIds = typeof TagIds !== 'string' ? TagIds.join(',') : TagIds;
  if (TemplateName) data.TemplateName = TemplateName;
  if (TrainingFocus && TrainingFocus.length) data.TrainingFocus = TrainingFocus.join(',');
  if (WorkoutType && WorkoutType.length) data.WorkoutType = typeof WorkoutType !== 'string' ? WorkoutType.join(',') : WorkoutType;

  // const response = await axiosInstance.post(`${RIDGE_API}ptadmin/searchtemplates`, { ...data });
  const response = await axiosInstance.post(`${RIDGE_API}templates/SearchWorkoutTemplates`, { ...data });

  if (response.data.Response === 1) {
    // var { Templates, Templates_IsMoreResults, TotalRecords } = response.data;
    var { NumPendingShared, Templates, IsMoreResults: Templates_IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_WORKOUT_TEMPLATES_SUCCESS, payload: { AddToExisting, NumPendingShared, Templates, Templates_IsMoreResults, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_WORKOUT_TEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ShowErrorModalWarning = ({ ModalError }) => async dispatch => {
  dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError, ModalErrorNoLink: true } });
}

export const SubscribePaymentGateway = ({ CurrencyId, ReturnUrl }) => async dispatch => {
  dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY });

  var data = { CurrencyId, ReturnUrl };

  const response = await axiosInstance.post(`${RIDGE_API}payments/SubscribePaymentGateway`, { ...data });

  if (response.data.Response === 1) {
    var { hostedPaymentPageUrl } = response.data;

    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS });

    return { hostedPaymentPageUrl };
  } else {
    dispatch({ type: TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });

    return {};
  }
}

export const Tasks_AssignStatus = ({ ClientIds, EndDate, RecurrencePattern, StartDate, StatusIds }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { ClientIds, EndDate, RecurrencePattern, StartDate, StatusIds };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/AssignStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_AssignTask = ({ ClientIds, EndDate, RecurrencePattern, StartDate, TaskIds }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { ClientIds, EndDate, RecurrencePattern, StartDate, TaskIds };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/AssignTask`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_DeleteStatus = ({ StatusId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { StatusId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/DeleteStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_DeleteTask = ({ TaskId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { TaskId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/DeleteTask`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_GetGetCustomStatusDetails = ({ LanguageId, StatusId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { LanguageId, StatusId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetCustomStatusDetails`, { ...data });

  if (response.data.Response === 1) {
    var { Status, Languages, Translations } = response.data;

    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { Status, Languages, Translations };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_GetGetCustomTaskDetails = ({ LanguageId, TaskId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { LanguageId, TaskId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetCustomTaskDetails`, { ...data });

  if (response.data.Response === 1) {
    var { Task, Languages, Translations } = response.data;

    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { Task, Languages, Translations };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_GetStatuses = ({ PageNo, PageSize, StatusName = '' }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { PageNo, PageSize, StatusName };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetStatuses`, { ...data });

  if (response.data.Response === 1) {
    var { Statuses, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { Statuses, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_GetTasks = ({ PageNo, PageSize, TaskName = '', TaskTypes }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { PageNo, PageSize, TaskName, TaskTypes };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetTasks`, { ...data });

  if (response.data.Response === 1) {
    var { Tasks, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { Tasks, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_GetTaskTypes = () => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetTaskTypes`, {});

  if (response.data.Response === 1) {
    var { TaskTypes } = response.data;

    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { TaskTypes };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_InsertEditStatus = ({ Icon, ResponseHidden, StatusId, Translations }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { Icon, ResponseHidden, StatusId, Translations };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/InsertEditStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_InsertEditTask = ({ Amount, Icon, ResponseHidden, TaskId, TaskType, Translations }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { Amount, Icon, ResponseHidden, TaskId, TaskType, Translations };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/InsertEditTask`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_GetUserTasksAndStatuses = ({ CalendarDifference, ClientId, EndDate, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION_COPY, payload: { CalendarDifference } });
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { ClientId, EndDate, StartDate };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetUserTasksAndStatuses`, { ...data });

  if (response.data.Response === 1) {
    var { UserTasksAndStatuses } = response.data;

    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { UserTasksAndStatuses };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_GetUserStatus_Progress = ({ StatusId, UserId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { StatusId, UserDate: moment().format('YYYY-MM-DD'), UserId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetUserStatus_Progress`, { ...data });

  if (response.data.Response === 1) {
    var { Calendar, ClientDetails, Stats, Status } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { Calendar, Stats, Status };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_GetUserTask_Progress = ({ TaskId, UserId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { TaskId, UserDate: moment().format('YYYY-MM-DD'), UserId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/GetUserTask_Progress`, { ...data });

  if (response.data.Response === 1) {
    var { Calendar, ClientDetails, Stats, Task } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
    return { Calendar, Stats, Task };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const Tasks_RemoveStatus = ({ DeleteType, UserStatusId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { DeleteType, UserStatusId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/RemoveStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_RemoveTask = ({ DeleteType, UserTaskId }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { DeleteType, UserTaskId };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/RemoveTask`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_ReorderStatuses = ({ UpdatedOrder }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { UpdatedOrder };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/ReorderStatuses`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const Tasks_ReorderTasks = ({ UpdatedOrder }) => async dispatch => {
  dispatch({ type: TRYING_TASKS_ACTION });

  var data = { UpdatedOrder };

  const response = await axiosInstance.post(`${RIDGE_API}tasks/ReorderTasks`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_TASKS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_TASKS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const TransactionHistory_Get = ({ EndDate, OrganizationId, StartDate }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { EndDate, OrganizationId, StartDate };

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetTransactionHistoryByOrg`, { ...data });

  if (response.data.Response === 1) {
    var { TransactionHistory } = response.data;

    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
    return { TransactionHistory };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const UpdateBankAccountNo = BankAccountNo => async dispatch => {
  dispatch({ type: TRYING_EDIT_USER_PROFILE });

  var data = { BankAccountNo };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateBankAccountNo`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_EDIT_USER_PROFILE_SUCCESS, payload: { BankAccountNo } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_EDIT_USER_PROFILE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateClientAssigning = ({ AddClients, ClientAssigningId, Clients, TemplateIds }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_CLIENT_ASSIGNING });

  var data = { AddClients, ClientAssigningId, Clients, TemplateIds };

  const response = await axiosInstance.post(`${RIDGE_API}templates/UpdateClientAssigning`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_CLIENT_ASSIGNING_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_CLIENT_ASSIGNING_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateContractProductDeliveredQuantity = ({ ContractId, DeliveredQuantity, ProductId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY });

  var data = { ContractId, DeliveredQuantity, ProductId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateContractProductDeliveredQuantity`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateDailyActivityLevel = ({ ActivityLevel, Calories, ClientId, DayDate, DayIndex, LevelType }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_DAILY_ACTIVITY_LEVEL, payload: { ActivityLevel, Calories, DayIndex } });

  var data = { UserId: ClientId, ActivityLevel, Calories, LevelType, MealDate: DayDate };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/updateDailyActivityLevel`, { DailyActivityData: data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateDishCompletionStatus = ({ DoneStatus, uId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_DISH_COMPLETION_STATUS });

  var data = { uId, DoneStatus };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/updateMealPlanDishCompleteStatus`, { DishCompleteStatusData: data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_DISH_COMPLETION_STATUS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_DISH_COMPLETION_STATUS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateDishDetailFood = ({ ClientId, DishId, FoodId, MealPlanUId, Quantity, UnitId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_DISH_DETAIL_FOOD });

  var data = { UserId: ClientId, DishId, FoodId, MealPlanUId, Quantity, UnitId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/updateDishDetailFood`, { DishDetailFoodData: data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_DISH_DETAIL_FOOD_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_DISH_DETAIL_FOOD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateExercisesTags = ({ ActionType, ExerciseIds, TagIds }) => async dispatch => {
  // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });
  
  var data = { ActionType, ExerciseIds, TagIds };

  let response = await axiosInstance.post(`${RIDGE_API}exercises/UpdateExercisesTags`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return;
  }
}

export const UpdateMealAccess = ({ ClientType, EndDate, ReturnDataType, StartDate, UpdateType, UserId }) => async dispatch => {
  // ReturnDataType = Dashboard or ClientDetail
  // UpdateType = "give" or "remove"
  dispatch({ type: TRYING_UPDATE_MEAL_ACCESS, payload: { ReturnDataType } });

  var data = { ReturnDataType, UpdateType, UserDate: moment().format('YYYY-MM-DD'), UserId };
  if (ReturnDataType === 'ClientDetail') data = { ...data, EndDate, StartDate };
  else if (ReturnDataType === 'Dashboard') data = { ...data };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/mealaccess`, { ...data });

  if (response.data.Response === 1) {
    var { Clients, Meals, MealAccess, NeedsHeight, NeedsWeight, ShowGetStarted } = response.data;

    dispatch({ type: TRYING_UPDATE_MEAL_ACCESS_SUCCESS, payload: { Clients, ClientType, ReturnDataType, UserId, Meals, MealAccess, NeedsHeight, NeedsWeight, ShowGetStarted } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_MEAL_ACCESS_ERROR, payload: { ReturnDataType, ErrorMessage: response.data.ErrorMessage } });

      if (response.data.BadToken) LogoutUser()
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateNotificationSettings = ({ Settings }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_NOTIFICATION_SETTINGS });

  var data = { Settings };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateNotificationSettings`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_NOTIFICATION_SETTINGS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_NOTIFICATION_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateNutritionPreviewFilter = ({ IsBasic, NutrientIds }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS });

  var data = { IsBasic, NutrientIds };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateNutritionPreviewFilter`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateOrganizationIncome = ({ IncomeShare }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_INCOME });

  var data = { IncomeShare };

  const response = await axiosInstance.post(`${RIDGE_API}payments/UpdateOrganizationIncome`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_ORGANIZATION_INCOME_SUCCESS, payload: { IncomeShare } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_ORGANIZATION_INCOME_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateOrganizationMemberDetails = ({ IncomeShare, MemberIndex, OrganizationMemberId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS });

  var data = { IncomeShare, OrganizationMemberId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/UpdateOrganizationMemberDetails`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_SUCCESS, payload: { IncomeShare, MemberIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateOrganizationMemberInvitation = ({ Acceptance, OrganizationId, OrganizationUserId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS });

  var data = { Acceptance, OrganizationId, OrganizationUserId };

  const response = await axiosInstance.post(`${RIDGE_API}payments/UpdateOrganizationMemberInvitation`, { ...data });

  if (response.data.Response === 1) {
    var { UserDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS, payload: { UserDetails } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdatePausedSharing_Exercises = ({ ExerciseId, IsPaused }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_EXERCISES });

  var data = { ExerciseId, IsPaused };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/UpdatePausedSharing`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_EXERCISES_SUCCESS, payload: { IsPaused } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdatePausedSharing_MealTemplates = ({ IsPaused, TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES });

  var data = { IsPaused, TemplateId };

  const response = await axiosInstance.post(`${RIDGE_API}meal-templates/UpdatePausedSharing`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_SUCCESS, payload: { IsPaused } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdatePausedSharing_TrainingPrograms = ({ IsPaused, TrainingProgramId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS });

  var data = { IsPaused, TrainingProgramId };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/UpdatePausedSharing`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_SUCCESS, payload: { IsPaused } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdatePausedSharing_WorkoutTemplates = ({ IsPaused, TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES });

  var data = { IsPaused, TemplateId };

  const response = await axiosInstance.post(`${RIDGE_API}templates/UpdatePausedSharing`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_SUCCESS, payload: { IsPaused } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdatePDFLogo = PDFLogo => async dispatch => {
  dispatch({ type: UPDATE_PDF_LOGO, payload: { PDFLogo } });
}

export const UpdateSelectedTab = SelectedTab => async dispatch => {
  dispatch({ type: UPDATE_SELECTED_TAB, payload: SelectedTab });
}

export const UpdateShareAbilityStatus_Exercises = ({ ExerciseId, NewStatus, NewStatusName }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES });

  var data = { ExerciseId, NewStatus, NewStatusName };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/UpdateShareAbilityStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS, payload: { ExerciseId, NewStatus, NewStatusName } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateShareAbilityStatus_MealTemplates = ({ NewStatus, NewStatusName, TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES });

  var data = { NewStatus, NewStatusName, TemplateId };

  const response = await axiosInstance.post(`${RIDGE_API}meal-templates/UpdateShareAbilityStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_SUCCESS, payload: { NewStatus, NewStatusName, TemplateId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateShareAbilityStatus_TrainingPrograms = ({ NewStatus, NewStatusName, TrainingProgramId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS });

  var data = { NewStatus, NewStatusName, TrainingProgramId };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/UpdateShareAbilityStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_SUCCESS, payload: { NewStatus, NewStatusName, TrainingProgramId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateShareAbilityStatus_WorkoutTemplates = ({ NewStatus, NewStatusName, TemplateId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES });

  var data = { NewStatus, NewStatusName, TemplateId };

  const response = await axiosInstance.post(`${RIDGE_API}templates/UpdateShareAbilityStatus`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_SUCCESS, payload: { NewStatus, NewStatusName, TemplateId } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatusAll_Exercises = ({ MergeTags, NewStatus }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES });

  var data = { MergeTags: +MergeTags, NewStatus };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/UpdateAllSharedTo`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_SUCCESS });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatusAll_MealTemplates = ({ NewStatus }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES });

  var data = { NewStatus };

  const response = await axiosInstance.post(`${RIDGE_API}meal-templates/UpdateAllSharedTo`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatusAll_TrainingPrograms = ({ MergeTags, NewStatus }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS });

  var data = { MergeTags: +MergeTags, NewStatus };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/UpdateAllSharedTo`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_SUCCESS });
    dispatch({ type: TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatusAll_WorkoutTemplates = ({ MergeTags, NewStatus }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES });

  var data = { MergeTags: +MergeTags, NewStatus };

  const response = await axiosInstance.post(`${RIDGE_API}templates/UpdateAllSharedTo`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_SUCCESS });
    dispatch({ type: TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatus_Exercises = ({ Emails, ExerciseId, ExerciseIds = [], NewStatus, PendingContentRequestIndex = null, PersonalResponse = 0 }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES });

  if (Emails) Emails = Emails.join(',');

  if (ExerciseId && ExerciseIds.indexOf(+ExerciseId) === -1) ExerciseIds.push(ExerciseId);

  if (PendingContentRequestIndex !== null) var ContentId = ExerciseIds[0];

  ExerciseIds = ExerciseIds.join(',');

  var data = { Emails, ExerciseIds, NewStatus, PersonalResponse };

  const response = await axiosInstance.post(`${RIDGE_API}exercises/UpdateSharedToCoachStatus`, { ...data });

  if (response.data.Response === 1) {
    var { SharingDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_SUCCESS, payload: { SharingDetails, UpdateSingleTemplate: !!+ExerciseId } });
    if (PendingContentRequestIndex !== null) dispatch({ type: REMOVE_PENDING_CONTENT_REQUEST, payload: { ContentId, ContentType: 'Exercise', NewStatus, PendingContentRequestIndex } });
    dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateSharedToCoachStatus_MealTemplates = ({ Emails, NewStatus, PendingContentRequestIndex = null, PersonalResponse = 0, TemplateId, TemplateIds = [] }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES });

  if (Emails) Emails = Emails.join(',');

  if (TemplateId && TemplateIds.indexOf(+TemplateId) === -1) TemplateIds.push(TemplateId);

  if (PendingContentRequestIndex !== null) var ContentId = TemplateIds[0];

  TemplateIds = TemplateIds.join(',');

  var data = { Emails, NewStatus, PersonalResponse, TemplateIds };

  const response = await axiosInstance.post(`${RIDGE_API}meal-templates/UpdateSharedToCoachStatus`, { ...data });

  if (response.data.Response === 1) {
    var { SharingDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_SUCCESS, payload: { SharingDetails, UpdateSingleTemplate: !!+TemplateId } });
    if (PendingContentRequestIndex !== null) dispatch({ type: REMOVE_PENDING_CONTENT_REQUEST, payload: { ContentId, ContentType: 'MealTemplate', NewStatus, PendingContentRequestIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatus_TrainingPrograms = ({ Emails, NewStatus, PendingContentRequestIndex = null, PersonalResponse = 0, TrainingProgramId, TrainingProgramIds = [] }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS });

  if (Emails) Emails = Emails.join(',');

  if (TrainingProgramId && TrainingProgramIds.indexOf(+TrainingProgramId) === -1) TrainingProgramIds.push(TrainingProgramId);

  if (PendingContentRequestIndex !== null) var ContentId = TrainingProgramIds[0];

  TrainingProgramIds = TrainingProgramIds.join(',');

  var data = { Emails, NewStatus, PersonalResponse, TrainingProgramIds };

  const response = await axiosInstance.post(`${RIDGE_API}training-programs/UpdateSharedToCoachStatus`, { ...data });

  if (response.data.Response === 1) {
    var { SharingDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_SUCCESS, payload: { SharingDetails, UpdateSingleTemplate: !!+TrainingProgramId } });
    if (PendingContentRequestIndex !== null) dispatch({ type: REMOVE_PENDING_CONTENT_REQUEST, payload: { ContentId, ContentType: 'TrainingProgram', NewStatus, PendingContentRequestIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateSharedToCoachStatus_WorkoutTemplates = ({ Emails, NewStatus, PendingContentRequestIndex = null, PersonalResponse = 0, TemplateId, TemplateIds = [] }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES });

  if (Emails) Emails = Emails.join(',');

  if (TemplateId && TemplateIds.indexOf(+TemplateId) === -1) TemplateIds.push(TemplateId);

  if (PendingContentRequestIndex !== null) var ContentId = TemplateIds[0];

  TemplateIds = TemplateIds.join(',');

  var data = { Emails, NewStatus, PersonalResponse, TemplateIds };

  const response = await axiosInstance.post(`${RIDGE_API}templates/UpdateSharedToCoachStatus`, { ...data });

  if (response.data.Response === 1) {
    var { SharingDetails } = response.data;

    dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_SUCCESS, payload: { SharingDetails, UpdateSingleTemplate: !!+TemplateId } });
    if (PendingContentRequestIndex !== null) dispatch({ type: REMOVE_PENDING_CONTENT_REQUEST, payload: { ContentId, ContentType: 'WorkoutTemplate', NewStatus, PendingContentRequestIndex } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateTemplatesTags = ({ ActionType, TemplateIds, TagIds }) => async dispatch => {
  // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });
  
  var data = { ActionType, TemplateIds, TagIds };

  let response = await axiosInstance.post(`${RIDGE_API}templates/UpdateTemplatesTags`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return;
  }
}

export const UpdateTrainerClientColor = ({ ClientColor, ClientId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_TRAINER_CLIENT_COLOR, payload: { ClientColor, ClientId } });

  var data = { ClientColor, ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/client/updateclientcolor`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_TRAINER_CLIENT_COLOR_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_TRAINER_CLIENT_COLOR_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateTrainerClientNote = (ClientId, Note) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_TRAINER_CLIENT_NOTE });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/client/updateclientnote`, { ClientId, Note });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_TRAINER_CLIENT_NOTE_SUCCESS, payload: Note });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_TRAINER_CLIENT_NOTE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
    }
  }
}

export const UpdateTrainerGroupsOrder = (OldIndex, NewIndex) => async dispatch => {
  dispatch({ type: UPDATE_TRAINER_GROUP_ORDER, payload: { OldIndex, NewIndex } });
}

export const UpdateTrainingProgramsTags = ({ ActionType, TrainingProgramIds, TagIds }) => async dispatch => {
  // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS });
  
  var data = { ActionType, TrainingProgramIds, TagIds };

  let response = await axiosInstance.post(`${RIDGE_API}training-programs/UpdateTrainingProgramsTags`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS, payload: { ...response.data } });

    return;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return;
  }
}

export const UpdateUserPaymentGatewayInfo = () => async dispatch => {
  dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER });

  const response = await axiosInstance.post(`${RIDGE_API}payments/UpdateUserPaymentGatewayInfo`, null);

  if (response.data.Response === 1) {
    var { MembershipRequests, SubscriptionStatus, UserDetails } = response.data;

    UserDetails.MembershipRequests = MembershipRequests;
    UserDetails.SubscriptionStatus = SubscriptionStatus;

    dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER_SUCCESS });
    dispatch({ type: TRYING_LOGIN_USER_SUCCESS, payload: UserDetails });
  } else {
    dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER_ERROR, payload: response.data.ErrorMessage });

    if (response.data.BadToken) LogoutUser();
    else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
  }
}

export const UpdateUserSettings = ({ LanguageId, PreferredMeasuringSystem }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_USER_SETTINGS });

  var data = { LanguageId, PreferredMeasuringSystem };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateUserSettings`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_USER_SETTINGS_SUCCESS, payload: { LanguageId, PreferredMeasuringSystem } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_USER_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateWorkoutAccess = ({ ClientType, EndDate, ReturnDataType, StartDate, UpdateType, UserId }) => async dispatch => {
  // ReturnDataType = Dashboard or ClientDetail
  // UpdateType = "give" or "remove"
  dispatch({ type: TRYING_UPDATE_WORKOUT_ACCESS, payload: { ReturnDataType } });

  var data = { ReturnDataType, UpdateType, UserId };
  if (ReturnDataType === 'ClientDetail') data = { ...data, EndDate, StartDate };
  else if (ReturnDataType === 'Dashboard') data = { ...data, UserDate: moment().format('YYYY-MM-DD') };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/workoutaccess`, { ...data });

  if (response.data.Response === 1) {
    var { Clients, Workouts, NeedsWeight, ShowGetStarted, WorkoutAccess } = response.data;

    dispatch({ type: TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS, payload: { Clients, ClientType, ReturnDataType, UserId, Workouts, NeedsWeight, ShowGetStarted, WorkoutAccess } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_WORKOUT_ACCESS_ERROR, payload: { ReturnDataType, ErrorMessage: response.data.ErrorMessage } });

      if (response.data.BadToken) LogoutUser()
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const UpdateWorkoutInformation = ({ AssignedDate, ClientId, Duration, WorkoutId, WorkoutName }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_WORKOUT_INFORMATION });

  var data = { AssignedDate, ClientId, Duration, WorkoutId, WorkoutName };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateWorkoutInformation`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_UPDATE_WORKOUT_INFORMATION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_WORKOUT_INFORMATION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const UpdateWorkoutLap = ({ Active, BreakTime, ClientId, Completed, LapType, Rest, Speed, WorkoutId, WorkoutLapId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_WORKOUT_LAP });

  var data = { Active, BreakTime, ClientId, Completed, LapType, Rest, Speed, WorkoutId, WorkoutLapId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/UpdateWorkoutLap`, { ...data });

  if (response.data.Response === 1) {
    var { WorkoutCompletionStatus: { WorkoutCompleted } } = response.data;

    dispatch({ type: TRYING_UPDATE_WORKOUT_LAP_SUCCESS });
    return { WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_WORKOUT_LAP_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const UpdateWorkoutSet = ({ ClientId, Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Rest, Seconds, Weight, WorkoutExerciseId, WorkoutId, WorkoutSetId }) => async dispatch => {
  dispatch({ type: TRYING_UPDATE_WORKOUT_SET });

  var data = { ClientId, Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Rest, Seconds, Weight, WorkoutExerciseId, WorkoutId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/exercises/${WorkoutSetId}`, { ...data });

  if (response.data.Response === 1) {
    var { CanDelete, WorkoutCompleted } = response.data;

    dispatch({ type: TRYING_UPDATE_WORKOUT_SET_SUCCESS });
    return { CanDelete, WorkoutCompleted };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPDATE_WORKOUT_SET_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const UploadOrganizationPicture = ({ Media, MediaUrl }) => async dispatch => {
  dispatch({ type: TRYING_UPLOAD_ORGANIZATION_PICTURE });

  var data = new FormData();
  data.append('Media', Media ? JSON.stringify(Media) : null);

  const response = await axiosInstance.post(`${RIDGE_API}payments/UploadOrganizationPicture`, data);

  if (response.data.Response === 1) {
    var { PreSignedUrls } = response.data;

    dispatch({ type: TRYING_UPLOAD_ORGANIZATION_PICTURE_SUCCESS, payload: { MediaUrl } });

    return { PreSignedUrls };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPLOAD_ORGANIZATION_PICTURE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const UploadProfilePicture = ({ ContentType, FileName, MediaUrl }) => async dispatch => {
  dispatch({ type: TRYING_UPLOAD_PROFILE_PICTURE });


  var data = { ProfilePhotoContentType: ContentType, ProfilePhotoFileName: FileName };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/EditUserProfile`, data);

  if (response.data.Response === 1) {
    var { PresignedFormData } = response.data;

    dispatch({ type: TRYING_UPLOAD_PROFILE_PICTURE_SUCCESS, payload: { ProfilePhotoUrl: MediaUrl } });
    return { PresignedFormData };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_UPLOAD_PROFILE_PICTURE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const UploadToPresignedUrl = ({ ContentType, CustomLabelName, File, PresignedFormData }) => async dispatch => {
  try {
    dispatch({ type: TRYING_GET_PRESIGNED_URL });

    const formData = new FormData();
    Object.entries(PresignedFormData.fields).forEach(([k, v]) => { formData.append(k, v); });
    formData.append("file", File);

    var ExtraHeaderConfig = {
      onUploadProgress: progress => {
        const { loaded, total } = progress;
        dispatch({ type: TRYING_UPLOAD_CONTENT_PROGRESS, payload: { type: ContentType, labelName: CustomLabelName || File.name, loaded, status: 'Uploading', total } });

        if (loaded === total) dispatch({ type: TRYING_UPLOAD_CONTENT_PROGRESS, payload: { type: ContentType, labelName: CustomLabelName || File.name, status: 'Complete' } });
      }
    };

    const response = await axios.post(PresignedFormData.url, formData, ExtraHeaderConfig);

    if (response.status === 204) {
      dispatch({ type: TRYING_GET_PRESIGNED_URL_SUCCESS });
    } else {
      dispatch({ type: TRYING_GET_PRESIGNED_URL_ERROR, payload: 'Error uploading media' });

      // Set loading to full
      dispatch({ type: TRYING_UPLOAD_CONTENT_PROGRESS, payload: { type: ContentType, labelName: CustomLabelName || File.name, status: 'Complete' } });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: 'Error uploading media' } });
    }
  } catch (error) {
    dispatch({ type: TRYING_GET_PRESIGNED_URL_ERROR, payload: 'Error uploading media' });

    // Set loading to full
    dispatch({ type: TRYING_UPLOAD_CONTENT_PROGRESS, payload: { type: ContentType, labelName: CustomLabelName || File.name, status: 'Complete' } });

    throw error;
  }
}

export const UserAccounts_ChangeEmail = ({ Email, NewEmail }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { CurrentEmail: Email, NewEmail };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/ChangeEmail`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return { Response: response.data.Response };
}

export const UserAccounts_ChangeSubPrice = ({ IsCoach, SubPrice, UserId }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { IsCoach, SubPrice, UserId };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/ChangeSubPrice`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return { Response: response.data.Response };
}

export const UserAccounts_ResetPassword = ({ Password, UserId }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { Password, UserId };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/ResetPassword`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const UserAccounts_SearchUsers = ({ DisplayName }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { DisplayName };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/SearchUsers`, { ...data });

  if (response.data.Response === 1) {
    var { Users } = response.data;

    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
    return { Users };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const UserAccounts_UndeleteUser = ({ UserId }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { UserId };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/UndeleteUser`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const UserAccounts_VerifyUser = ({ UserId }) => async dispatch => {
  dispatch({ type: TRYING_USER_ACCOUNTS_ACTION });

  var data = { UserId };

  const response = await axiosInstance.post(`${RIDGE_API}user-accounts/VerifyUser`, { ...data });

  if (response.data.Response === 1) {
    dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_SUCCESS });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_USER_ACCOUNTS_ACTION_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ValidateUser = ({ Register = false, UserName }) => async dispatch => {
  dispatch({ type: TRYING_VALIDATE_USER });

  var data = { UserName };

  // const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ValidateUser${!!Register ? '_Register' : ''}`, { ...data });
  const response = await axiosInstance.post(`https://dev.api.abel.fit/azure-dev/dev_v2/api/ValidateUser${!!Register ? '_Register' : ''}`, { ...data });

  if (response.data.Response === 1) {
    var { UserNameTaken } = response.data;

    dispatch({ type: TRYING_VALIDATE_USER_SUCCESS });
    return { UserNameTaken };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VALIDATE_USER_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const ViewAvailableLanguages = () => async dispatch => {
  dispatch({ type: TRYING_VIEW_AVAILABLE_LANGUAGES });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewAvailableLanguages`, {});

  if (response.data.Response === 1) {
    var { Languages } = response.data;

    dispatch({ type: TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS });
    return { Languages }
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ViewActivityLevels = ClientId => async dispatch => {
  dispatch({ type: TRYING_GET_ACTIVITY_LEVELS });

  var data = {};
  data.UserId = Number(ClientId);

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/viewActivityLevels`, { ActivityLevelData: data });

  if (response.data.Response === 1) {
    var { ActivityLevels, CurrentActivityLevel } = response.data;

    dispatch({ type: TRYING_GET_ACTIVITY_LEVELS_SUCCESS, payload: { ActivityLevels, CurrentActivityLevel: CurrentActivityLevel[0] } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_ACTIVITY_LEVELS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewBodyFatProgress = ({ BodyGoalTypeId, ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { BodyGoalTypeId, ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewBodyFat`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewBodyWeightProgress = ({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewBodyWeight`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewCircumferenceProgress = ({ BodyAreaId, ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { BodyAreaId, ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewCircumference`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewCreatePrivateDishSettings = () => async dispatch => {
  dispatch({ type: TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/viewCreatePrivateDishSettings`, {});

  if (response.data.Response === 1) {
    var { DishCategories, MealTypes } = response.data;

    dispatch({ type: TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_SUCCESS, payload: { DishCategories, MealTypes } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewCreatePrivateExerciseSettings = ({ ExerciseId }) => async dispatch => {
  dispatch({ type: TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS });

  var data = {};
  if (ExerciseId) data.ExerciseId = +ExerciseId;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/getcustomexercisedetails`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewCreatePrivateFoodSettings = ({ FoodId }) => async dispatch => {
  dispatch({ type: TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS });

  var data = {};
  if (FoodId) data.FoodId = +FoodId;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewCustomFoodSettings`, { ...data });

  if (response.data.Response === 1) {
    delete response.data.Response;

    dispatch({ type: TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_SUCCESS, payload: { ...response.data } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewDataCenterDashboard = ({ BranchIds, OrgIds, SortCol, SortDir, SubStatus }) => async dispatch => {
  dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD });

  var data = { BranchIds, OrgIds, SortCol, SortDir, SubStatus };

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetDataCenterDashboard`, { ...data });

  if (response.data.Response === 1) {
    var { DataCenterDashboard } = response.data;

    dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_SUCCESS });
    return { DataCenterDashboard };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewDataCenterDashboardFilters = () => async dispatch => {
  dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_FILTERS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetDataCenterDashboardFilters`, {});

  if (response.data.Response === 1) {
    var { Branches, Organizations } = response.data;

    dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_FILTERS_SUCCESS });
    return { Branches, Organizations };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_FILTERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewDataCenterSuperAdminDashboard = () => async dispatch => {
  dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD });

  var data = {};

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetOrganizationDataCenterDashboard`, { ...data });

  if (response.data.Response === 1) {
    var { SuperAdminDashboard } = response.data;

    dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_SUCCESS });
    return { SuperAdminDashboard };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_DATACENTER_DASHBOARD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewDishFoods_MealPlanBuilder = ({ DishId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER });

  var data = { DishId };

  const response = await axiosInstance.post(`${RIDGE_API}templates/GetDishFoods_MealPlanBuilder`, { ...data });

  if (response.data.Response === 1) {
    var { Foods } = response.data;

    dispatch({ type: TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_SUCCESS });
    return { Foods };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const ViewFoods_MealPlanBuilder = ({ AddToExisting, ExcludeFoodIds, FoodName, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_SEARCH_FOOD });

  var data = { FoodName, PageNo, PageSize };

  if (ExcludeFoodIds && ExcludeFoodIds.length) data.ExcludeFoodIds = ExcludeFoodIds.join(',');
  else data.ExcludeFoodIds = "";

  const response = await axiosInstance.post(`${RIDGE_API}templates/GetFoods_MealPlanBuilder`, { ...data });

  if (response.data.Response === 1) {
    var { Foods: Ingredients, IsMoreResults: AddIngredients_IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_SEARCH_FOOD_SUCCESS, payload: { AddIngredients_IsMoreResults, AddToExisting, Ingredients, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_SEARCH_FOOD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewEnduranceProgress = ({ ClientId, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_ENDURANCE_PROGRESS });

  var data = { PageNo, PageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewEndurance`, { ...data });

  if (response.data.Response === 1) {
    var { ClientDetails, Endurances, OverallPercentage } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_ENDURANCE_PROGRESS_SUCCESS, payload: { Endurances, OverallPercentage } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ENDURANCE_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewEnduranceTemplates = ({ ClientId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_ENDURANCE_TEMPLATES });

  var data = { UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewEnduranceTemplates`, { ...data });

  if (response.data.Response === 1) {
    var { Endurances } = response.data;

    dispatch({ type: TRYING_VIEW_ENDURANCE_TEMPLATES_SUCCESS });
    return { Endurances };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ENDURANCE_TEMPLATES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ViewExperienceLevels = () => async dispatch => {
  dispatch({ type: TRYING_GET_EXPERIENCE_LEVELS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/viewExperienceLevels`, {});

  if (response.data.Response === 1) {
    var { Endurance, Strength } = response.data;

    dispatch({ type: TRYING_GET_EXPERIENCE_LEVELS_SUCCESS, payload: { Endurance, Strength } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_EXPERIENCE_LEVELS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewExpenseCategories = () => async dispatch => {
  dispatch({ type: TRYING_VIEW_EXPENSE_CATEGORIES });

  const response = await axiosInstance.post(`${RIDGE_API}expenses/ViewExpenseCategories`, {});

  if (response.data.Response === 1) {
    var { ExpenseCategories } = response.data;

    dispatch({ type: TRYING_VIEW_EXPENSE_CATEGORIES_SUCCESS, payload: { ExpenseCategories } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_EXPENSE_CATEGORIES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewFoodUnits = FoodIds => async dispatch => {
  dispatch({ type: TRYING_VIEW_FOOD_UNITS });

  var data = {};
  data.FoodIds = FoodIds.join(',');

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/viewFoodUnits`, { ...data });

  if (response.data.Response === 1) {
    var { FoodUnits } = response.data;

    dispatch({ type: TRYING_VIEW_FOOD_UNITS_SUCCESS });
    return FoodUnits;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_FOOD_UNITS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewGoals = () => async dispatch => {
  dispatch({ type: TRYING_GET_GOALS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewGoals`, {});

  if (response.data.Response === 1) {
    var { Goals } = response.data;

    dispatch({ type: TRYING_GET_GOALS_SUCCESS, payload: Goals });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_GOALS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewInjuries = ClientId => async dispatch => {
  dispatch({ type: TRYING_GET_INJURIES });

  var data = {};
  if (ClientId) data.UserId = ClientId;

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/viewInjuries`, { ...data });

  if (response.data.Response === 1) {
    var { Joints, Muscles } = response.data;

    dispatch({ type: TRYING_GET_INJURIES_SUCCESS, payload: { Joints, Muscles } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_INJURIES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewMuscleMassProgress = ({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewMuscleMass`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewNotificationHistory = ({ PageNo, PageSize, UserId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_NOTIFICATION_HISTORY });

  var url = `${AGGREGATION_API}notification/history`;
  var config = { headers: { 'x-user-id': UserId }, params: { page: PageNo, limit: PageSize } }

  const response = await axiosInstance.get(url, config);

  if (response.status === 200) {
    var { result: History, total: TotalRecords } = response.data;
    var IsMoreResults = (PageSize * PageNo) < TotalRecords ? 1 : 0;

    dispatch({ type: TRYING_VIEW_NOTIFICATION_HISTORY_SUCCESS });
    return { Notifications: History, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_NOTIFICATION_HISTORY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ViewNotificationSettings = ({ UserId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_NOTIFICATION_SETTINGS });

  const response = await axiosInstance.post(`${NOTIFICATION_API}User/ViewNotificationSettings`, { Platform: 'web', oauthToken: OAUTH_TOKEN, UserId });

  if (response.data.Response === 1) {
    var { Categories } = response.data;

    dispatch({ type: TRYING_VIEW_NOTIFICATION_SETTINGS_SUCCESS, payload: { Categories } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_NOTIFICATION_SETTINGS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewNutritionPreviewFilters = () => async dispatch => {
  dispatch({ type: TRYING_VIEW_NUTRITION_PREVIEW_FILTERS });

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewNutritionPreviewFilter`, {});

  if (response.data.Response === 1) {
    var { NutritionCategories } = response.data;

    dispatch({ type: TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_SUCCESS, payload: { NutritionPreviewFilters: NutritionCategories } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewNutritionProgress = ({ EndDate, StartDate, UserId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_NUTRITION_PROGRESS });

  var data = { EndDate, StartDate, UserId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/GetNutritionProgress`, { ...data });

  if (response.data.Response === 1) {
    var { ClientDetails, NutritionProgress } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_NUTRITION_PROGRESS_SUCCESS });
    return { NutritionProgress };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_NUTRITION_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }

  return {};
}

export const ViewOrganizationBranch = ({ Month, OrganizationBranchId, OrganizationBranchIndex, Year }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_BRANCH });

  var data = { Month, OrganizationBranchId, Year };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationBranch`, { ...data });

  if (response.data.Response === 1) {
    var { Coaches } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_BRANCH_SUCCESS, payload: { Coaches, Month, OrganizationBranchIndex, Year } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_BRANCH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewOrganizationDashboard = ({ Month, Year }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_DASHBOARD });

  var data = { Month, Year };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationDashboard`, { ...data });

  if (response.data.Response === 1) {
    var { Branches, Details, MonthlyIncome } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_DASHBOARD_SUCCESS, payload: { Branches, Details, Month, MonthlyIncome, Year } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_DASHBOARD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewOrganizationMemberContractInvoices = ({ BranchIds, ClientIds, EndDate_DueDate, EndDate_SentDate, PageNo, PageSize, PaidStatus, RegionIds, SortCol, SortDir, StartDate_DueDate, StartDate_SentDate, TrainerIds }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES });

  var data = { BranchIds, ClientIds, EndDate_DueDate, EndDate_SentDate, PageNo, PageSize, PaidStatus, RegionIds, SortCol, SortDir, StartDate_DueDate, StartDate_SentDate, TrainerIds };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationMemberContractInvoices`, { ...data });

  if (response.data.Response === 1) {
    var { Invoices, IsMoreResults, Sums, TotalRecords } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_SUCCESS });
    return { Invoices, IsMoreResults, Sums, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewOrganizationMemberContractInvoicesFilters = () => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationMemberContractInvoicesFilters`, {});

  if (response.data.Response === 1) {
    var { Branches, Clients, Coaches, Regions } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_SUCCESS });
    return { Branches, Clients, Coaches, Regions };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewOrganizationMemberContracts = ({ BranchIds, ClientIds, EndDate, InsideOrg, OutsideOrg, PageNo, PageSize, ProductIds, RegionIds, SortCol, SortDir, StartDate, TrainerIds }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS });

  var data = { BranchIds, ClientIds, EndDate, InsideOrg, OutsideOrg, PageNo, PageSize, ProductIds, RegionIds, SortCol, SortDir, StartDate, TrainerIds };

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationMemberContracts`, { ...data });

  if (response.data.Response === 1) {
    var { Contracts, IsMoreResults, TotalRecords } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_SUCCESS, payload: { Contracts, IsMoreResults, TotalRecords } });

    return { Contracts, IsMoreResults, TotalRecords };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewOrganizationMemberContractsFilters = () => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationMemberContractsFilters`, {});

  if (response.data.Response === 1) {
    var { Branches, Clients, Coaches, Products, Regions } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_SUCCESS });
    return { Branches, Clients, Coaches, Products, Regions };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }

  return {};
}

export const ViewOrganizationMembers = () => async dispatch => {
  dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBERS });

  const response = await axiosInstance.post(`${RIDGE_API}payments/ViewOrganizationMembers`, {});

  if (response.data.Response === 1) {
    var { AcceptedUsers, IncomeShare, PendingUsers } = response.data;

    dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBERS_SUCCESS, payload: { AcceptedUsers, IncomeShare, PendingUsers } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_ORGANIZATION_MEMBERS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewPainProgress = ({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewPain`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewRPEProgress = ({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewRPE`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewSleepProgress = ({ ChartEndDate, ChartStartDate, ClientId, TrackingPageNo, TrackingPageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS });

  var UserDate = moment().format();

  var data = { ChartEndDate, ChartStartDate, TrackingPageNo, TrackingPageSize, UserDate, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewSleep`, { ...data });

  if (response.data.Response === 1) {
    var { AddMeasurementDetails, Chart, ClientDetails, CurrentStatus, TrackingHistory } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS, payload: { AddMeasurementDetails, Chart, CurrentStatus, TrackingHistory } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_BODYGOAL_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewStrengthProgress = ({ ClientId, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_STRENGTH_PROGRESS });

  var data = { PageNo, PageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewStrength`, { ...data });

  if (response.data.Response === 1) {
    var { ClientDetails, Exercises, FilterSettings, OverallPercentage } = response.data;

    if (ClientDetails) dispatch({ type: TRYING_GET_CLIENT_DETAILS_SUCCESS, payload: ClientDetails });
    dispatch({ type: TRYING_VIEW_STRENGTH_PROGRESS_SUCCESS, payload: { Exercises, FilterSettings, OverallPercentage } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_STRENGTH_PROGRESS_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewSubscriptionBillingHistory = () => async dispatch => {
  // dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER });

  const response = await axiosInstance.post(`${RIDGE_API}payments/GetBillingHistory`, null);

  if (response.data.Response === 1) {
    return response.data.BillingHistory;
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      // dispatch({ type: TRYING_PROCESS_STAGE_SUBSCRIBER_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return;
  }
}

export const ViewTrainerPaymentDashboard = ({ MonthlyYear }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD });

  var data = { MonthlyYear };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewTrainerPaymentDashboard`, { ...data });

  if (response.data.Response === 1) {
    var { Dashboard } = response.data;

    dispatch({ type: TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_SUCCESS });
    return { Dashboard };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ViewTrainerPaymentDashboardMonthly = ({ MonthlyYear }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY });

  var data = { MonthlyYear };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewTrainerPaymentDashboardMonthly`, { ...data });

  if (response.data.Response === 1) {
    var { Monthly } = response.data;

    dispatch({ type: TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_SUCCESS });
    return { Monthly };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }

    return {};
  }
}

export const ViewTrainerPayoutDetail = ({ PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_TRAINER_PAYOUT_DETAIL });

  var data = { PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewTrainerPayoutDetail`, { ...data });

  if (response.data.Response === 1) {
    var { Detail } = response.data;

    dispatch({ type: TRYING_VIEW_TRAINER_PAYOUT_DETAIL_SUCCESS });
    return { Detail };
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_TRAINER_PAYOUT_DETAIL_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }

    return {};
  }
}

export const ViewTrainerPayoutSummary = ({ AddToExisting, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_TRAINER_PAYOUT_SUMMARY });

  var data = { PageNo, PageSize };

  const response = await axiosInstance.post(`${RIDGE_API}ptadmin/ViewTrainerPayoutSummary`, { ...data });

  if (response.data.Response === 1) {
    var { IsMoreResults, Summary, TotalRecords } = response.data;

    dispatch({ type: TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_SUCCESS, payload: { AddToExisting, IsMoreResults, Summary, TotalRecords } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewUnreadNotificationCount = ({ UserId }) => async dispatch => {
  dispatch({ type: TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT });

  var url = `${NOTIFICATION_API}notification/number-of-unread`;
  var config = { headers: { 'x-user-id': UserId } };

  const response = await axiosInstance.get(url, config);

  if (response.status === 200) {
    var { result: UnreadNotifications } = response.data;

    dispatch({ type: TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_SUCCESS, payload: UnreadNotifications });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage, ModalErrorNoLink: true } });
    }
  }
}

export const ViewUserWorkoutMaxes_Endurance = ({ AddToExisting, ClientId, EnduranceName, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE });

  var data = { EnduranceName, PageNo, PageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewUserWorkoutMaxes_Endurance`, { ...data });

  if (response.data.Response === 1) {
    var { Endurances } = response.data;

    dispatch({ type: TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_SUCCESS, payload: { AddToExisting, Endurances } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}

export const ViewUserWorkoutMaxes_Strength = ({ AddToExisting, ClientId, ExerciseName, PageNo, PageSize }) => async dispatch => {
  dispatch({ type: TRYING_GET_USER_WORKOUT_MAXES_STRENGTH });

  var data = { ExerciseName, PageNo, PageSize, UserId: ClientId };

  const response = await axiosInstance.post(`${RIDGE_API}progress/admin/ViewUserWorkoutMaxes_Strength`, { ...data });

  if (response.data.Response === 1) {
    var { Exercises } = response.data;

    dispatch({ type: TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_SUCCESS, payload: { AddToExisting, Exercises } });
  } else {
    if (response.data.NoAccess) dispatch({ type: TRYING_UPDATE_SUBSCRIPTION_STATUS, payload: response.data.SubscriptionStatus });
    else {
      dispatch({ type: TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_ERROR, payload: response.data.ErrorMessage });

      if (response.data.BadToken) LogoutUser();
      else dispatch({ type: UPDATE_ERROR_MODAL_WARNING, payload: { ModalError: response.data.ErrorMessage } });
    }
  }
}